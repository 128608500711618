<template>
    <v-card class="card-modal" :class="card_extra_classes" :id="card_id" :disabled="card_disabled">
        <v-card-title class="head-modal">
            <slot name="modal-header">
                <span class="title-modal">{{ title }}</span>
                <v-btn class="btn-close-modal" @click="openCloseDialog" :ripple="false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </slot>
        </v-card-title>
        <div class="body-modal">
            <div v-if="loading" class="general-loading">
                <Loading class_loading="loading-gif-s"/>
            </div>
            <template v-else>
                <v-card-text class="content-modal personalize-scroll" :class="body_extra_classes">
                    <slot name="body-modal-content"></slot>
                </v-card-text>
                <v-card-actions class="footer-modal" v-if="!hide_actions">
                    <slot name="actions-modal">
                        <v-row justify="end">
                            <v-col cols="12"
                                   :sm="card_extra_classes === 'modal-width-xs' ? 4 : 3"
                                   :md="card_extra_classes === 'modal-width-xs' ? 4 : 2"
                                   xl="2">
                                <v-btn
                                    variant="outlined"
                                    @click="btnSavePressed"
                                    class="btn button-primary"
                                    :loading="loading_save"
                                >
                                    Guardar
                                </v-btn>
                            </v-col>
                            <v-col cols="12"
                                   :sm="card_extra_classes === 'modal-width-xs' ? 4 : 3"
                                   :md="card_extra_classes === 'modal-width-xs' ? 4 : 2"
                                    xl="2">
                                <v-btn
                                    variant="outlined"
                                    class="btn button-secondary"
                                    @click="openCloseDialog"
                                >
                                    Cancelar
                                </v-btn>
                            </v-col>
                        </v-row>
                    </slot>
                </v-card-actions>
            </template>
        </div>
    </v-card>
</template>

<script>
import Loading from "@/components/Generic/Loading.vue";
import {ref} from "vue";

export default {
    name: "BaseModalTemplate",
    components: {Loading},
    props: {
        /*Función para abrir/cerrar el modal*/
        openCloseDialog: Function,

        /*
        * Función llamada al pulsar el botón GUARDAR
        * No es necesaria si el parámetro hide_actions es TRUE
        * o si se sustituye el slot de actions por uno personalizado
        * */
        sendForm: Function,

        /*Título del modal*/
        title: String,

        /*Variable que controla la carga de datos del modal*/
        loading: {
            type: Boolean,
            default: false,
        },

        /*
        * String con las clases que se le quieran asignar al card
        * Deben estar separadas por espacios en blanco
        * */
        card_extra_classes: {
            type: String,
            default: "",
        },

        /*ID del elemento card, en caso de ser necesario*/
        card_id: {
            type: String,
            default: "",
        },

        /*Bool que indica si el elemento card esta deshabilitado*/
        card_disabled: {
            type: Boolean,
            default: false,
        },

        /*
        * String con las clases que se le quieran asignar al v-card-text
        * Deben estar separadas por espacios en blanco
        * */
        body_extra_classes: {
            type: String,
            default: "",
        },

        /*Oculta las v-card-actions*/
        hide_actions: {
            type: Boolean,
            default: false,
        },

    },

    setup(props) {
        let loading_save = ref(false)

        async function btnSavePressed() {
            loading_save.value = true
            await props.sendForm()
            loading_save.value = false
        }

        return{
            btnSavePressed,
            loading_save,
        }
    }
}
</script>

<style scoped>

</style>