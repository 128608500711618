<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <span class="main-title-page">Grupos por roles</span>
            </v-col>
        </v-row>
        <template v-if="loading_template.general_container">
            <Loading/>
        </template>
        <template v-else>
            <v-row>
                <v-col cols="12" md="6" lg="4">
                    <v-select
                        class="input-error select-secondary"
                        placeholder="Seleccione un rol"
                        v-model="form_data.rol_selected"
                        :items="form_data.all_roles"
                        item-title="name"
                        item-value="id_rol"
                        @update:modelValue="getAllGroupsByRol(form_data.rol_selected)"
                        variant="outlined"
                        hide-details
                        :menu-props="{ width: '250'}"
                        no-data-text="No hay datos disponibles"
                    >
                        <template v-slot:item="{ props, item }">
                            <v-list-item
                                v-bind="props"
                                :title="item?.raw?.name"
                            >
                            </v-list-item>
                        </template>
                        <template v-slot:selection="{ item }">
                            <div>
                                <span>{{ item?.raw?.name }}</span>
                            </div>
                        </template>
                    </v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="7" md="8" class="users-col">
                    <v-row>
                        <v-col>
                            <span class="list-title">Lista de grupos</span>
                        </v-col>
                    </v-row>
                    <div v-if="loading_template.get_group" class="general-loading">
                        <Loading class_loading="loading-gif-s"/>
                    </div>
                    <template v-else>
                        <v-row v-if="show_template.show_groups">
                            <v-col v-if="form_data.groups_list.length > 0">
                                <v-list class="personalize-scroll list-users" mandatory>
                                    <v-list-item
                                        class="text-list"
                                        v-for="group in form_data.groups_list"
                                        :key="group.gidNumber"
                                        :title="`${group.name} (${group.gidNumber})`"
                                        :value="group.name"
                                        color="deep-purple-lighten-1"
                                        @click="groupClickField(group.name)"
                                    ></v-list-item>
                                </v-list>
                            </v-col>
                            <v-col v-else>
                                <span>No hay grupos asignados en este rol</span>
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col class="default-text">
                                <span style="font-style: italic">Seleccione un rol para mostrar los grupos</span>
                            </v-col>
                        </v-row>
                    </template>
                </v-col>
                <v-col cols="12" sm="5" md="4" class="users-col">
                    <v-row v-if="show_template.show_user_group">
                        <v-col cols="12">
                            <span class="list-title">Usuarios del grupo</span>
                        </v-col>

                        <v-col v-if="loading_template.get_group" class="general-loading">
                            <Loading class_loading="loading-gif-s"/>
                        </v-col>
                        <v-col v-else>
                            <v-list class="personalize-scroll list-users" v-if="form_data.user_group.length>0">
                                <v-list-item
                                    class="text-list"
                                    v-for="user in form_data.user_group"
                                    :key="user.nombre_usuario"
                                    :title="user.nombre_usuario"
                                ></v-list-item>
                            </v-list>
                            <span v-else>No hay usuarios para el grupo seleccionado</span>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

        </template>
    </v-container>
</template>

<script>
import {useRoute} from "vue-router";
import {onBeforeMount, ref} from "vue";
import {hasPermission} from "@/utils/auth";
import {showNotification} from "@/utils/aux_functions";
import {
    getAllRolesWS,
    getAllTechnicalByGroupWS,
    getAllGroupsByRolWS
} from "@/utils/admin_functions";
import Loading from "@/components/Generic/Loading.vue";

export default {
    name: "ManagerUserRolesView",
    components: {Loading},

    setup() {
        const route = useRoute();
        let form_data = ref({})
        let show_template = ref({show_groups: false, show_user_group: false})
        let loading_template = ref({get_group: false, general_container: false, get_users: false})
        let click_status = true
        let clone_group_selected

        onBeforeMount(async () => {
            loading_template.value.general_container = true;
            await hasPermission(route);
            await getAllRoles();
            loading_template.value.general_container = false;
        })


        async function getAllRoles() {
            let {result, data, errors} = await getAllRolesWS();
            if (result) {
                form_data.value.all_roles = data;
            } else {
                showNotification('error', errors)
            }
        }


        async function getAllGroupsByRol(rol_id) {
            loading_template.value.get_group = true;
            show_template.value.show_user_group = false
            form_data.value.user_group = []

            let {result, data, errors} = await getAllGroupsByRolWS(rol_id)
            if (result) {
                form_data.value.groups_list = data
            } else {
                showNotification('error', errors)
            }

            loading_template.value.get_group = false;
            show_template.value.show_groups = true
        }


        async function showUserGroup(group_selected) {
            let {result, data, errors} = await getAllTechnicalByGroupWS(group_selected)
            if (result) {
                click_status = false
                form_data.value.user_group = data
            } else {
                showNotification('error', errors)
            }
        }


        async function groupClickField(group_selected) {
            /*CONTROLA LA PETICIÓN DEL LISTADO GRUPOS*/
            loading_template.value.get_users = true

            if (click_status && group_selected !== clone_group_selected) {
                await showUserGroup(group_selected)
                click_status = true
                clone_group_selected = group_selected
                show_template.value.show_user_group = true
            }

            loading_template.value.get_users = false
        }

        return {
            getAllGroupsByRol,
            groupClickField,

            form_data,
            show_template,
            loading_template,
        }
    }
}
</script>

<style lang="scss">
@import "@/styles/views/admin/manager-user-roles.scss";
</style>