import store from "@/store";
import {useRouter} from "vue-router";
import {b64EncodeUnicode, showNotification} from "@/utils/aux_functions";
import {
    localGetIDRolByAlias
} from "@/utils/admin_functions";
import {escicApiBackendPetition} from "@/utils/escic_api_petition";


export async function hasPermission(route) {
    const router = useRouter();
    let has_permission = true;
    if (isLogged()) {
        switch (route.name) {
            case 'admin':
            case 'roles':
            case 'grupos':
            case 'roles-usuario':
            case 'usuarios-grupo':
            case 'grupos-roles':
                has_permission = userHaveAllowedRol("ROOT");
                has_permission = true;
                break;
            case 'agrupaciones-ffvv':
            case 'grupos-objetivos-ffvv':
            case 'tipos-fuerzas-ventas':
            case 'agrupaciones-internas':
            case 'canales-ventas':
            case 'fuerzas-ventas':
            case 'zonas':
            case 'detalles-fuerza-venta':
                has_permission = userHaveAllowedRol("SALES_FORCE_READ") || userHaveAllowedRol("SALES_FORCE_EDIT");
                break;

        }

        if (!route.fullPath.includes('acceso-denegado')) {
            await saveLogVisit(route.fullPath, has_permission)
        }

        return (has_permission ? true : router.push({name: 'not-allowed'}))
    }
    return router.push({name: 'inicio'})
}


export async function authenticate(username, password) {
    const encrypted_password = b64EncodeUnicode(password)
    const encrypted_token_password = b64EncodeUnicode(process.env.VUE_APP_AICORE_AUTH_PASSWORD)

    return $.ajax({
        type: "POST",
        url: process.env.VUE_APP_AICORE_URL_LOGIN,
        data: {
            username_ldap: username,
            password_ldap: encrypted_password,
            username_aicore: process.env.VUE_APP_AICORE_AUTH_USER,
            password_aicore: encrypted_token_password,
        }
    });
}

export function isLogged() {
    return store.getters.getUsername !== ""
}

export async function logout() {
    localStorage.clear()
    store.commit('setUser', "")
}


export async function saveLogVisit(to, visited) {
    if (process.env.NODE_ENV !== 'development') {
        var data = {
            url: to,
            username: store.getters.getUsername,
            visualizado: visited ? 1 : 0,
            application: 'escic'
        };
    }
}


/*FUNCIONES PARA GESTIONAR LOS ROLES DEL USUARIO Y SUS PERMISOS*/
export async function getUserRolesWS() {
    let params_token = {
        'logged_user': store.getters.getUsername,
    }

    let params = {
        'user_name': store.getters.getUsername,
    }

    try {
        return await escicApiBackendPetition('POST', 'technical/getAllRolesTechnical', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'technical/getAllRolesTechnical');
    }
}


export function userHaveAllowedRol(alias) {
    let user_roles = store.getters.getRolesUser;
    let id_rol =  localGetIDRolByAlias(alias)

    return user_roles.includes(parseInt(id_rol)) || user_roles.includes(localGetIDRolByAlias("ROOT"))

}