<template>
    <GenericTableFormPageTemplate
            :get-all-elements="getAllTechniciansWS"
            :headers="headers"
            page_title="Técnicos por grupo"
            @insert-new-element="openModalInsert"
            :custom_item_slots="['item-btn_actions', 'item-activo', 'item-id_grupo']"
            :page_loading="page_loading"
            ref="form_page"
            :use_expansion_panel = "use_expansion_panel"
    >
        <!--Template con el botón de las acciones disponibles-->
        <template #item-btn_actions="{item}">
            <v-menu>
                <template v-slot:activator="{ props }">
                    <img :src="require('@/assets/icons/more-options.svg')"
                         class="btn button-bg-white icon-actions"
                         alt="Icono puntos"
                         v-bind="props">
                </template>
                <v-list>
                    <v-list-item>
                        <v-list-item-title class="text-center"
                                           @click="openModalUpdate(item)">
                            <span style="cursor: pointer">Editar</span>
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-title class="text-center"
                                           @click="deleteRelationWarning(item)">
                            <span style="cursor: pointer">Eliminar</span>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>

        <template #item-activo="{item}">
            <span>{{item.activo ? 'Activo' : 'Inactivo'}}</span>
        </template>

        <template #item-id_grupo="{item}">
            {{group_list.find(group => group.id_grupo === item.id_grupo).nombre}}
        </template>

        <template #queue-filters="{form_data}">
            <v-col cols="12" md="6">
                <span class="input-title">Nombre de usuario</span>
                <v-text-field
                        v-model.trim="form_data.id_user"
                        placeholder="Nombre"
                        class="input-error text-field-secondary"
                        bg-color="white"
                        color="transparent"
                        hide-details
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
                <span class="input-title">Grupo</span>
                <v-select
                    class="input-error select-secondary"
                    placeholder="Seleccione un grupo"
                    item-title="nombre"
                    item-value="id_grupo"
                    v-model="form_data.id_group"
                    :items="group_list"
                    variant="outlined"
                    hide-details
                    no-data-text="No hay datos disponibles"
                >
                </v-select>
            </v-col>
            <v-col cols="12" md="6">
                <span class="input-title">Estado</span>
                <v-select
                    class="input-error select-secondary"
                    placeholder="Seleccione un estado"
                    item-title="state"
                    item-value="value"
                    v-model="form_data.active"
                    :items="available_states"
                    variant="outlined"
                    hide-details
                    no-data-text="No hay datos disponibles"
                >
                </v-select>
            </v-col>
        </template>

        <template v-slot:modal-element-actions="{reloadData}">
            <v-dialog v-model="show_insert_modal" persistent>
                <ManageTechniciansGroupRelationModal
                    :open_modal="openCloseModal"
                    :reloadData="reloadData"
                    :action="action"
                    :selected_relation="selected_relation"
                    :available_groups="group_list"
                ></ManageTechniciansGroupRelationModal>
            </v-dialog>
        </template>
    </GenericTableFormPageTemplate>
</template>

<script>
import GroupTechniciansModal from "@/components/TechniciansGroupsModal/GroupTechniciansModal.vue";
import GenericTableFormPageTemplate from "@/components/Generic/GenericTableFormPageTemplate.vue";
import ManageTechniciansGroupsModal from "@/components/TechniciansGroupsModal/ManageTechniciansGroupsModal.vue";
import {onBeforeMount, ref} from "vue";
import {deleteTechnicianFromGroupWS, getAllTechniciansWS} from "@/utils/technicians";
import {manageWSResponses, showNotification} from "@/utils/aux_functions";
import {getTechniciansGroupsWS} from "@/utils/technicians_groups";
import ManageTechniciansGroupRelationModal from "@/components/Technicians/ManageTechniciansGroupRelationModal.vue";
import {fireSweetAlert, initSweetAlert} from "@/utils/sweetalert";

export default {
    name: "TechniciansListView",
    methods: {getAllTechniciansWS},
    components: {
        ManageTechniciansGroupRelationModal,
        ManageTechniciansGroupsModal, GenericTableFormPageTemplate, GroupTechniciansModal},
    setup() {
        let use_expansion_panel = ref(true);
        let available_states = ref([
            {state: 'Activo', value: 1},
            {state: 'Inactivo', value: 0},
            {state: 'Ambos', value: null},
        ])

        let headers = ref([
            {text: 'ID', value: 'id', sortable: true},
            {text: 'NOMBRE USUARIO', value: 'id_usuario', sortable: true},
            {text: 'GRUPO', value: 'id_grupo'},
            {text: 'ESTADO', value: 'activo'},
            {text: 'ACCIONES', value: 'btn_actions'},
        ])
        let show_insert_modal = ref(false)

        let action = ref("")
        let selected_relation = ref({})

        let group_list = ref([])
        let page_loading = ref(false)

        let form_page = ref(null)

        onBeforeMount(async () => {
            page_loading.value = true
            await loadAllGroups()
            initSweetAlert()
            page_loading.value = false
        })

        /*
        * Carga en el array group_list el listado de todos los grupos disponibles
        * */
        async function loadAllGroups () {
            let response = await manageWSResponses(getTechniciansGroupsWS, [{}])

            if (response){
                group_list.value = response['data']
            }
        }

        /*
        * Abre o cierra el modal
        * */
        function openCloseModal() {
            show_insert_modal.value = !show_insert_modal.value
        }

        function openModalInsert() {
            action.value = 'insert'
            openCloseModal()
        }

        function openModalUpdate({id, id_usuario, id_grupo, activo}) {
            action.value = 'update'

            selected_relation.value = {
                'relation_id' : id,
                'user_id' : id_usuario,
                'group_id': id_grupo,
                'active': activo,
            }

            openCloseModal()
        }

       /*
       * Muestra un aviso indicando que la relación se va a eliminar
       * */
        async function deleteRelationWarning(selected_item){
            selected_relation.value['id'] = selected_item.id

            await fireSweetAlert(`Se va a eliminar la relación`, deleteTechnicianFromGroup, '¿Está seguro?')
        }

        /*
        * Elimina la relación entre un técnico y un grupo
        * */
        async function deleteTechnicianFromGroup(){
            let response = await manageWSResponses(deleteTechnicianFromGroupWS, [selected_relation.value['id']])

            if (response){
                showNotification('success', response['data'])
                await form_page.value.loadAllElements()
            }
        }


        return{
            openCloseModal,
            openModalInsert,
            openModalUpdate,
            deleteRelationWarning,

            page_loading,
            available_states,
            action,
            headers,
            selected_relation,
            use_expansion_panel,
            show_insert_modal,
            group_list,
            form_page,
        }
    }
}
</script>

<style scoped>

</style>