<template>
    <v-container>
        <template v-if="page_loading">
            <Loading/>
        </template>
        <template v-else>
            <v-row align="center">
                <v-col cols="12" sm="9" md="9" lg="10">
                    <span class="main-title-page">{{ page_title }}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="text-end">
                    <v-btn
                        class="btn button-primary"
                        @click="insertNewElement"
                    >
                        Añadir nuevo elemento
                    </v-btn>
                </v-col>
                <v-col cols="12">
                    <v-expansion-panels v-if="use_expansion_panel">
                        <v-expansion-panel class="expansion-personalized">
                            <v-expansion-panel-title>Opciones de filtrado</v-expansion-panel-title>
                            <v-expansion-panel-text>
                                <v-row>
                                    <!-- DOCUMENTACIÓN
                                        SLOT QUE PERMITE AÑADIR FILTROS
                                        SIMPLEMENTE EN EL COMPONENTE PADRE CREAR LOS ELEMENTOS
                                        Y USAR COMO SU v-model EL OBJETO form_data QUE SE PASA
                                        EN EL SLOT, SE DEBERÍA USAR LA ESTRUCTURA.
                                        ESTA FORMA DE MOSTRAR LOS FILTROS CON EL V-EXPANSION-PANEL
                                        SOLO SE HARÁ CUANDO SE NOS PASE LA PROPS useExpansionPanel
                                        QUE ACTIVARÁ LA MISMA. EN CASO CONTRARIO, SE HARÁ CON EL
                                        SLOT QUE FUNCIONA IGUAL QUE ESTE, PERO QUE MUESTRA LOS DATOS
                                        EN FORMA DE ROW. ( <v-row v-else> ) QUE TENEMOS JUSTO A
                                        CONTINUACIÓN.
                                        <v-col>elemento</v-col> PARA MANTENER LA MAQUETACIÓN
                                        Ejemplo:
                                        <template #queue-filters="{form_data}">
                                          <v-col cols="12" lg="6">
                                                <v-text-field
                                                    v-model.trim="form_data.origin_url"
                                                    ....
                                                ></v-text-field>
                                            </v-col>
                                            ....
                                    -->
                                    <slot name="queue-filters" :form_data="filter_search"></slot>
                                </v-row>

                                <v-row justify="end" no-gutters class="pt-2 sm:pt-0">
                                    <v-col cols="12" class="text-end align-self-center p-1 sm:p-0">
                                        <v-btn class="btn button-bg-orange" @click="applyFilter">Filtrar</v-btn>
                                        <img :src="require('@/assets/icons/drop-filter.svg')" alt="Eliminar filtros"
                                             class="delete-filter ms-4" @click="deleteFilters">
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-text>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <v-row v-else>
                        <slot name="queue-filters" :form_data="filter_search"></slot>
                        <v-col cols="12" class="text-end align-self-center p-1 sm:p-0">
                            <v-btn class="btn button-bg-orange" @click="applyFilter">Filtrar</v-btn>
                            <img :src="require('@/assets/icons/drop-filter.svg')" alt="Eliminar filtros"
                                 class="delete-filter ms-4" @click="deleteFilters">
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <Vue3EasyDataTable
                        v-if="uses_pagination"
                        table-class-name="primary-table"
                        theme-color="#AD7BE9"
                        buttons-pagination
                        :headers="headers"
                        :items="items"
                        v-model:server-options="server_options"
                        :server-items-length="server_items_length"
                        :loading="loading"
                        must-sort
                        rows-per-page-message="Elementos por página: "
                        rows-of-page-separator-message="de"
                        empty-message="No hay datos disponibles"
                    >
                        <!-- DOCUMENTACIÓN
                            CONJUNTO DE SLOTS POR SI HAY QUE PERSONALIZAR ALGÚN HEADER EN ESPECIAL
                            O POR SI HAY QUE CAMBIAR ALGUNO DE LOS QUE HAY POR DEFECTO.
                            USA LOS PROPS custom_item_slots Y manage-custom-slots
                            name INDICA EL NOMBRE QUE SE VA A USAR PARA REFERIR AL SLOT,
                            EN ESTE CASO COMO ESTÁ EN LA TABLA SIEMPRE DEBERÍA SER DEL ESTILO
                            'item-(header.value)' Ej: 'item-updated_at' cambiaría lo que muestra en la columna updated_at
                            según lo que devuelva la función manageCustomSlots
                         -->
                        <template v-for="(name) in custom_item_slots" :key="name" #[name]="item">
                            <slot :name="name" :item="item"></slot>
                        </template>
                    </Vue3EasyDataTable>
                    <Vue3EasyDataTable
                        v-else
                        table-class-name="primary-table"
                        theme-color="#AD7BE9"
                        :headers="headers"
                        :items="items"
                        :loading="loading"
                        must-sort
                        rows-per-page-message="Elementos por página: "
                        rows-of-page-separator-message="de"
                        empty-message="No hay datos disponibles"
                    >
                        <!-- DOCUMENTACIÓN
                            CONJUNTO DE SLOTS POR SI HAY QUE PERSONALIZAR ALGÚN HEADER EN ESPECIAL
                            O POR SI HAY QUE CAMBIAR ALGUNO DE LOS QUE HAY POR DEFECTO.
                            USA LOS PROPS custom_item_slots Y manage-custom-slots
                            name INDICA EL NOMBRE QUE SE VA A USAR PARA REFERIR AL SLOT,
                            EN ESTE CASO COMO ESTÁ EN LA TABLA SIEMPRE DEBERÍA SER DEL ESTILO
                            'item-(header.value)' Ej: 'item-updated_at' cambiaría lo que muestra en la columna updated_at
                            según lo que devuelva la función manageCustomSlots
                         -->
                        <template v-for="(name) in custom_item_slots" :key="name" #[name]="item">
                            <slot :name="name" :item="item"></slot>
                        </template>
                    </Vue3EasyDataTable>
                </v-col>
            </v-row>
        </template>

    </v-container>

    <!-- DOCUMENTACIÓN
      MODAL PARA AÑADIR ELEMENTOS
    -->
    <slot name="modal-element-actions" :reloadData="loadAllElements">
        <!--    <slot name="modal-element-actions-target-groups" :reloadData="loadAllElementsGroup">-->
    </slot>

    <!--    <slot name="modal-detail-actions" :item="item"></slot>-->
</template>

<script>
import {onBeforeMount, ref, watch} from "vue";
import {manageWSResponses, showNotification} from "@/utils/aux_functions";
import Vue3EasyDataTable from "vue3-easy-data-table";
import 'vue3-easy-data-table/dist/style.css';
import Loading from "@/components/Generic/Loading.vue";

export default {
    name: "GenericTableFormPageTemplate",
    components: {Loading, Vue3EasyDataTable},
    props: {
        page_title: {
            type: String,
            required: true,
        },
        page_loading: {
            type: Boolean,
            default: false,
        },
        headers: {
            type: Array,
            required: true,
        },
        getAllElements: {
            type: Function,
            required: true,
        },
        custom_item_slots: {
            type: Array,
            default: [],
        },
        uses_pagination: {
            type: Boolean,
            default: true,
        },
        use_sales_forces_view: {
            type: Boolean,
            default: false,
        },
        use_expansion_panel: {
            type: Boolean,
            default: false,
        }

    },
    emits: ['insertNewElement'],
    setup(props, {emit}) {
        let loading = ref(false)

        let items = ref([])
        let filter_search = ref({})

        let server_items_length = ref(0)

        // let server_options = ref({
        //     page: 1,
        //     rowsPerPage: 20,
        //     sortBy: props.use_sales_forces_view ? 'update_at' : null,
        //     sortType: props.use_sales_forces_view ? 'ASC' : 'ASC'
        // });

        let server_options = ref({
            page: 1,
            rowsPerPage: 20,
            sortBy: null,
            sortType: "asc",
        })


        onBeforeMount(async () => {
            await loadAllElements()
        })

        watch(server_options, async () => {
                await loadAllElements()
            },
            {deep: true}
        );

        /*
        * Carga los elementos con o sin paginación, según como se indique en los props
        *
        * */
        async function loadAllElements() {
            loading.value = true

            if (props.uses_pagination) {
                await loadElementsWithPagination()
            } else {
                await loadElementsInArray()
            }

            loading.value = false
        }

        async function loadElementsWithPagination() {
            const {sortBy, sortType} = server_options.value
            filter_search.value['order_by'] = sortBy
            filter_search.value['order_direction'] = sortType

            const response = await manageWSResponses(props.getAllElements, [server_options.value.page, server_options.value.rowsPerPage, filter_search.value])

            if (response) {
                items.value = response['data'].data;
                server_items_length.value = response['data'].total;
            }
        }

        async function loadElementsInArray() {
            const {sortBy, sortType} = server_options.value
            filter_search.value['order_by'] = sortBy
            filter_search.value['order_direction'] = sortType

            const response = await manageWSResponses(props.getAllElements, [filter_search.value]);

            if (response) {
                items.value = response['data']
            }
        }

        function insertNewElement() {
            emit('insertNewElement')
        }

        /*Funciones para aplicar los filtros*/
        async function applyFilter() {
            server_options.value.page = 1
            await loadAllElements()
        }

        async function deleteFilters() {
            filter_search.value = {};
            server_options.value.page = 1
            await loadAllElements()
        }

        return {
            applyFilter,
            deleteFilters,
            insertNewElement,
            loadAllElements,

            loading,
            server_items_length,
            server_options,
            items,
            filter_search,
        }
    }
}
</script>


<style lang="scss">
.expansion-personalized {
    border: 1px solid $primary-color-1;

    .delete-filter {
        //filter: brightness(0) saturate(100%) invert(61%) sepia(76%) saturate(5574%) hue-rotate(325deg) brightness(97%) contrast(95%);
        width: 30px;
        height: auto;
        cursor: pointer;

        &:hover {
            filter: brightness(0) saturate(100%) invert(31%) sepia(47%) saturate(4560%) hue-rotate(334deg) brightness(81%) contrast(101%);
        }
    }
}


.v-expansion-panel--active > .v-expansion-panel-title {
    min-height: 55px !important;
}

.v-expansion-panel-title--active {
    background: $primary-color-1;
    opacity: 1;
    color: $white !important;
    font-weight: bold;
}

.icon-actions {
    max-height: 36px;
}

</style>