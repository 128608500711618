<template>
    <v-container>
        <v-row align="center">
            <v-col cols="12" sm="9" md="9" lg="10">
                <span class="main-title-page">Manuales de referencia</span>
            </v-col>
        </v-row>
        <v-row>
            <template v-for="document in document_list">
                <v-col cols="6">
                    <router-link class="link" :to="'/docs/' + document.href" target="_blank">
                        <img :src="require('@/assets/icons/pdf.svg')" alt="Icono pdf" style="width: 28px">
                        {{document.name}}
                    </router-link>
                </v-col>
            </template>
        </v-row>
    </v-container>
</template>

<script>

import {ref} from "vue";

export default {
    name: "DocumentsView",
    setup() {
        const document_list = ref([
            {name: 'Agrupaciones', href:'agrupaciones.pdf'},
            {name: 'Grupos de objetivos', href:'grupos_objetivos.pdf'},
            {name: 'Tipos fuerzas ventas', href:'tipos_ffvv.pdf'},
            {name: 'Agrupaciones internas', href:'agrupaciones_internas.pdf'},
            {name: 'Canales de venta', href:'canales_venta.pdf'},
            {name: 'Gestión de fuerzas de ventas', href:'fuerzas_ventas.pdf'},
            {name: 'Grupos de técnicos', href:'grupos_tecnicos.pdf'},
            {name: 'Relaciones entre técnicos-grupos', href:'relacion_tecnico-grupo.pdf'},
        ])

        return{
            document_list
        }
    }
}
</script>

<style lang="scss">
.link {
    font-size: 18px;
    color: $primary-color-1;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
        color: $primary-color-2;
        font-weight: 600;
    }
}
</style>