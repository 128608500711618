<template>
    <BaseModalTemplate
            :title="modal_title"
            :open-close-dialog="open_modal"
            :loading="false"
            card_extra_classes="modal-width-xs"
            body_extra_classes="custom-height"
            :sendForm="sendForm"
    >
        <template #body-modal-content>
            <v-row>
                <v-col cols="12">
                    <span class="input-title">Nombre</span>
                    <v-text-field
                            placeholder="Nombre"
                            class="input-error text-field-primary"
                            :error="form_errors.name"
                            v-model.trim="target_group_data.name"
                            variant="underlined"
                            hide-details
                    >
                    </v-text-field>
                    <span v-if="form_errors.name"
                          class="input-error msg-error"
                    >
                        El campo no puede estar vacío
                    </span>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>

<script>
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";
import {onBeforeMount, ref} from "vue";
import {manageWSResponses, showNotification, variableIsDefined} from "@/utils/aux_functions";
import {insertTargetGroupWS, updateTargetGroupWS} from "@/utils/target_groups";

export default {
    name: "ManageTargetGroupsModal",
    components: {BaseModalTemplate},
    props: {
        open_modal: Function,
        reloadData: Function,

        action: String, //Indica si el modal se usará para insertar un valor o para actualizar
        selected_target_group: Object, //Si el modal se usará para actualizar debe de haber un ID del elemento a actualizar
    },
    setup(props) {
        let loading = ref(false)
        let target_group_data = ref({})
        let form_errors = ref({})
        let modal_title = ref("Añadir nuevo grupo de objetivos")

        onBeforeMount(() => {
            if (props.action === 'update'){
                target_group_data.value = { ...props.selected_target_group};
                modal_title.value = `Actualización de grupo objetivo con ID ${target_group_data.value.target_id}`
            }
        })

        //Función que se encarga de llamar al ws de insertar o actualizar según la acción que se vaya a realizar
        async function sendForm () {
            switch (props.action) {
                case "insert":
                    await insertNewTargetGroup()
                    break;
                case "update":
                    await updateTargetGroup()
                    break;
            }
        }

        //Función para insertar un nuevo grupo objetivo de ffvv
        async function insertNewTargetGroup() {
            form_errors.value = {}
            loading.value = true

            if (variableIsDefined(target_group_data.value.name)){
                let response = await manageWSResponses(insertTargetGroupWS, [target_group_data.value.name])
                loading.value = false
                if (response){
                    showNotification('success', 'Se ha insertado un nuevo grupo de objetivos')
                    await props.reloadData()
                    props.open_modal()
                }
            }else{
                loading.value = false
                form_errors.value.name = true
            }
        }

        //Función para actualizar un grupo objetivo de ffvv
        async function updateTargetGroup() {
            form_errors.value = {}
            loading.value = true

            if (variableIsDefined(target_group_data.value.name)){
                let response = await manageWSResponses(updateTargetGroupWS, [target_group_data.value.target_id, target_group_data.value.name])
                loading.value = false

                if (response){
                    showNotification('success', response.data)
                    await props.reloadData()
                    props.open_modal()
                }
            }else{
                loading.value = false
                form_errors.value.name = true
            }
        }

        return {
            sendForm,

            modal_title,
            form_errors,
            target_group_data,
            loading,
        }
    }
}
</script>

<style lang="scss">

.custom-height {
    min-height: 15vh !important
}

</style>