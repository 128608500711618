<template>
    <BaseModalTemplate
            :title="modal_title"
            :open-close-dialog="open_modal"
            :loading="loading"
            card_extra_classes=""
            :hide_actions="true"
    >
        <template #body-modal-content>
            <v-row>
                <v-col cols="12">
                    <Vue3EasyDataTable
                        table-class-name="primary-table"
                        theme-color="#AD7BE9"
                        buttons-pagination
                        :headers="headers"
                        :items="items"
                        v-model:server-options="server_options"
                        :server-items-length="server_items_length"
                        :loading="loading"
                        must-sort
                        rows-per-page-message="Elementos por página: "
                        rows-of-page-separator-message="de"
                        empty-message="No hay datos disponibles"
                    >
                        <template #item-activo="item">
                            <span>{{item.activo ? 'Activo' : 'Inactivo'}}</span>
                        </template>

                        <!--Template con el botón de las acciones disponibles, en este caso unicamente la de actualizar-->
                        <template #item-btn_actions="item">
                            <v-menu>
                                <template v-slot:activator="{ props }">
                                    <img :src="require('@/assets/icons/more-options.svg')"
                                         class="btn button-bg-white icon-actions"
                                         alt="Icono puntos"
                                         v-bind="props">
                                </template>
                                <v-list>
                                    <v-list-item v-if="item.activo">
                                        <v-list-item-title class="text-center"
                                                           @click="changeRelationStateWarning(item, 0)">
                                            <span style="cursor: pointer">Desactivar</span>
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item v-else>
                                        <v-list-item-title class="text-center"
                                                           @click="changeRelationStateWarning(item, 1)">
                                            <span style="cursor: pointer">Activar</span>
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-title class="text-center"
                                                           @click="deleteRelationWarning(item)">
                                            <span style="cursor: pointer">Eliminar</span>
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>
                    </Vue3EasyDataTable>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>

<script>
import {onBeforeMount, ref, watch} from "vue";
import {deleteTechnicianFromGroupWS, getAllTechniciansWS, updateTechnicianGroupWS} from "@/utils/technicians";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";
import Vue3EasyDataTable from "vue3-easy-data-table";
import {manageWSResponses, showNotification} from "@/utils/aux_functions";
import {fireSweetAlert, initSweetAlert} from "@/utils/sweetalert";

export default {
    name: "GroupTechniciansModal",
    components: {Vue3EasyDataTable, BaseModalTemplate},
    props: {
        open_modal: Function,
        selected_group_id: Number,
    },
    setup(props){
        let headers = ref([
            {text: 'ID', value: 'id', sortable: true},
            {text: 'Nombre', value: 'id_usuario', sortable: true},
            {text: 'Estado', value: 'activo'},
            {text: 'ACCIONES', value: 'btn_actions'},
        ])
        let server_items_length = ref(0)
        let server_options = ref({
            page: 1,
            rowsPerPage: 20,
            sortBy: null,
            sortType: null,
        })
        let loading = ref(false)
        let filter_search = {}
        let items = ref([])
        let modal_title = ref(`Técnicos asociados al grupo con ID: ${props.selected_group_id}`)
        let selected_relation_new_data = ref({})

        onBeforeMount(async () => {
            initSweetAlert()
            await loadGroupTechnicals()

        })

        watch(server_options, async () => {
                await loadGroupTechnicals()
            },
            {deep: true}
        );

        /*
        * Carga una página de los técnicos asociados a un grupo
        * */
        async function loadGroupTechnicals(){
            loading.value = true
            const {sortBy, sortType} = server_options.value
            filter_search['order_by'] = sortBy
            filter_search['order_direction'] = sortType
            filter_search['id_group'] = props.selected_group_id

            const response = await manageWSResponses(getAllTechniciansWS, [server_options.value.page, server_options.value.rowsPerPage, filter_search])

            if (response) {
                items.value = response['data'].data;
                server_items_length.value = response['data'].total;
            }
            loading.value = false
        }

        /*
        * Muestra un aviso indicando si la relación técnico-grupo se va a activar o desactivar
        * */
        async function changeRelationStateWarning(selected_item, new_state){
            selected_relation_new_data.value['id'] = selected_item.id
            selected_relation_new_data.value['active'] = new_state
            await fireSweetAlert(`Se va a ${new_state ? 'activar' : 'desactivar'} la relación`, changeStateRelation, '¿Está seguro?')
        }

        /*
        * Actualiza la relación técnico-grupo activandola o desactivandola
        * */
        async function changeStateRelation(){
            let response = await manageWSResponses(updateTechnicianGroupWS, [selected_relation_new_data.value['id'], { active: selected_relation_new_data.value['active']}])

            if (response){
                showNotification('success', response['data']['message'])
                await loadGroupTechnicals()
            }
        }

        /*
        * Muestra un aviso indicando que la relación se va a eliminar
        * */
        async function deleteRelationWarning(selected_item){
            selected_relation_new_data.value['id'] = selected_item.id

            await fireSweetAlert(`Se va a eliminar la relación`, deleteTechnicianFromGroup, '¿Está seguro?')
        }

        /*
        * Elimina la relación entre un técnico y un grupo
        * */
        async function deleteTechnicianFromGroup(){
            let response = await manageWSResponses(deleteTechnicianFromGroupWS, [selected_relation_new_data.value['id']])

            if (response){
                showNotification('success', response['data'])
                await loadGroupTechnicals()
            }
        }

        return{
            changeRelationStateWarning,
            deleteRelationWarning,

            modal_title,
            headers,
            server_items_length,
            server_options,
            loading,
            items,
        }
    }
}
</script>

<style lang="scss" scoped>
.primary-table{
    --easy-table-footer-background-color: unset;
}
</style>