import store from "@/store";
import {showNotification} from "@/utils/aux_functions";
import {escicApiBackendPetition} from "@/utils/escic_api_petition";

/**
 * Obtiene una página con un array de las agrupaciones internas
 *
 * @param {number} page - Número de página al cual acceder
 * @param {number} rows_per_page - Número de elementos que se mostraran por página
 * @param {Object} options - Opciones para la consulta.
 * @param {string} [options.order_by] - El campo por el cual ordenar.
 * @param {string} [options.order_direction] - La dirección de la ordenación ('asc' o 'desc').
 * @param {string} [options.name] - El nombre para buscar.
 * @param {string} [options.active] - Si la agrupación interna está activada o desactivada.
 * @param {boolean} [options.exact_search] - Indica si la búsqueda debe ser exacta.
 * @param {boolean} [options.use_pagination=true] - Indica si la búsqueda debe ser paginada.
 *
 * @return {Object} Objeto con posiciones bool 'result', array 'data' y array 'errors'. El objeto 'data' contiene array de las agrupaciones internas
 */
export async function getSFInternalGroupingsWS(page, rows_per_page, {order_by, order_direction, name, active, exact_search, use_pagination = true}){
    const params_token = {
        logged_user: store.getters.getUsername,
    };

    const params = {
        'page_size': rows_per_page,
        'order_by': order_by,
        'order_direction': order_direction,
        'name': name ,
        'active': active,
        'exact_search': exact_search,
        'use_pagination': use_pagination,
    };

    try {
        return await escicApiBackendPetition('POST', 'internalgrouping/getInternalGroupings?page='+page, params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'internalgrouping/getInternalGroupings');
    }
}

/**
 * Inserta una nueva agrupación interna
 *
 * @param {string} name - Nombre de la agrupación interna a crear
 * @param {boolean} active - Estado de la agrupación interna a crear
 *
 * @return {Object} Objeto con posiciones bool 'result', array 'data' y array 'errors'. El objeto 'data' contiene los datos de la agrupación interna creada
 */
export async function insertSFInternalGroupingWS(name, active){
    const params_token = {
        logged_user: store.getters.getUsername,
    };

    const params = {
        'name': name,
        'active': active,
    };

    try {
        return await escicApiBackendPetition('POST', 'internalgrouping/insertInternalGrouping', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'internalgrouping/insertInternalGrouping');
    }
}

/**
 * Actualiza una agrupación interna
 *
 * @param {number} sf_internal_grouping_id - ID de la agrupación interna a actualizar
 * @param {string} [new_name] - Nuevo nombre que va a tener la agrupación interna
 * @param {string} [active] - Activa o desactiva la agrupación interna
 *
 * @return {Object} Objeto con posiciones bool 'result', array 'data' y array 'errors'. El objeto 'data' contiene un mensaje de éxito
 */
export async function updateSFInternalGroupingWS(sf_internal_grouping_id, new_name, active){
    const params_token = {
        logged_user: store.getters.getUsername,
    };

    const params = {
        'internal_grouping_id': sf_internal_grouping_id,
        'new_name': new_name,
        'active': active,
    };

    try {
        return await escicApiBackendPetition('POST', 'internalgrouping/updateInternalGrouping', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'internalgrouping/updateInternalGrouping');
    }
}