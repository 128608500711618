import store from "@/store";
import {showNotification} from "@/utils/aux_functions";
import {escicApiBackendPetition} from "@/utils/escic_api_petition";

/**
 * Obtiene un array de los grupos de técnicos
 *
 * @param {Object} options - Opciones para la consulta.
 * @param {string} [options.name] - El nombre para buscar.
 * @param {string} [options.parent_group] - El nombre para buscar.
 * @param {number} [options.active] - Bool indicando si se debe filtrar por activos.
 * @param {string} [options.event_dispatcher] - El nombre para buscar.
 * @param {string} [options.registration_user] - El nombre para buscar.
 * @param {string} [options.id_group] - El campo por el cual ordenar.
 * @param {string} [options.order_by] - El campo por el cual ordenar.
 * @param {string} [options.order_direction] - La dirección de la ordenación ('asc' o 'desc').
 *
 * @return {Object} Objeto con posiciones bool 'result', array 'data' y array 'errors'. El objeto 'data' contiene array de los grupos de técnicos
 */
export async function getTechniciansGroupsWS({order_by, order_direction, name, id_group, registration_user, event_dispatcher, active, parent_group}){
    const params_token = {
        logged_user: store.getters.getUsername,
    };

    const params = {
        'order_by': order_by,
        'order_direction': order_direction,
        'name': name ?? null,
        'id_group': id_group ?? null,
        'registration_user': registration_user ?? null,
        'event_dispatcher': event_dispatcher ?? null,
        'active': active ?? null,
        'parent_group': parent_group ?? null,
    };

    try {
        return await escicApiBackendPetition('POST', 'CrmGroupsTechnicians/getAllGroupsTechnicians' , params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'CrmGroupsTechnicians/getAllGroupsTechnicians');
    }
}

/**
 * Inserta un nuevo grupo de técnicos
 *
 * @param {string} name - Nombre del grupo
 * @param {boolean} active - Estado en el que se va a crear el grupo Activo (1) / Inactivo (0)
 * @param {number} parent_group - ID del grupo padre o 0 si se crea como grupo padre
 * @param {boolean} event_dispatcher - 1 o 0
 * @param {boolean} registration_user - 1 o 0
 * @param {number} [enterprise_group] - ID del grupo empresa al que pertenece
 *
 * @return {Object} Objeto con posiciones bool 'result', array 'data' y array 'errors'. El objeto 'data' contiene los datos de la agrupación creada
 */
export async function insertTechnicianGroupWS({name, active,parent_group, event_dispatcher, registration_user, enterprise_group}){
    const params_token = {
        logged_user: store.getters.getUsername,
    };

    const params = {
        'name': name,
        'parent_group': parent_group,
        'active' : active,
        'event_dispatcher' : event_dispatcher,
        'registration_user': registration_user,
        'enterprise_group': enterprise_group
    };

    try {
        return await escicApiBackendPetition('POST', 'CrmGroupsTechnicians/setGroupsTechnicians', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'CrmGroupsTechnicians/setGroupsTechnicians');
    }
}

/**
 * Actualiza un grupo de técnicos
 *
 * @param {object} options - ID del grupo a actualizar
 * @param {number} options.id_group - ID del grupo a actualizar
 * @param {string} [options.name] - Nuevo nombre que va a tener la agrupación
 * @param {number} [options.enterprise_group] - Nuevo nombre que va a tener la agrupación
 * @param {number} [options.parent_group] - Nuevo nombre que va a tener la agrupación
 * @param {number} [options.active] - Nuevo nombre que va a tener la agrupación
 * @param {number} [options.event_dispatcher] - Nuevo nombre que va a tener la agrupación
 * @param {number} [options.registration_user] - Nuevo nombre que va a tener la agrupación
 * @return {Object} Objeto con posiciones bool 'result', array 'data' y array 'errors'. El objeto 'data' contiene un mensaje de éxito
 */
export async function updateTechnicianGroupWS({id_group, name, enterprise_group, parent_group, active, event_dispatcher, registration_user}){
    const params_token = {
        logged_user: store.getters.getUsername,
    };

    const params = {
        'id_group': id_group,
        'name': name,
        'enterprise_group': enterprise_group,
        'parent_group': parent_group,
        'active': active,
        'event_dispatcher' : event_dispatcher,
        'registration_user': registration_user,
    };

    try {
        return await escicApiBackendPetition('POST', 'CrmGroupsTechnicians/updateGroupsTechnicians', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'CrmGroupsTechnicians/updateGroupsTechnicians');
    }
}