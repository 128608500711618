<template>
    <BaseModalTemplate
            :title="modal_title"
            :open-close-dialog="open_modal"
            :loading="loading"
            card_extra_classes="modal-width-xs"
            :sendForm="sendForm"
    >
        <template #body-modal-content>
            <v-row>
                <v-col cols="12">
                    <span class="input-title">Nombre</span>
                    <v-text-field
                            placeholder="Nombre"
                            class="input-error text-field-primary"
                            :error="form_errors.name"
                            v-model.trim="technicians_group_data.name"
                            variant="underlined"
                            hide-details
                    >
                    </v-text-field>
                    <span v-if="form_errors.name"
                          class="input-error msg-error"
                    >
                        El campo no puede estar vacío
                    </span>
                </v-col>
                <v-col cols="12">
                    <span class="input-title">Grupo padre</span>
                    <v-autocomplete
                        placeholder="Grupo padre"
                        v-model="technicians_group_data.parent_group"
                        :items="parent_group_list"
                        item-title="nombre"
                        item-value="id_grupo"
                        variant="underlined"
                        class="select-primary input-error"
                        :loading="loading"
                        :error="form_errors.parent_group"
                        :menu-props="{ width: '200px'}"
                    >
                    </v-autocomplete>
                    <span v-if="form_errors.parent_group"
                          class="input-error msg-error"
                    >
                        El campo no puede estar vacío
                    </span>
                </v-col>
                <v-col cols="12">
                    <span class="input-title">Empresa grupo</span>
                    <v-autocomplete
                        placeholder="Empresa grupo"
                        v-model="technicians_group_data.enterprise_group"
                        :items="available_groups"
                        item-title="nombre"
                        item-value="id_grupo"
                        variant="underlined"
                        class="select-primary input-error"
                        :loading="loading"
                        :error="form_errors.enterprise_group"
                        :menu-props="{ width: '200px'}"
                    >
                    </v-autocomplete>
                </v-col>
                <v-col cols="12">
                    <span class="input-title">Usuario matricula</span>
                    <div style="display: inline-block; width: auto ">
                        <v-checkbox
                            label="Si permite"
                            v-model.number="technicians_group_data.registration_user"
                            :true-value="1"
                            :false-value="0"
                            :color="'#AD7BE9'"
                            hide-details
                        >
                        </v-checkbox>
                    </div>
                </v-col>
                <v-col cols="12">
                    <span class="input-title">Asignador eventos</span>
                    <div style="display: inline-block; width: auto ">
                        <v-checkbox
                            label="Si permite"
                            v-model.number="technicians_group_data.event_dispatcher"
                            :true-value="1"
                            :false-value="0"
                            :color="'#AD7BE9'"
                            hide-details
                        >
                        </v-checkbox>
                    </div>
                </v-col>
                <v-col cols="12">
                    <span class="input-title">Estado</span>
                    <div style="display: inline-block; width: auto ">
                        <v-checkbox
                            label="Activo"
                            v-model="technicians_group_data.active"
                            :true-value="1"
                            :false-value="0"
                            :color="'#AD7BE9'"
                            hide-details
                        >
                        </v-checkbox>
                    </div>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>

<script>
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";
import {onBeforeMount, ref} from "vue";
import {manageWSResponses, showNotification, variableIsDefined} from "@/utils/aux_functions";
import {insertSFTypeWS, updateSFTypeWS} from "@/utils/sf_types";
import {insertTechnicianGroupWS, updateTechnicianGroupWS} from "@/utils/technicians_groups";

export default {
    name: "ManageTechniciansGroupsModal",
    components: {BaseModalTemplate},
    props: {
        open_modal: Function,
        reloadData: Function,

        available_groups: Array,
        action: String, //Indica si el modal se usará para insertar un valor o para actualizar
        selected_group: Object, //Si el modal se usará para actualizar debe de haber un ID del elemento a actualizar
    },
    setup(props) {
        let loading = ref(false)
        let technicians_group_data = ref({
            'active': 0,
            'registration_user': 0,
            'event_dispatcher': 0,
        })
        let form_errors = ref({})
        let modal_title = ref("Añadir nuevo grupo de técnicos")

        let parent_group_list = ref([])

        onBeforeMount(() => {
            parent_group_list.value = [{id_grupo: 0, nombre: 'ES GRUPO PADRE'}, ...props.available_groups]

            if (props.action === 'update'){
                technicians_group_data.value = {...props.selected_group};
                modal_title.value = `Actualización del grupo con ID ${technicians_group_data.value.id_group}`
            }
        })

        /**
         * Llama al ws de insertar o actualizar según la acción que se vaya a realizar
         *
         */
        async function sendForm () {
            switch (props.action) {
                case "insert":
                    await insertNewTechnicianGroup()
                    break;
                case "update":
                    await updateTechnicianGroup()
                    break;
            }
        }

        /**
         * Inserta un nuevo grupo de técnicos
         *
         */
        async function insertNewTechnicianGroup() {
            form_errors.value = {}
            loading.value = true

            if (validateData()){
                let response = await manageWSResponses(insertTechnicianGroupWS, [technicians_group_data.value])

                if (response){
                    showNotification('success', 'Se ha insertado un nuevo grupo de técnicos')
                    await props.reloadData()
                    props.open_modal()
                }
            }
            loading.value = false
        }

        /**
         * Actualiza una agrupación
         *
         */
        async function updateTechnicianGroup() {
            loading.value = true

            if (validateData()){
                let response = await manageWSResponses(updateTechnicianGroupWS, [technicians_group_data.value])

                if (response){
                    showNotification('success', response.data.message)
                    await props.reloadData()
                    props.open_modal()
                }
            }

            loading.value = false
        }

        /**
         * Valida que los campos de nombre, grupo padre y empresa grupo tengan un valor
         *
         * @return {boolean} True si están rellenos, false si alguno no lo está
         */
        function validateData() {
            let correct = true
            form_errors.value = {}

            let data_keys = ['name', 'parent_group'];

            data_keys.forEach(data_key => {
                if (!variableIsDefined(technicians_group_data.value[data_key]) && technicians_group_data.value[data_key] !== 0){
                    form_errors.value[data_key] = true
                    correct = false
                }
            })

            return correct
        }

        return {
            sendForm,

            parent_group_list,
            modal_title,
            form_errors,
            technicians_group_data,
            loading,
        }
    }
}
</script>

<style scoped>

</style>