<template>
    <BaseModalTemplate
            :title="modal_title"
            :open-close-dialog="open_modal"
            :loading="false"
            card_extra_classes="modal-width-xs"
            :sendForm="sendForm"
    >
        <template #body-modal-content>
            <v-row>
                <v-col cols="12">
                    <span class="input-title">Nombre</span>
                    <v-text-field
                        placeholder="Nombre"
                        class="input-error text-field-primary"
                        :error="form_errors.name"
                        v-model.trim="grouping_data.name"
                        variant="underlined"
                        hide-details
                    >
                    </v-text-field>
                    <span v-if="form_errors.name"
                          class="input-error msg-error"
                    >
                        El campo no puede estar vacío
                    </span>
                </v-col>
                <v-col cols="12">
                    <span class="input-title">Manager</span>
                    <v-text-field
                        placeholder="Manager"
                        class="input-error text-field-primary"
                        :error="form_errors.manager"
                        v-model.trim="grouping_data.manager"
                        variant="underlined"
                        hide-details
                    >
                    </v-text-field>
                    <span v-if="form_errors.manager"
                          class="input-error msg-error"
                    >
                        El campo no puede estar vacío
                    </span>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";
import {manageWSResponses, showNotification, variableIsDefined} from "@/utils/aux_functions";
import {insertGroupingWS, updateGroupingWS} from "@/utils/sf_groupings";

export default {
    name: "ManageGroupingModal",
    components: {BaseModalTemplate},
    props: {
        open_modal: Function,
        reloadData: Function,

        action: String, //Indica si el modal se usará para insertar un valor o para actualizar
        selected_grouping: Object, //Si el modal se usará para actualizar debe de haber un ID del elemento a actualizar
    },
    setup(props) {
        let loading = ref(false)
        let grouping_data = ref({})
        let form_errors = ref({})
        let modal_title = ref("Añadir nueva agrupación")

        onBeforeMount(() => {
            if (props.action === 'update'){
                grouping_data.value = { ...props.selected_grouping};
                modal_title.value = `Actualización de agrupación con ID ${grouping_data.value.grouping_id}`
            }
        })

        /**
         * Llama al ws de insertar o actualizar según la acción que se vaya a realizar
         *
         */
        async function sendForm () {
            switch (props.action) {
                case "insert":
                    await insertNewGrouping()
                    break;
                case "update":
                    await updateGrouping()
                    break;
            }
        }

        /**
         * Inserta una nueva agrupación
         *
         */
        async function insertNewGrouping() {
            form_errors.value = {}
            loading.value = true

            if (validateData()){
                let response = await manageWSResponses(insertGroupingWS, [grouping_data.value.name,grouping_data.value.manager])

                if (response){
                    showNotification('success', 'Se ha insertado una nueva agrupación')
                    await props.reloadData()
                    props.open_modal()
                }
            }
            loading.value = false
        }

        /**
         * Actualiza una agrupación
         *
         */
        async function updateGrouping() {
            loading.value = true

            if (validateData()){
                let response = await manageWSResponses(updateGroupingWS, [grouping_data.value.grouping_id, grouping_data.value.name,grouping_data.value.manager])

                if (response){
                    showNotification('success', response.data)
                    await props.reloadData()
                    props.open_modal()
                }
            }

            loading.value = false
        }

        /**
         * Valida que los campos de nombre y manager esten rellenos
         *
         * @return {boolean} True si ambos campos están rellenos, false si alguno no lo está
         */
        function validateData() {
            let correct = true
            form_errors.value = {}

            if (!variableIsDefined(grouping_data.value.name)){
                form_errors.value.name = true
                correct = false
            }

            if (!variableIsDefined(grouping_data.value.manager)){
                form_errors.value.manager = true
                correct = false
            }
            return correct
        }

        return {
            sendForm,

            modal_title,
            form_errors,
            grouping_data,
            loading,
        }
    }
}
</script>

<style scoped>

</style>