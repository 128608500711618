import {createTokenESCIC} from "@/utils/aux_functions";
import store from "@/store";
import axios from "axios";

export async function escicApiBackendPetition(type, url, data, params_token, petition = 'ajax', content_type = 'application/x-www-form-urlencoded; charset=UTF-8') {

    let token = createTokenESCIC(params_token)

    let main_data = {
        'logged_user': store.getters.getUsername,
        'token': token,
        'debug': process.env.NODE_ENV === 'development' ? 1 : 1,
    }
    let final_data = Object.fromEntries(Object.entries(main_data).concat(Object.entries(data)).sort())

    switch (petition.toLowerCase()) {
        case 'ajax':
            return $.ajax({
                type: type,
                url: process.env.VUE_APP_ESCICAPI_BACKEND_URL + url,
                data: final_data,
                headers: {
                    'content-type': content_type,
                }
            });
        case 'axios':
            switch (type.toLowerCase()) {
                case 'post':
                    return axios.post(process.env.VUE_APP_ESCICAPI_BACKEND_URL + url,
                        final_data,
                        {
                            headers: {
                                'content-type': content_type,
                            }
                        }
                    )
                case 'get':
                    return axios.get(process.env.VUE_APP_ESCICAPI_BACKEND_URL + url + final_data,
                        {
                            headers: {
                                'content-type': content_type
                            }
                        }
                    )
            }
    }

}