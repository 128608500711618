<template>
    <BaseModalTemplate
            :title="modal_title"
            :open-close-dialog="open_modal"
            :loading="loading"
            card_extra_classes="modal-width-xs"
            body_extra_classes="custom-height"
            :sendForm="sendForm"
    >
        <template #body-modal-content>
            <v-row>
                <v-col cols="12">
                    <span class="input-title">Nombre de usuario</span>
                    <v-text-field
                            placeholder="Nombre del usuario"
                            class="input-error text-field-primary"
                            :error="form_errors.name"
                            v-model.trim="relation_data.user_id"
                            variant="underlined"
                            hide-details
                    >
                    </v-text-field>
                    <span v-if="form_errors.name"
                          class="input-error msg-error"
                    >
                        El campo no puede estar vacío
                    </span>
                </v-col>
                <v-col cols="12">
                    <span class="input-title">Grupo</span>
                    <v-select
                        class="input-error select-secondary"
                        placeholder="Seleccione un grupo"
                        item-title="nombre"
                        item-value="id_grupo"
                        v-model="relation_data.group_id"
                        :items="available_groups"
                        variant="outlined"
                        hide-details
                        no-data-text="No hay datos disponibles"
                    >
                        <template v-slot:item="{ props, item }">
                            <v-list-item
                                v-bind="props"
                                :title="item.raw.activo ? item.raw.nombre : `${item.raw.nombre} (Inactivo)`"
                                :disabled="!item.raw.activo"
                            ></v-list-item>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12">
                    <span class="input-title">Estado</span>
                    <div style="display: inline-block; width: auto ">
                        <v-checkbox
                            label="Activo"
                            v-model="relation_data.active"
                            :true-value="1"
                            :false-value="0"
                            :color="'#AD7BE9'"
                            hide-details
                        >
                        </v-checkbox>
                    </div>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>

<script>
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";
import {onBeforeMount, ref} from "vue";
import {manageWSResponses, showNotification, variableIsDefined} from "@/utils/aux_functions";
import {insertTargetGroupWS, updateTargetGroupWS} from "@/utils/target_groups";
import {addTechnicianToGroupWS, updateTechnicianGroupWS} from "@/utils/technicians";

export default {
    name: "ManageTechniciansGroupRelationModal",
    components: {BaseModalTemplate},
    props: {
        open_modal: Function,
        reloadData: Function,
        available_groups: Array,

        action: String, //Indica si el modal se usará para insertar un valor o para actualizar
        selected_relation: Object, //Si el modal se usará para actualizar debe de haber un ID del elemento a actualizar
    },
    setup(props) {
        let loading = ref(false)
        let relation_data = ref({'active': 0})
        let form_errors = ref({})
        let modal_title = ref("Añadir usuario a grupo")
        let active_groups = ref([])

        onBeforeMount(() => {
            if (props.action === 'update'){
                relation_data.value = {...props.selected_relation};
                modal_title.value = `Actualización de relación con ID ${relation_data.value.relation_id}`
            }
        })

        //Función que se encarga de llamar al ws de insertar o actualizar según la acción que se vaya a realizar
        async function sendForm () {
            switch (props.action) {
                case "insert":
                    await insertNewRelationTechnicianGroup()
                    break;
                case "update":
                    await updateRelationTechnicianGroup()
                    break;
            }
        }

        /*
        * Crea una nueva relación técnico-grupo
        *
        * */
        async function insertNewRelationTechnicianGroup() {
            form_errors.value = {}
            loading.value = true

            if (variableIsDefined(relation_data.value.user_id)){
                let response = await manageWSResponses(addTechnicianToGroupWS, [ relation_data.value.user_id, relation_data.value.group_id, relation_data.value.active])
                loading.value = false
                if (response){
                    showNotification('success', 'El usuario se ha añadido correctamente al grupo')
                    await props.reloadData()
                    props.open_modal()
                }
            }else{
                form_errors.value.name = true
            }
            loading.value = false
        }

        /*
        * Actualiza uan relación técnico - grupo
        *
        * */
        async function updateRelationTechnicianGroup() {
            form_errors.value = {}
            loading.value = true

            if (variableIsDefined(relation_data.value.user_id)){
                let params = {
                    'active': relation_data.value.active
                }

                if (!(props.selected_relation.user_id === relation_data.value.user_id) || !(props.selected_relation.group_id === relation_data.value.group_id)){
                    params['id_user'] = relation_data.value.user_id
                    params['id_group'] = relation_data.value.group_id
                }

                let response = await manageWSResponses(updateTechnicianGroupWS, [relation_data.value.relation_id, params])
                loading.value = false

                if (response){
                    showNotification('success', response.data.message)
                    await props.reloadData()
                    props.open_modal()
                }
            }else{
                loading.value = false
                form_errors.value.name = true
            }
        }

        return {
            sendForm,

            modal_title,
            form_errors,
            relation_data,
            loading,
            active_groups,
        }
    }
}
</script>

<style scoped>

</style>