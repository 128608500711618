<template>
    <BaseModalTemplate
            :title="modal_title"
            :open-close-dialog="open_modal"
            :loading="false"
            card_extra_classes="modal-width-xs"
            body_extra_classes="custom-height"
            :sendForm="sendForm"
    >
        <template #body-modal-content>
            <v-row>
                <v-col cols="12">
                    <span class="input-title">Nombre</span>
                    <v-text-field
                            placeholder="Nombre"
                            class="input-error text-field-primary"
                            :error="form_errors.name"
                            v-model.trim="sf_sales_channel_data.name"
                            variant="underlined"
                            hide-details
                    >
                    </v-text-field>
                    <span v-if="form_errors.name"
                          class="input-error msg-error"
                    >
                        El campo no puede estar vacío
                    </span>
                </v-col>
                <v-col cols="12" v-if="action === 'update'">
                    <span class="input-title">Estado</span>
                    <div style="display: inline-block; width: auto ">
                        <v-checkbox
                            label="Activo"
                            v-model="sf_sales_channel_data.active"
                            :true-value="1"
                            :false-value="0"
                            :color="'#AD7BE9'"
                            hide-details
                            :disabled="!sf_sales_channel_data.is_safe_deactivate"
                        >
                        </v-checkbox>
                    </div>
                    <span
                            v-if="!sf_sales_channel_data.is_safe_deactivate"
                            class="input-error msg-error"
                    >
                            No se puede desactivar el tipo ya que esta asignado a una fuerza de ventas activa
                        </span>
                </v-col>
                <v-col cols="12" v-else>
                    <span class="input-title">Estado</span>
                    <div style="display: inline-block; width: auto ">
                        <v-checkbox
                            label="Activo"
                            v-model="sf_sales_channel_data.active"
                            :true-value="1"
                            :false-value="0"
                            :color="'#AD7BE9'"
                            hide-details
                        >
                        </v-checkbox>
                    </div>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>

<script>
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";
import {onBeforeMount, ref} from "vue";
import {manageWSResponses, showNotification, variableIsDefined} from "@/utils/aux_functions";
import {insertSalesChannelWS, updateSalesChannelWS} from "@/utils/sales_channels";

export default {
    name: "ManageSalesChannelsModal",
    components: {BaseModalTemplate},
    props: {
        open_modal: Function,
        reloadData: Function,

        action: String, //Indica si el modal se usará para insertar un valor o para actualizar
        selected_sales_channel: Object, //Si el modal se usará para actualizar debe de haber un ID del elemento a actualizar
    },
    setup(props) {
        let loading = ref(false)
        let sf_sales_channel_data = ref({})
        let form_errors = ref({})
        let modal_title = ref("Añadir nuevo canal de ventas")

        onBeforeMount(() => {
            if (props.action === 'update'){
                sf_sales_channel_data.value = { ...props.selected_sales_channel};
                modal_title.value = `Actualización del canal de ventas con ID ${sf_sales_channel_data.value.channel_id}`
            }
        })

        /**
         * Llama al ws de insertar o actualizar según la acción que se vaya a realizar
         *
         */
        async function sendForm () {
            switch (props.action) {
                case "insert":
                    await insertNewSalesChannel()
                    break;
                case "update":
                    await updateSalesChannel()
                    break;
            }
        }

        /**
         * Inserta una nueva agrupación
         *
         */
        async function insertNewSalesChannel() {
            form_errors.value = {}
            loading.value = true

            if (validateData()){
                let response = await manageWSResponses(insertSalesChannelWS, [sf_sales_channel_data.value.name, sf_sales_channel_data.value.active])

                if (response){
                    showNotification('success', 'Se ha insertado un nuevo canal de venta')
                    await props.reloadData()
                    props.open_modal()
                }
            }
            loading.value = false
        }

        /**
         * Actualiza una agrupación
         *
         */
        async function updateSalesChannel() {
            loading.value = true

            if (validateData()){
                let response = await manageWSResponses(updateSalesChannelWS, [sf_sales_channel_data.value.channel_id, sf_sales_channel_data.value.name, sf_sales_channel_data.value.active])

                if (response){
                    showNotification('success', response.data)
                    await props.reloadData()
                    props.open_modal()
                }
            }

            loading.value = false
        }

        /**
         * Valida que el campo de nombre esté relleno
         *
         * @return {boolean} True si el campo está relleno, false si no lo está
         */
        function validateData() {
            let correct = true
            form_errors.value = {}

            if (!variableIsDefined(sf_sales_channel_data.value.name)){
                form_errors.value.name = true
                correct = false
            }

            return correct
        }

        return {
            sendForm,

            modal_title,
            form_errors,
            sf_sales_channel_data,
            loading,
        }
    }
}
</script>

<style lang="scss">

.custom-height {
    min-height: 30vh !important
}

</style>