import store from "@/store";
import {escicApiBackendPetition} from "@/utils/escic_api_petition";
import {showNotification} from "@/utils/aux_functions";

/**
 * Obtiene una página con un array de los técnicos y su grupo asociado
 *
 * @param {number} page - Número de página al cual acceder
 * @param {number} rows_per_page - Número de elementos que se mostraran por página
 * @param {Object} options - Opciones para la consulta.
 * @param {string} [options.id_user] - El nombre para buscar.
 * @param {string} [options.active] - Indica si la relación está activa.
 * @param {string} [options.id_group] - Id del grupo por el que filtrar.
 * @param {string} [options.order_by] - El campo por el cual ordenar.
 * @param {string} [options.order_direction] - La dirección de la ordenación ('asc' o 'desc').
 *
 * @return {Object} Objeto con posiciones bool 'result', array 'data' y array 'errors'. El objeto 'data' contiene array de los técnicos
 */
export async function getAllTechniciansWS(page, rows_per_page, {order_by, order_direction, id_user, id_group, active}) {
    const params_token = {
        logged_user: store.getters.getUsername,
    };
    
    const params = {
        'page_size': rows_per_page,
        'order_by': order_by ?? null,
        'order_direction': order_direction ?? null,
        'id_user': id_user ?? null,
        'id_group': id_group ?? null,
        'active': active ?? null,
    };
    
    try {
        return await escicApiBackendPetition('POST', 'CrmTechniciansGroup/getAllTechniciansGroup?page=' + page, params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'CrmTechniciansGroup/getAllTechniciansGroup');
    }
}

/**
 * Obtiene una página con un array de los técnicos y su grupo asociado
 *
 * @param {number} relation_id - Número de página al cual acceder
 * @param {Object} options - Opciones para la consulta.
 * @param {string} [options.id_user] - El nombre para buscar.
 * @param {string} [options.active] - Indica si la relación está activa.
 * @param {string} [options.id_group] - Id del grupo por el que filtrar.
 *
 * @return {Object} Objeto con posiciones bool 'result', array 'data' y array 'errors'. El objeto 'data' contiene un mensaje de éxito
 */
export async function updateTechnicianGroupWS(relation_id, {id_user, id_group, active}) {
    const params_token = {
        logged_user: store.getters.getUsername,
    };
    
    const params = {
        'id': relation_id,
        'id_user': id_user ?? null,
        'id_group': id_group ?? null,
        'active': active ?? null,
    };
    
    try {
        return await escicApiBackendPetition('POST', 'CrmTechniciansGroup/updateTechnicianGroup', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'CrmTechniciansGroup/updateTechnicianGroup');
    }
}

/**
 * Obtiene una página con un array de los técnicos y su grupo asociado
 *
 * @param {string} id_user - Usuario que se va a añadir al grupo.
 * @param {number} id_group - Grupo al que se va a añadir el usuario.
 * @param {boolean} active - Estado en el que se va a crear la relación.
 *
 * @return {Object} Objeto con posiciones bool 'result', array 'data' y array 'errors'. El objeto 'data' contiene un mensaje de éxito
 */
export async function addTechnicianToGroupWS(id_user, id_group, active) {
    const params_token = {
        logged_user: store.getters.getUsername,
    };
    
    const params = {
        'id_user': id_user ?? null,
        'id_group': id_group ?? null,
        'active': active ?? null,
    };
    
    try {
        return await escicApiBackendPetition('POST', 'CrmTechniciansGroup/addTechnicianToGroup', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'CrmTechniciansGroup/addTechnicianToGroup');
    }
}

/**
 * Elimina la asociación entre técnico y grupo
 *
 * @param {number} relation_id - ID de la relación Técnico - Grupo a eliminar
 *
 * @return {Object} Objeto con posiciones bool 'result', array 'data' y array 'errors'. El objeto 'data' contiene un mensaje de éxito
 */
export async function deleteTechnicianFromGroupWS(relation_id) {
    const params_token = {
        logged_user: store.getters.getUsername,
    };
    
    const params = {
        'id': relation_id,
    };
    
    try {
        return await escicApiBackendPetition('POST', 'CrmTechniciansGroup/deleteTechnicianFromGroup', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'CrmTechniciansGroup/deleteTechnicianFromGroup');
    }
}