import md5 from "md5";
import store from "@/store";
import {getSweetNotification} from "@/utils/sweetalert";

export const generateRandomString = (num) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result1 = ' ';
    const charactersLength = characters.length;
    for (let i = 0; i < num; i++) {
        result1 += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result1;
}

export function htmlDecode(input) {
    let ret = "";
    if (typeof input !== 'undefined' && input !== null) {
        ret = input.replace(/&gt;/g, '>');
        ret = ret.replace(/&lt;/g, '<');
        ret = ret.replace(/&quot;/g, '"');
        ret = ret.replace(/&apos;/g, "'");
    }
    return ret;
}

export function getHtmlElements(text, tag) {
    const parser = new DOMParser()
    const content = parser.parseFromString(text, 'text/html')
    const html_elements = content.getElementsByTagName(tag);

    return html_elements
}

export function showNotification(tipo, text, many_messages = false, msg = '') {
    let notification_text = text;

    if (typeof text === "object") {
        if (text[0].title !== undefined) {
            notification_text = text[0].title;
        }
        if (many_messages) {
            notification_text = '';
            text.forEach(item => {
                notification_text += item + '\n';
            });
        }
    }
    if (many_messages) {
        let listItems = '';
        if (notification_text) {
            if (variableIsDefined(msg)){
                listItems = msg
            }
            notification_text.split('\n').forEach(item => {
                listItems += `<li>${item}</li>`;
            });
        }
        const listContent = `<ul>${listItems}</ul>`;
        getSweetNotification().fire({
            icon: tipo,
            text: notification_text,
            html: listContent
        })
    } else {
        getSweetNotification().fire({
            icon: tipo,
            text: notification_text,
        })
    }


}

export function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
}


export function createTokenAISEO(params = {}) {

    var final_token = "";

    Object.entries(params).sort().forEach(([key, value]) => {
        final_token += key + '=' + value + ';'
    });

    return md5(process.env.VUE_APP_AISEO_TOKEN_WS_KEY + final_token);
}

export function createTokenESCIC(params = {}) {

    let final_token = "";

    Object.entries(params).sort().forEach(([key, value]) => {
        final_token += key + '=' + value + ';'
    });

    return md5(process.env.VUE_APP_ESCIC_TOKEN_WS_KEY + final_token);
}

export function exportXLSX(name_sheet, file_name, data) {
    let XLSX;
    if (typeof XLSX == 'undefined') XLSX = require('xlsx');
    let ws = XLSX.utils.json_to_sheet(data);
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name_sheet);
    XLSX.writeFile(wb, file_name);
}


export async function copyClipboard(data) {
    navigator.clipboard.writeText(data).then(
        () => {
            showNotification("success", "Dato copiado")
        },
        () => {
            showNotification("error", "No se ha podido copiar")
        },
    );

}

export function variableIsDefined(variable) {
    return (variable !== undefined && variable !== null && variable !== "" && variable !== 0 && variable !== [] && variable !== {})
}

export function removeAccents(string) {
    return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}


export function b64EncodeUnicode(str) {
    // first we use encodeURIComponent to get percent-encoded Unicode,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode('0x' + p1);
        }));
}

export function objectsHasSameInfo(objetoA, objetoB, strict = false) {
    // Comprobar si ambos parámetros son objetos
    if (typeof objetoA !== 'object' || typeof objetoB !== 'object') {
        return false;
    }

    // Obtener las claves de las propiedades de cada objeto
    const clavesObjetoA = Object.keys(objetoA);
    const clavesObjetoB = Object.keys(objetoB);

    // Comprobar si el número de propiedades es el mismo
    if (clavesObjetoA.length !== clavesObjetoB.length) {
        return false;
    }

    // Recorrer las claves y comparar los valores de las propiedades
    for (const clave of clavesObjetoA) {
        if (!clavesObjetoB.includes(clave)) {
            return false;
        } else {
            if (strict) {
                if (objetoA[clave] !== objetoB[clave]) {
                    return false;
                }
            } else {
                if (objetoA[clave] != objetoB[clave]) {
                    return false;
                }
            }

        }
    }

    // Si todas las comparaciones pasaron, los objetos son iguales
    return true;
}


export function formatDate(complete_date) {
    let date = new Date(complete_date.toString().substring(0, 10)),
        day = '' + date.getDate(),
        month = '' + (date.getMonth() + 1),
        year = '' + date.getFullYear();

    if (day.length < 2) {
        day = '0' + day;
    }

    if (month.length < 2)
        month = '0' + month;

    let date_first = [day, month, year].join('-');
    let date_second = complete_date.toString().substring(11);

    return date_first + ' ' + date_second;
}

export function validateEmail(email) {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return ((regex.exec(email)) !== null);
}

export const manageWSResponses = async (WSFunction, params = []) =>{

    const response = await WSFunction(...params)

    if (!response['result']){
        showNotification('error', response['errors'])
        return false
    }

    return response
}
