<template>
    <v-row>
        <v-col cols="12" class="text-center position-content">
            <img :src="require('@/assets/gifs/loading.gif')"
                 alt="Loading Gif" :class="class_loading">
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "Loading",
props:{
    class_loading :{
        default: 'loading-gif-m'
    }
}
}

</script>

<style scoped>
.position-content{
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>