import {createStore} from 'vuex'

export default createStore({
    state: {
        username: '',
        group: '',
        aicore_token: '',
        portal_id: 0,
        user_roles: [],
        roles: [],
        children_menu: false,
    },

    getters: {
        getToken(state) {
            let token = state.aicore_token
            if (token === "") {
                token = localStorage.getItem('token')
            }
            return token;
        },

        getUsername(state) {
            let username = state.username
            if (username === "" && localStorage.getItem('username')) {
                try {
                    username = atob(localStorage.getItem('username'))
                } catch (err) {
                    localStorage.clear();
                }

            }
            return username;
        },
        

        getUserGroup(state) {
            let group = state.group
            if (group === "" && localStorage.getItem('group')) {
                try {
                    group = atob(localStorage.getItem('group'))
                } catch (err) {
                    localStorage.clear();
                }
            }
            return group;
        },
        

        getRolesUser(state) {
            let checked_rol = state.user_roles;

            if (checked_rol.length === 0) {
                checked_rol = JSON.parse(localStorage.getItem('user_roles'))
            }

            return checked_rol;
        },

        getRoles(state) {
            let roles = state.roles;

            if (roles.length === 0) {
                roles = JSON.parse(localStorage.getItem('roles'))
            }

            return roles;
        },
      
        getChildrenMenu(state){
            let children_menu = state.children_menu
            if (children_menu === ""){
                children_menu = localStorage.getItem('children_menu')
            }
            return children_menu;
        }

    },


    mutations: {
        setUser(state, payload) {
            state.username = payload;
        },
        
        setAicoreToken(state, payload) {
            state.aicore_token = payload
        },
        
        setGroup(state, payload) {
            state.group = payload
        },
        
        setRolesUser(state, payload) {
            state.user_roles = payload;
        },
        
        setRoles(state, payload) {
            state.roles = payload
        },
        
        setChildrenMenu(state, payload) {
            state.children_menu = payload
        },

    },
    actions: {},
    modules: {}
})
