<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <span class="main-title-page">Visualización de usuarios por rol</span>
            </v-col>
        </v-row>
        <template v-if="loading_template.loading_page">
            <Loading/>
        </template>
        <template v-else>
            <v-row>
                <v-col cols="12" sm="7" md="6" lg="4">
                    <v-select
                        :items="rol_list"
                        item-title="name"
                        item-value="id_rol"
                        v-model="selected_role"
                        @update:modelValue="getAllTechnicalsRol"
                        class="input-error select-secondary"
                        placeholder="Roles disponibles"
                        no-data-text="No hay datos disponibles"
                        :menu-props="{ width: '250'}"
                        hide-details
                        variant="outlined"
                    >
                        <template v-slot:item="{ props, item }">
                            <v-list-item
                                v-bind="props"
                                :title="item?.raw?.name"
                            >
                            </v-list-item>
                        </template>
                        <template v-slot:selection="{ item }">
                            <div>
                                <span>{{ item?.raw?.name }}</span>
                            </div>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="10" sm="4" md="5" lg="3">
                    <v-text-field
                        v-model.trim="filter_name_user"
                        @keyup.enter="searchUser"
                        @update:model-value="captureFieldStatus"
                        :disabled="!variableIsDefined(selected_role)"
                        class="text-field-secondary"
                        placeholder="Buscar por nombre de usuario..."
                        append-inner-icon="mdi-magnify"
                        single-line
                        bg-color="white"
                        color="transparent"
                        hide-details
                    ></v-text-field>
                </v-col>
                <v-col cols="2" sm="1" class="align-self-center">
                    <img :src="require('@/assets/icons/delete-left-solid.svg')" alt="Eliminar campo usuario"
                         class="delete-filter" @click="cleanNameUser(selected_role)">
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="11" lg="9" class="users-col">
                    <v-row>
                        <v-col>
                            <span class="list-title">Lista de usuarios </span>
                        </v-col>
                    </v-row>
                    <div v-if="loading_template.loading_users" class="general-loading">
                        <Loading class_loading="loading-gif-s"/>
                    </div>
                    <template v-else>
                        <v-row v-if="show_users">
                            <v-col v-if="list_user_by_role.length > 0">
                                <v-list class="personalize-scroll list-users">
                                    <v-list-item
                                        class="text-list"
                                        v-for="user in list_user_by_role"
                                        :key="user.id"
                                        :title="user.name"
                                    ></v-list-item>
                                </v-list>
                            </v-col>
                            <v-col v-else>
                                <span>No hay usuarios asignados en este rol.</span>
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col class="default-text">
                                <span>Seleccione un rol para mostrar los usuarios</span>
                            </v-col>
                        </v-row>
                    </template>

                </v-col>
            </v-row>
        </template>
    </v-container>
</template>

<script>
import {useRoute} from "vue-router";
import {onBeforeMount, ref} from "vue";
import {hasPermission} from "@/utils/auth";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import {getAllRolesWS, getAllTechnicalsRolWS} from "@/utils/admin_functions";
import Loading from "@/components/Generic/Loading.vue";

export default {
    name: "ManagerUserRolesView",
    methods: {variableIsDefined},
    components: {Loading},

    setup() {
        let route = useRoute();
        let selected_role = ref();
        let rol_list = ref([]);
        let list_user_by_role = ref([]);
        let filter_name_user = ref(null);
        let show_users = ref(false)
        let loading_template = ref({
            loading_page: false,
            loading_users: false,
        })
        let field_status = false


        onBeforeMount(async () => {
            loading_template.value.loading_page = true;
            await hasPermission(route);
            await getAllRoles();
            loading_template.value.loading_page = false;
        })

        async function getAllRoles() {
            let {result, data, errors} = await getAllRolesWS();
            if (result) {
                rol_list.value = data;
            } else {
                showNotification('error', errors)
            }
        }

        async function getAllTechnicalsRol() {
            loading_template.value.loading_users = true;
            let {result, data, errors} = await getAllTechnicalsRolWS(selected_role.value, filter_name_user.value)
            if (result) {
                list_user_by_role.value = data;
                field_status = false
            } else {
                showNotification('error', errors)
            }
            show_users.value = true;
            loading_template.value.loading_users = false;
        }

        function captureFieldStatus() {
            /*CADA VEZ QUE CAMBIE EL TXT_FIELD SE ACTIVA PARA HACER LA PETICIÓN AL PULSAR ENTER*/
            field_status = true
        }

        async function searchUser() {
            if (field_status) {
                await getAllTechnicalsRol(filter_name_user.value)
            }
        }

        function cleanNameUser() {
            if (variableIsDefined(filter_name_user.value) && variableIsDefined(selected_role.value)) {
                filter_name_user.value = null
                getAllTechnicalsRol()
            }
        }

        return {
            getAllTechnicalsRol,
            captureFieldStatus,
            searchUser,
            cleanNameUser,

            rol_list,
            show_users,
            selected_role,
            loading_template,
            filter_name_user,
            list_user_by_role,
        }
    }
}
</script>

<style lang="scss">
@import "@/styles/views/admin/manager-user-roles.scss";
</style>