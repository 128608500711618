import store from "@/store";
import {showNotification} from "@/utils/aux_functions";
import {escicApiBackendPetition} from "@/utils/escic_api_petition";

/**
 * Obtiene una página con un array de los técnicos y su grupo asociado
 *
 * @param {number} page - Número de página al cual acceder
 * @param {number} rows_per_page - Número de elementos que se mostraran por página
 * @param {Object} options - Opciones para la consulta.
 * @param {number} [options.id_group] - Número del identificador del grupo seleccionado
 * @param {string} [options.order_by] - El campo por el cual ordenar.
 * @param {string} [options.order_direction] - La dirección de la ordenación ('asc' o 'desc').
 *
 * @return {Object} Objeto con posiciones bool 'result', array 'data' y array 'errors'. El objeto 'data' contiene array de los técnicos
 */

export async function getTargetGroupsDetails(page, rows_per_page, {order_by, order_direction, id_user, id_group}) {
    const params_token = {
        logged_user: store.getters.getUsername,
    };

    const params = {
        'page_size': rows_per_page,
        'order_by': order_by ?? null,
        'order_direction': order_direction ?? null,
        'id_objective_group': id_group ?? null,
        'page': page ?? null
    };

    try {
        return await escicApiBackendPetition('POST', 'salesforceTargetGroups/getSalesForcesGroupBySalesForcesTargetGroupId?page='+ page , params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'salesforceTargetGroups/getSalesForceTargetGroups');
    }
}
