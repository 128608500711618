<template>
    <v-container class="container-home">
        <v-row class="row-home">
            <template v-for="route in route_system" :key="route.name">
                <v-col cols="12" sm="6" md="4" v-if="route.access && route.active">
                    <router-link @click.prevent="goToLink(route)" :to="route.path" class="link-unestyled">
                        <HomeCard
                            :route_name="route.name"
                            :route_img="route.image"
                            :route_alt="route.alt"
                        ></HomeCard>
                    </router-link>
                </v-col>
            </template>
        </v-row>
    </v-container>
</template>

<script>
import {computed, onBeforeMount, ref, watch} from "vue";
import {systemRoute} from "@/router/routes_home";
import HomeCard from "@/components/Home/HomeCard.vue";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import store from "@/store";
import {useStore} from "vuex";
import App from "@/App.vue";

export default {
    name: "HomeView",
    components: {App, HomeCard},
    setup() {
        let route_system = ref({})
        const route_children_menu = computed(() => store.getters.getChildrenMenu)

        onBeforeMount(async () => {
            route_system.value = systemRoute()

            try{
                if (store.getters.getChildrenMenu.children){
                    route_system.value.forEach((route)=>{
                        if (route.name === store.getters.getChildrenMenu.title ){
                            route_system.value = route.children

                        }
                    })
                }
            }catch (error){
                showNotification('error', error)
            }
        })

        watch(route_children_menu,async (value, oldValue) => {
            if (!value){
                route_system.value = systemRoute()
            }
        })

        async function goToLink(route) {
            if (route.children.length > 0) {
                route_system.value = route.children
                store.commit('setChildrenMenu', route.name);
                localStorage.setItem('children_menu', JSON.stringify(route.name))
            }
            if (route.name === "Volver") {
                route_system.value = systemRoute()
                store.commit('setChildrenMenu', false);
                localStorage.setItem('children_menu', JSON.stringify(false))
            }
        }

        return {
            route_system,
            goToLink,
        }
    }
}
</script>

<style lang="scss" scoped>
.all-content {
    padding: 20rem !important;
}

.container-home {
    min-height: 83vh;
    min-width: inherit;

    .row-home {
        min-height: inherit;
        align-items: center;
        justify-content: center;
        align-content: center;
    }
}
</style>