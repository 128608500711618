<template>
    <BaseModalTemplate
        :title="modal_title"
        :open-close-dialog="open_modal"
        :loading="loading"
        card_extra_classes=""
        :sendForm="sendForm"
    >
        <template #body-modal-content>
            <v-row>
                <v-col cols="12" md="6">
                    <span class="input-title">Nombre</span>
                    <v-text-field
                        placeholder="Nombre"
                        class="input-error text-field-primary "
                        :error="form_errors.name"
                        v-model.trim="sales_force_data.name"
                        variant="underlined"
                        hide-details
                    >
                    </v-text-field>
                    <span v-if="form_errors.name"
                          class="input-error msg-error"
                    >
                        El campo no puede estar vacío
                    </span>
                </v-col>
                <v-col cols="12" md="6">
                    <span class="input-title">Agrupación</span>
                    <v-autocomplete
                        placeholder="Agrupación"
                        v-model="sales_force_data.grouping_id"
                        :items="selects_data.sf_grouping"
                        item-title="nombre"
                        item-value="id_agrupacion"
                        variant="underlined"
                        class="select-primary input-error"
                        :loading="loading"
                        :error="form_errors.grouping_id"
                        :menu-props="{ width: '200px'}"
                    >
                    </v-autocomplete>
                    <span v-if="form_errors.grouping_id"
                          class="input-error msg-error"
                    >
                        El campo no puede estar vacío
                    </span>
                </v-col>
                <v-col cols="12" md="6">
                    <span class="input-title">Grupo objetivo de la fuerza de ventas</span>
                    <v-autocomplete
                        placeholder="Grupo objetivo fuerza de ventas"
                        v-model="sales_force_data.target_group_id"
                        :items="selects_data.sf_target_groups"
                        item-title="nombre"
                        item-value="id_grupo_objetivos"
                        variant="underlined"
                        class="select-primary input-error"
                        :loading="loading"
                        :error="form_errors.target_group_id"
                        :menu-props="{ width: '200px'}"
                    >
                    </v-autocomplete>
                    <span v-if="form_errors.target_group_id"
                          class="input-error msg-error"
                    >
                        El campo no puede estar vacío
                    </span>
                </v-col>
                <v-col cols="12" md="6">
                    <span class="input-title">Tipo</span>
                    <v-autocomplete
                        placeholder="Tipo"
                        v-model="sales_force_data.type_id"
                        :items="selects_data.sf_types"
                        item-title="name"
                        item-value="type_id"
                        variant="underlined"
                        class="select-primary input-error"
                        :loading="loading"
                        :error="form_errors.type_id"
                        :menu-props="{ width: '200px'}"
                    >
                        <template v-slot:item="{ props, item }">
                            <v-list-item
                                v-bind="props"
                                :title="item.raw.name"
                            ></v-list-item>
                        </template>
                    </v-autocomplete>
                    <span v-if="form_errors.type_id"
                          class="input-error msg-error"
                    >
                        El campo no puede estar vacío
                    </span>
                </v-col>
                <v-col cols="12" md="6">
                    <span class="input-title">Agrupación interna</span>
                    <v-autocomplete
                        placeholder="Agrupación interna"
                        v-model="sales_force_data.internal_grouping_id"
                        :items="selects_data.sf_internal_groupings"
                        item-title="name"
                        item-value="internal_grouping_id"
                        variant="underlined"
                        class="select-primary input-error"
                        :loading="loading"
                        :error="form_errors.internal_grouping_id"
                        :menu-props="{ width: '200px'}"
                    >
                        <template v-slot:item="{ props, item }">
                            <v-list-item
                                v-bind="props"
                                :title="item.raw.active ? item.raw.name : `${item.raw.name} (Inactivo)`"
                                :disabled="!item.raw.active"
                            ></v-list-item>
                        </template>
                    </v-autocomplete>
                    <span v-if="form_errors.internal_grouping_id"
                          class="input-error msg-error"
                    >
                        El campo no puede estar vacío
                    </span>
                </v-col>
                <v-col cols="12" md="6">
                    <span class="input-title">Canal de ventas</span>
                    <v-autocomplete
                        placeholder="Canal de ventas"
                        v-model="sales_force_data.channel_id"
                        :items="selects_data.sf_sales_channel"
                        item-title="name"
                        item-value="channel_id"
                        variant="underlined"
                        class="select-primary input-error"
                        :loading="loading"
                        :error="form_errors.channel_id"
                        :menu-props="{ width: '200px'}"
                    >
                        <template v-slot:item="{ props, item }">
                            <v-list-item
                                v-bind="props"
                                :title="item.raw.name"
                            ></v-list-item>
                        </template>
                        <!--                        <template v-slot:item="{ props, item }">-->
                        <!--                            <v-list-item-->
                        <!--                                v-bind="props"-->
                        <!--                                :title="item.raw.active ? item.raw.name : `${item.raw.name} (Inactivo)`"-->
                        <!--                                :disabled="!item.raw.active"-->
                        <!--                            ></v-list-item>-->
                        <!--                        </template>-->
                    </v-autocomplete>
                    <span v-if="form_errors.channel_id"
                          class="input-error msg-error"
                    >
                        El campo no puede estar vacío
                    </span>
                </v-col>
                <v-col cols="12" md="6">
                    <span class="input-title">Grupo de técnicos asociado</span>
                    <v-autocomplete
                        placeholder="Grupo a asociar con la ffvv"
                        v-model="sales_force_data.technician_group_id"
                        :items="sf_technical_groups"
                        item-title="nombre"
                        item-value="id_grupo"
                        variant="underlined"
                        class="select-primary input-error"
                        :loading="loading"
                        :error="form_errors.technician_group_id"
                        :menu-props="{ width: '200px'}"
                        chips
                        small-chips
                        multiple
                    >
                        <template v-slot:item="{ props, item }">
                            <v-list-item
                                v-bind="props"
                                :title="item.raw.activo ? item.raw.nombre : `${item.raw.nombre} (Inactivo)`"
                                :disabled="!item.raw.activo"
                            ></v-list-item>
                        </template>
                    </v-autocomplete>
                    <span v-if="form_errors.technician_group_id"
                          class="input-error msg-error"
                    >
                        El campo no puede estar vacío
                    </span>
                </v-col>
                <v-col cols="12" md="6">
                    <span class="input-title">Zona</span>
                    <v-autocomplete
                        placeholder="Zona"
                        v-model="sales_force_data.zone_id"
                        :items="selects_data.sf_zones"
                        item-title="nombre"
                        item-value="id"
                        variant="underlined"
                        class="select-primary input-error"
                        :loading="loading"
                        :error="form_errors.zone_id"
                        :menu-props="{ width: '200px'}"
                    >
                        <template v-slot:item="{ props, item }">
                            <v-list-item
                                v-bind="props"
                                :title="item.raw.nombre"
                            ></v-list-item>
                        </template>
                    </v-autocomplete>
                    <span v-if="form_errors.zone_id"
                          class="input-error msg-error"
                    >
                        El campo no puede estar vacío
                    </span>
                </v-col>
                <v-col cols="6" v-if="action === 'update'">
                    <span class="input-title">Estado</span>
                    <div style="display: inline-block; width: auto ">
                        <v-checkbox
                            label="Activo"
                            v-model.number="sales_force_data.active"
                            :true-value="1"
                            :false-value="0"
                            :color="'#AD7BE9'"
                            hide-details
                            :disabled="!sales_force_data.is_safe_activate"
                        >
                        </v-checkbox>
                        <p v-if="!sales_force_data.is_safe_activate"
                           class="input-error msg-error float-start"
                        >
                            No se puede activar la fuerza de ventas ya que hay algún elemento relacionado a ella que
                            esta inactivo
                        </p>
                    </div>

                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>

<script>
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";
import {onBeforeMount, ref} from "vue";
import {manageWSResponses, showNotification, variableIsDefined} from "@/utils/aux_functions";
import {getTechniciansGroupsWS} from "@/utils/technicians_groups";
import {getZonesFormated} from "@/utils/zones";
import {getSalesForceDetailsWS, getSalesForcesWS, insertSalesForceWS, updateSalesForceWS} from "@/utils/sales_forces";

export default {
    name: "ManageSalesForcesModal",
    components: {BaseModalTemplate},
    props: {
        open_modal: Function,
        reloadData: Function,

        action: String, //Indica si el modal se usará para insertar un valor o para actualizar
        selected_sales_force: Object, //Si el modal se usará para actualizar debe de haber un ID del elemento a actualizar
        selects_data: Object,
    },
    setup(props) {
        let loading = ref(false)
        let sales_force_data = ref({})
        let form_errors = ref({})
        let modal_title = ref("Añadir nueva fuerza de ventas")

        let sf_technical_groups = ref([])

        let sf_zones_options = ref([]);

        onBeforeMount(async () => {
            loading.value = true
            await loadSelectData()

            if (props.action === 'update') {
                await loadSaleForceData()
                modal_title.value = `Actualización de la fuerza de ventas con ID ${sales_force_data.value.sales_force_id}`
            }

            loading.value = false
        })

        async function loadSaleForceData() {
            let response = await manageWSResponses(getSalesForceDetailsWS, [props.selected_sales_force.sales_force_id])

            if (response) {
                let data = response.data.selected_sales_force
                let groups_id_array = []

                if (data.technicians_groups) {
                    data.technicians_groups.forEach(group => {
                        groups_id_array.push(group['id_grupo'])
                    })
                }

                sales_force_data.value = {
                    'sales_force_id': parseInt(props.selected_sales_force.sales_force_id),
                    'active': data.activo ? parseInt(data.activo) : null,
                    'name': data.nombre ? data.nombre : "",
                    'grouping_id': data.id_agrupacion_fuerza_ventas ? parseInt(data.id_agrupacion_fuerza_ventas) : null,
                    'target_group_id': data.id_grupo_objetivos ? parseInt(data.id_grupo_objetivos) : null,
                    'type_id': (data.id_tipo && data.id_tipo.length > 0) ? data.id_tipo[0]['type_id'] : null,
                    'type_name': (data.id_tipo && data.id_tipo[0] && data.id_tipo[0].name) ? data.id_tipo[0].name : null,
                    'internal_grouping_id': (data.id_agrupacion_interna && data.id_agrupacion_interna.length > 0) ? data.id_agrupacion_interna[0]['internal_grouping_id'] : null,
                    'internal_grouping_name': (data.id_agrupacion_interna && data.id_agrupacion_interna[0] && data.id_agrupacion_interna[0].name) ? data.id_agrupacion_interna[0]['name'] : null,
                    'channel_id': (data.id_canal_venta && data.id_canal_venta.length > 0) ? parseInt(data.id_canal_venta[0].channel_id) : null,
                    'channel_name': (data.id_canal_venta && data.id_canal_venta[0] && data.id_canal_venta[0].name) ? data.id_canal_venta[0]['name'] : null,
                    'technician_group_id': groups_id_array,
                    'is_safe_activate': true,
                    'zone_name': data.zone_name ? data.zone_name : null,
                    'zone_id': data.id_zona ? data.id_zona : null,
                }

            }
        }

        /**
         *  Rellena los siguientes selectores:
         *      Agrupaciones
         *      Grupos de objetivos
         *      Tipo de ffvv
         *      Agrupación interna
         *      Canal de ventas
         *      Grupos de técnicos
         *
         */
        async function loadSelectData() {
            let response = await manageWSResponses(getTechniciansGroupsWS, [{}])

            if (response) {
                sf_technical_groups.value = [...response['data']]
            }
        }

        /**
         * Llama al ws de insertar o actualizar según la acción que se vaya a realizar
         */
        async function sendForm() {
            switch (props.action) {
                case "insert":
                    await insertNewSF()
                    break;
                case "update":
                    await updateSF()
                    break;
            }
        }

        /**
         * Inserta una nueva fuerza de ventas
         */
        async function insertNewSF() {
            form_errors.value = {}
            loading.value = true

            if (validateData()) {
                let function_params = {
                    'name': sales_force_data.value.name,
                    'grouping_id': sales_force_data.value.grouping_id,
                    'target_group_id': sales_force_data.value.target_group_id,
                    'type_id': sales_force_data.value.type_id,
                    'internal_grouping_id': sales_force_data.value.internal_grouping_id,
                    'channel_id': sales_force_data.value.channel_id,
                    'technician_group_id': JSON.stringify(sales_force_data.value.technician_group_id),
                    'zone_name': sales_force_data.value.zone_name,
                    'zone_id': sales_force_data.value.zone_id
                }

                let response = await manageWSResponses(insertSalesForceWS, [function_params])

                if (response) {
                    showNotification('success', 'Se ha insertado una nueva fuerza de ventas')
                    await props.reloadData()
                    props.open_modal()
                }
            }
            loading.value = false
        }

        /**
         * Actualiza una fuerza de ventas
         */
        async function updateSF() {
            loading.value = true

            if (validateData()) {
                let function_params = {
                    'name': sales_force_data.value.name,
                    'grouping_id': sales_force_data.value.grouping_id,
                    'target_group_id': sales_force_data.value.target_group_id,
                    'type_id': sales_force_data.value.type_id,
                    'internal_grouping_id': sales_force_data.value.internal_grouping_id,
                    'channel_id': sales_force_data.value.channel_id,
                    'technician_group_id': JSON.stringify(sales_force_data.value.technician_group_id),
                    'active': sales_force_data.value.active,
                    'sales_force_id': sales_force_data.value.sales_force_id,
                    'zone_id': sales_force_data.value.zone_id
                }

                let response = await manageWSResponses(updateSalesForceWS, [function_params])

                if (response) {
                    showNotification('success', response.data)
                    await props.reloadData()
                    props.open_modal()
                }
            }

            loading.value = false
        }

        /**
         * Valida que los campos estén rellenos
         *
         * @return {boolean} True si todos los campos están rellenos, false si no lo están
         */
        function validateData() {
            form_errors.value = {}
            let correct = true
            let data_keys = ['name', 'grouping_id', 'target_group_id', 'type_id', 'internal_grouping_id', 'channel_id', 'technician_group_id','zone_id'];

            data_keys.forEach(data_key => {
                if (!variableIsDefined(sales_force_data.value[data_key])) {
                    form_errors.value[data_key] = true
                    correct = false
                }
            })

            return correct
        }

        return {
            sendForm,

            form_errors,
            loading,
            modal_title,
            sales_force_data,
            sf_technical_groups,
            sf_zones_options,
        }
    }
}
</script>

<style scoped>

</style>