import store from "@/store";
import {showNotification} from "@/utils/aux_functions";
import {escicApiBackendPetition} from "@/utils/escic_api_petition";

/**
 * Obtiene una página con un array de los canales de venta
 *
 * @param {number} page - Número de página al cual acceder
 * @param {number} rows_per_page - Número de elementos que se mostraran por página
 * @param {Object} options - Opciones para la consulta.
 * @param {string} [options.order_by] - El campo por el cual ordenar.
 * @param {string} [options.order_direction] - La dirección de la ordenación ('asc' o 'desc').
 * @param {string} [options.name] - El nombre para buscar.
 * @param {string} [options.active] - Si el canal de venta está activado o desactivado.
 * @param {boolean} [options.exact_search] - Indica si la búsqueda debe ser exacta.
 * @param {boolean} [options.use_pagination=true] - Indica si la búsqueda debe ser paginada.
 *
 * @return {Object} Objeto con posiciones bool 'result', array 'data' y array 'errors'. El objeto 'data' contiene array de los canales de venta
 */
export async function getSalesChannelsWS(page, rows_per_page, {order_by, order_direction, name, active, exact_search, use_pagination = true}){
    const params_token = {
        logged_user: store.getters.getUsername,
    };

    const params = {
        'page_size': rows_per_page,
        'order_by': order_by,
        'order_direction': order_direction,
        'name': name,
        'active': active,
        'exact_search': exact_search,
        'use_pagination': use_pagination,
    };

    try {
        return await escicApiBackendPetition('POST', 'saleschannel/getSalesChannels?page='+page, params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'saleschannel/getSalesChannels');
    }
}

/**
 * Inserta un nuevo canal de ventas
 *
 * @param {string} name - Nombre del canal de ventas a crear
 * @param {boolean} active - Estado del canal de ventas a crear
 *
 * @return {Object} Objeto con posiciones bool 'result', array 'data' y array 'errors'. El objeto 'data' contiene los datos del canal de ventas creado
 */
export async function insertSalesChannelWS(name, active){
    const params_token = {
        logged_user: store.getters.getUsername,
    };

    const params = {
        'name': name,
        'active': active,
    };

    try {
        return await escicApiBackendPetition('POST', 'saleschannel/insertSalesChannel', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'saleschannel/insertSalesChannel');
    }
}

/**
 * Actualiza un canal de ventas
 *
 * @param {number} sf_sales_channel_id - ID del canal de ventas a actualizar
 * @param {string} [new_name] - Nuevo nombre que va a tener el canal de ventas
 * @param {string} [active] - Activa o desactiva el canal de ventas
 *
 * @return {Object} Objeto con posiciones bool 'result', array 'data' y array 'errors'. El objeto 'data' contiene un mensaje de éxito
 */
export async function updateSalesChannelWS(sf_sales_channel_id, new_name, active){
    const params_token = {
        logged_user: store.getters.getUsername,
    };

    const params = {
        'channel_id': sf_sales_channel_id,
        'new_name': new_name,
        'active': active,
    };

    try {
        return await escicApiBackendPetition('POST', 'saleschannel/updateSalesChannel', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'saleschannel/updateSalesChannel');
    }
}