<template>
    <BaseModalTemplate
            body_extra_classes="custom-height"
            :title="modal_title"
            :open-close-dialog="open_modal"
            :loading="loading"
            card_extra_classes=""
            :hide_actions="true"
    >
        <template #body-modal-content>
            <v-row>
                <v-col cols="12">
                    <Vue3EasyDataTable
                        table-class-name="primary-table"
                        theme-color="#AD7BE9"
                        buttons-pagination
                        :headers="headers"
                        :items="items"
                        v-model:server-options="server_options"
                        :server-items-length="server_items_length"
                        :loading="loading"
                        must-sort
                        rows-per-page-message="Elementos por página: "
                        rows-of-page-separator-message="de"
                        empty-message="No hay datos disponibles"
                    >
                      <template #item-btn_actions="item">
                        <v-menu>
                                <template v-slot:activator="{ props }">
                                    <img :src="require('@/assets/icons/more-options.svg')"
                                         class="btn button-bg-white icon-actions"
                                         alt="Icono puntos"
                                         v-bind="props">
                                </template>
                            </v-menu>
                      </template>
                    </Vue3EasyDataTable>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>

<script>
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";
import {onBeforeMount, ref, watch} from "vue";
import {getTargetGroupsDetails} from "@/utils/get_target_group_details";
import Vue3EasyDataTable from "vue3-easy-data-table";
import {manageWSResponses} from "@/utils/aux_functions";

export default {
    name: "DetailsTargetGroupModal",
    components: {Vue3EasyDataTable, BaseModalTemplate},
    props: {
        open_modal: Function,
        detail: Function,

        action: String, //Indica si el modal se usará para insertar un valor o para actualizar
        selected_group_id: Object, //Si el modal se usará para actualizar debe de haber un ID del elemento a actualizar
    },
    setup(props) {
        let headers = ref([
            { text: 'Año', value: 'anio', sortable: true },
            { text: 'Mes', value: 'mes', sortable: true },
            { text: 'Nombre del Grupo Objetivo', value: 'nombre_grupo_objetivo', sortable: true },
            { text: 'Objetivo', value: 'objetivo', sortable: true }
        ]);
        let server_items_length = ref(0)
        let selected_group_id = ref({})
        let server_options = ref({
            page: 1,
            rowsPerPage: 20,
            sortBy: null,
            sortType: null,
        })
        let loading = ref(false)
        let filter_search = ref({
          "order_by": null,
          "order_direction": null,
          "id_group": null
        })
        let items = ref([])
        let modal_title = ref(`Objetivos establecidos por grupo:`)
        let selected_relation_new_data = ref({})


        onBeforeMount(async() => {
          await getObjetives()
        })

        watch(server_options, async () => {
                await getObjetives()
            },
            {deep: true}
        );

        async function getObjetives() {
            loading.value = true;
            selected_group_id.value = {...props.selected_group_id};

            const {sortBy, sortType} = server_options.value
            filter_search.value['order_by'] = sortBy;
            filter_search.value['order_direction'] = sortType;
            filter_search.value['id_group'] = props.selected_group_id.id_group

            let response = await manageWSResponses(getTargetGroupsDetails, [server_options.value.page, server_options.value.rowsPerPage, filter_search.value]);

            if (response && response.result) {
                items.value = response['data'].data;
                server_items_length.value = response.data.length;
            }
            loading.value = false;
        }

        return {
            modal_title,
            selected_group_id,
            headers,
            server_items_length,
            server_options,
            loading,
            items,
        }
    }
}
</script>

<style lang="scss">

.custom-height {
    min-height: 15vh !important
}

</style>