import store from "@/store";
import {showNotification} from "@/utils/aux_functions";
import {escicApiBackendPetition} from "@/utils/escic_api_petition";

/**
 * Obtiene una página con un array de las fuerzas de ventas
 *
 * @param {number} page - Número de página al cual acceder
 * @param {number} rows_per_page - Número de elementos que se mostraran por página
 * @param {Object} options - Opciones para la consulta.
 * @param {string} [options.order_by] - El campo por el cual ordenar.
 * @param {string} [options.order_direction] - La dirección de la ordenación ('asc' o 'desc').
 * @param {string} [options.name] - El nombre para buscar.
 * @param {string} [options.active] - Si la fuerza de venta está activada o desactivada.
 * @param {boolean} [options.exact_search] - Indica si la búsqueda debe ser exacta.
 * @param {number} [options.grouping] - Indica si la búsqueda debe ser exacta.
 * @param {number} [options.zone] - Indica si la búsqueda debe ser exacta.
 * @param {number} [options.target_group] - Indica si la búsqueda debe ser exacta.
 * @param {number} [options.type] - Indica si la búsqueda debe ser exacta.
 * @param {number} [options.internal_grouping] - Indica si la búsqueda debe ser exacta.
 * @param {number} [options.sales_channel] - Indica si la búsqueda debe ser exacta.
 *
 * @return {Object} Objeto con posiciones bool 'result', array 'data' y array 'errors'. El objeto 'data' contiene array de las fuerzas de ventas
 */
export async function getSalesForcesWS(page, rows_per_page, {
    order_by,
    order_direction,
    name,
    active,
    exact_search,
    grouping,
    target_group,
    type,
    internal_grouping,
    sales_channel,
    zone
}) {
    const params_token = {
        logged_user: store.getters.getUsername,
    };
    
    const params = {
        'active': active,
        'exact_search': exact_search,
        'grouping': grouping,
        'internal_grouping': internal_grouping,
        'name': name,
        'order_by': order_by,
        'order_direction': order_direction,
        'page_size': rows_per_page,
        'sales_channel': sales_channel,
        'target_group': target_group,
        'type': type,
        'zone': zone
    };
    
    try {
        return await escicApiBackendPetition('POST', 'salesforce/getSalesForces?page=' + page, params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'salesforce/getSalesForces');
    }
}

/**
 * Obtiene los detalles de una fuerza de ventas
 *
 * @param {number} sales_force_id - ID de la fuerza de ventas a consultar
 *
 * @return {Object} Objeto con posiciones bool 'result', array 'data' y array 'errors'. El objeto 'data' contiene los datos de la fuerza de ventas consultada
 */
export async function getSalesForceDetailsWS(sales_force_id) {
    
    const params_token = {
        logged_user: store.getters.getUsername,
    };
    
    const params = {
        'sales_force_id': sales_force_id,
    };
    
    try {
        return await escicApiBackendPetition('POST', 'salesforce/getSalesForceDetails', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'salesforce/getSalesForceDetails');
    }
}

/**
 * Inserta una nueva fuerza de ventas
 *
 * @param {string} name - Nombre de la fuerza de ventas a crear
 * @param {number} grouping_id - ID de la agrupación
 * @param {number} target_group_id - ID del grupo objetivo
 * @param {number} type_id - ID del tipo de FFVV
 * @param {number} internal_grouping_id - ID de la agrupación interna
 * @param {number} channel_id - ID del canal de ventas
 * @param {number} technician_group_id - ID del grupo de técnicos asociado
 * @param {number} zone_id - ID de la zona
 *
 * @return {Object} Objeto con posiciones bool 'result', array 'data' y array 'errors'. El objeto 'data' contiene los datos de la fuerza de ventas creada
 */
export async function insertSalesForceWS({
                                             name,
                                             grouping_id,
                                             target_group_id,
                                             type_id,
                                             technician_group_id,
                                             internal_grouping_id,
                                             channel_id,
                                             zone_id
                                         }) {
    const params_token = {
        logged_user: store.getters.getUsername,
    };
    
    const params = {
        'name': name,
        'sf_grouping_id': grouping_id,
        'sf_target_group_id': target_group_id,
        'type': type_id,
        'internal_grouping': internal_grouping_id,
        'sales_channel': channel_id,
        'technical_group': technician_group_id,
        'id_zona': zone_id
    };
    
    
    try {
        return await escicApiBackendPetition('POST', 'salesforce/insertSalesForce', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'salesforce/insertSalesForce');
    }
}

/**
 * Actualiza una fuerza de ventas
 *
 * @param {Object} options - Objeto con los datos a actualizar
 * @param {number} [options.sales_force_id] - ID de la fuerza de ventas
 * @param {string} [options.name] - Nuevo nombre que va a tener la fuerza de ventas
 * @param {boolean} [options.active] - Activa o desactiva la fuerza de ventas
 * @param {number} [options.grouping_id] - Nueva agrupación de la fuerza de ventas
 * @param {number} [options.target_group_id] - Nuevo grupo objetivo
 * @param {number} [options.type_id] - Nuevo tipo de fuerza de ventas
 * @param {number} [options.internal_grouping_id] - Nueva agrupación interna
 * @param {number} [options.channel_id] - Nuevo canal de ventas
 * @param {number} [options.zone_id] - id de la zona
 * @param {number} [options.technician_group_id] - ID del grupo de técnicos asociado
 *
 * @return {Object} Objeto con posiciones bool 'result', array 'data' y array 'errors'. El objeto 'data' contiene un mensaje de éxito
 */
export async function updateSalesForceWS({
                                             sales_force_id,
                                             name,
                                             grouping_id,
                                             target_group_id,
                                             type_id,
                                             internal_grouping_id,
                                             channel_id,
                                             active,
                                             zone_id,
                                             technician_group_id
                                         }) {
    const params_token = {
        logged_user: store.getters.getUsername,
    };
    
    const params = {
        'sales_force_id': sales_force_id,
        'new_name': name,
        'active': active,
        'new_grouping': grouping_id,
        'new_target_group': target_group_id,
        'new_type': type_id,
        'new_internal_grouping': internal_grouping_id,
        'new_sales_channel': channel_id,
        'id_zona': zone_id,
        'new_technical_group': technician_group_id,
    };
    
    try {
        return await escicApiBackendPetition('POST', 'salesforce/updateSalesForce', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'salesforce/updateSalesForce');
    }
}