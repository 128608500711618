<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <span class="main-title-page">Gestión de roles</span>
            </v-col>
        </v-row>
        <template v-if="general_loading">
            <Loading/>
        </template>
        <template v-else>
            <v-row>
                <v-col cols="12" md="6" lg="4">
                    <v-text-field
                        v-model.trim="form_data.filter_name_rol"
                        @keyup.enter="applyFilter"
                        @update:model-value="captureFieldStatus"
                        class="text-field-secondary"
                        placeholder="Buscar por nombre del rol..."
                        append-inner-icon="mdi-magnify"
                        single-line
                        bg-color="white"
                        color="transparent"
                        hide-details
                    ></v-text-field>
                </v-col>
                <v-col cols="2" sm="1" class="align-self-center">
                    <img :src="require('@/assets/icons/delete-left-solid.svg')" alt="Eliminar campo usuario"
                         class="delete-filter" @click="clearFilter">
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="6" lg="8" v-if="show_template.container_edit_rol">
                    <v-row>
                        <v-col cols="12">
                            <span class="list-title">Lista de roles</span>
                        </v-col>
                        <v-col cols="12">
                            <v-list class="personalize-scroll">
                                <v-list-item
                                    v-for="rol in rol_list"
                                    :key="rol.id_rol"
                                    :title="rol.name + ' ['+rol.alias+']'"
                                    append-icon="mdi-chevron-right"
                                    class="active-list"
                                    :value="rol.name"
                                    color="deep-purple-lighten-1"
                                    @click="captureRol(rol.id_rol,rol.name, rol.alias)"
                                ></v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12" md="6" lg="8" v-else>
                    <span style="font-style: italic">No se han encontrado roles</span>
                </v-col>

                <v-col cols="12" md="6" lg="4">
                    <v-row>
                        <v-col cols="12">
                            <span class="list-title">Añadir rol</span>
                        </v-col>

                        <v-col cols="12">
                            <v-text-field
                                v-model.trim="form_data.name_rol_create"
                                placeholder="Nombre del rol *"
                                class="text-field-secondary input-error"
                                :error=variableIsDefined(form_error.name_rol_create)
                                bg-color="white"
                                color="transparent"
                                hide-details
                            ></v-text-field>
                            <span class="input-error msg-error"
                                  v-if="variableIsDefined(form_error.name_rol_create)">{{
                                    form_error.name_rol_create
                                }}</span>
                        </v-col>

                        <v-col cols="12">
                            <v-text-field
                                v-model.trim="form_data.alias_rol"
                                placeholder="Alias del rol *"
                                class="text-field-secondary input-error"
                                :error=variableIsDefined(form_error.alias_rol)
                                bg-color="white"
                                color="transparent"
                                persistent-hint
                                hint="Debe contener letras y guiones bajos"
                            ></v-text-field>
                            <span class="input-error msg-error"
                                  v-if="variableIsDefined(form_error.alias_rol)">{{ form_error.alias_rol }}</span>
                        </v-col>

                        <v-col cols="12" class="text-end">
                            <v-btn class="btn button-primary"
                                   @click="insertRol"
                                   :loading="show_template.save_rol"> Añadir
                            </v-btn>
                        </v-col>
                    </v-row>

                    <template v-if="show_template.container_edit_rol">
                        <v-row>
                            <v-col cols="12">
                                <span class="list-title">Edición de rol</span>
                            </v-col>

                            <v-col cols="12" class="mb-5" v-if="!show_template.selected_role_list">
                                <span style="font-style: italic">Seleccione un rol para poder editarlo</span>
                            </v-col>
                            <v-col v-else>
                                <v-card class="card-edit-rol">
                                    <v-card-title>
                                        <span> {{ rol_selected.name }}</span>
                                    </v-card-title>
                                    <v-divider class="m-0"></v-divider>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12">
                                                <span>¿Quiere editar o eliminar este rol?</span>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12 text-end">
                                                <v-btn class="btn button-bg-white me-2" @click="displayDialogDelete">
                                                    Eliminar
                                                </v-btn>
                                                <v-btn class="btn button-bg-orange"
                                                       @click="displayDialogConfirmationMessage">Editar
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </template>
                </v-col>
            </v-row>
        </template>

        <v-dialog v-model="show_template.dialog_confirmation">
            <BaseModalTemplate
                title="Editar el nombre del rol"
                card_extra_classes="modal-width-xs"
                body_extra_classes="content-edit-rol"
                :sendForm="updateRol"
                :open-close-dialog="displayDialogConfirmationMessage"
            >
                <template #body-modal-content>
                    <v-text-field
                        placeholder="Nombre del rol *"
                        class="input-error text-field-primary"
                        :error=variableIsDefined(form_error.name_rol_update)
                        v-model.trim="form_data.name_rol_update"
                        variant="underlined">
                    </v-text-field>
                    <span class="input-error msg-error"
                          v-if="variableIsDefined(form_error.name_rol_update)">{{ form_error.name_rol_update }}</span>
                </template>
            </BaseModalTemplate>
        </v-dialog>
    </v-container>

</template>

<script>
import {useRoute} from "vue-router";
import {onBeforeMount, ref} from "vue";
import {hasPermission} from "@/utils/auth";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import {deleteRolWS, getAllRolesWS, insertRolWS, updateRolWS} from "@/utils/admin_functions"
import Loading from "@/components/Generic/Loading.vue";
import {fireSweetAlert, initSweetAlert} from "@/utils/sweetalert";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";
import * as Yup from "yup";

export default {
    name: "ManagerRolesView",
    methods: {variableIsDefined},
    components: {BaseModalTemplate, Loading},

    setup() {
        let route = useRoute();
        let form_data = ref({});
        let form_error = ref({});
        let rol_list = ref([]);
        let rol_selected = ref({});
        let general_loading = ref(false);
        let show_template = ref({
            selected_role_list: false,
            container_edit_rol: false,
            save_rol: false,
            dialog_confirmation: false
        })
        let field_status = false

        onBeforeMount(async () => {
            general_loading.value = true;
            await hasPermission(route);
            await getAllRoles();
            initSweetAlert()
            general_loading.value = false;
        })

        function captureRol(rol_id, rol_name, alias) {
            rol_selected.value = {
                'id_rol': rol_id,
                'name': rol_name,
                'alias': alias,
            }
            show_template.value.selected_role_list = true;
        }

        function displayDialogConfirmationMessage() {
            form_data.value.name_rol_update = rol_selected.value['name'];
            show_template.value.dialog_confirmation = !show_template.value.dialog_confirmation;
        }

        function displayDialogDelete() {
            fireSweetAlert(`¿Quiere eliminar el rol ${rol_selected.value['name']}?`, deleteRol)
        }

        async function getAllRoles(filter_name = null) {
            let {result, data, errors} = await getAllRolesWS(filter_name)
            if (result) {
                rol_list.value = data
                show_template.value.container_edit_rol = rol_list.value.length !== 0;
                field_status = false
            } else {
                showNotification('error', errors)
            }
        }

        async function updateRol() {
            if (await validateRol('update')) {
                show_template.value.save_rol = true;
                let rol_data = {
                    'id_rol': rol_selected.value['id_rol'],
                    'name': form_data.value.name_rol_update.toUpperCase(),
                }
                let {result, data, errors} = await updateRolWS(rol_data)
                if (result) {
                    rol_selected.value['name'] = rol_data.name;
                    showNotification('success', data);
                    await getAllRoles();
                    displayDialogConfirmationMessage();
                } else {
                    showNotification('error', errors)
                }
                show_template.value.save_rol = false;
            }
        }

        async function insertRol() {
            show_template.value.save_rol = true;
            if (await validateRol('create')) {
                let {
                    result,
                    data,
                    errors
                } = await insertRolWS(form_data.value.name_rol_create, form_data.value.alias_rol);
                if (result) {
                    showNotification('success', 'Rol creado correctamente');
                    await getAllRoles();
                    form_data.value = {};
                    form_data.value.filter_name_rol = null
                } else {
                    showNotification('error', errors);
                }
            }
            show_template.value.save_rol = false;
        }

        async function deleteRol() {
            if (variableIsDefined(rol_selected.value['id_rol'])) {
                let {result, data, errors} = await deleteRolWS(rol_selected.value['id_rol'])
                if (result) {
                    showNotification('success', 'Rol eliminado correctamente');
                    await getAllRoles();
                    show_template.value.selected_role_list = false;
                } else {
                    showNotification('error', errors);
                }
            }
        }

        async function validateRol(type) {
            form_error.value = {};
            let schemaForm = {}
            if (type === 'create') {
                schemaForm = Yup.object().shape({
                    name_rol_create: Yup.string().required('El nombre es obligatorio'),
                    alias_rol: Yup.string().required('El alias es obligatorio')
                        .matches(/^[a-z_]+$/, 'El alias solo puede contener letras minúsculas y guiones bajos'),
                })
            } else if (type === 'update') {
                schemaForm = Yup.object().shape({
                    name_rol_update: Yup.string().required('El campo es obligatorio'),
                })
            }
            try {
                await schemaForm.validate(form_data.value, {abortEarly: false});
                return true
            } catch (error) {
                error.inner.forEach((error) => {
                    form_error.value[error.path] = error.message;
                })
                return false
            }
        }

        function captureFieldStatus() {
            /*CADA VEZ QUE CAMBIE EL TXT_FIELD SE ACTIVA PARA HACER LA PETICIÓN AL PULSAR ENTER*/
            field_status = true
        }

        async function applyFilter() {
            if (field_status) {
                show_template.value.selected_role_list = false;
                await getAllRoles(form_data.value.filter_name_rol)
            }
        }

        async function clearFilter() {
            if (variableIsDefined(form_data.value.filter_name_rol)) {
                form_data.value.filter_name_rol = null
                show_template.value.selected_role_list = false;
                await getAllRoles()
            }
        }

        return {
            captureRol,
            insertRol,
            updateRol,
            applyFilter,
            clearFilter,
            captureFieldStatus,
            displayDialogDelete,
            displayDialogConfirmationMessage,

            form_data,
            form_error,
            rol_list,
            rol_selected,
            show_template,
            general_loading,
        }
    }
}
</script>

<style lang="scss">
@import "@/styles/views/admin/manager-roles.scss";
</style>