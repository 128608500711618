<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="9" lg="10">
                <span class="main-title-page">Grupos asignados a un usuario</span>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="10" sm="7" md="6" lg="4">
                <v-text-field
                    v-model.trim="identifier_user"
                    @keyup.enter="getAllGroups"
                    @update:model-value="captureFieldStatus"
                    class="text-field-secondary input-error"
                    :error="variableIsDefined(form_alert.identifier_user)"
                    placeholder="Identificador del usuario"
                    append-inner-icon="mdi-magnify"
                    single-line
                    bg-color="white"
                    color="transparent"
                    hide-details
                ></v-text-field>
            </v-col>
            <v-col cols="2" sm="1" class="align-self-center">
                <img :src="require('@/assets/icons/delete-left-solid.svg')" alt="Eliminar campo usuario"
                     class="delete-filter" @click="cleanUser">
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="10" sm="7" md="6" lg="4" class="pe-4">
                <span v-if="variableIsDefined(form_alert.identifier_user)"
                      class="input-error msg-error">{{ form_alert.identifier_user }}</span>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <span class="input-title mb-3">Grupos LDAP</span>
            </v-col>
        </v-row>

        <v-row no-gutters>
            <v-col cols="12" v-if="template_info.see_info">
                <Vue3EasyDataTable
                    :hide-footer="group_user.length<=50"
                    class="primary-table user-groups-table"
                    :headers="columns_table"
                    :items="group_user"
                    :loading="loading"
                    :rows-per-page="50"
                    buttons-pagination
                    rows-per-page-message="Elementos por página: "
                    rows-of-page-separator-message="de"
                    empty-message="No hay datos disponibles"

                ></Vue3EasyDataTable>
            </v-col>
            <v-col v-else cols="12">
                <span style="font-style: italic">{{
                        template_info.msg_info || "Debe introducir un usuario para mostrar los grupos asociados"
                    }}</span>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {ref} from "vue";
import {getAllGroupsByTechnicalWS} from "@/utils/admin_functions";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import Vue3EasyDataTable from "vue3-easy-data-table";
import Loading from "@/components/Generic/Loading.vue";
import * as Yup from "yup";

export default {
    name: "ManagerGroupUserView",
    methods: {variableIsDefined},
    components: {Loading, Vue3EasyDataTable},

    setup() {
        let loading = ref(false)
        let identifier_user = ref(null)
        let columns_table = ref([
            {text: 'ID', value: 'id'},
            {text: 'Nombre grupo LDAP', value: 'cn'},
            {text: 'GidNumber', value: 'gidNumber'},
        ]);
        let group_user = ref([])
        let form_alert = ref({})
        let template_info = ref({show_info: false, msg_info: ''})
        let field_status = false

        async function getAllGroupsByUser() {
            let {result, data, errors} = await getAllGroupsByTechnicalWS(identifier_user.value)
            if (result) {
                group_user.value = data
                field_status = false
                if (group_user.value.length !== 0) {
                    template_info.value.see_info = true
                } else {
                    template_info.value.see_info = false
                    template_info.value.msg_info = "No hay grupos para el usuario introducido"
                }
            } else {
                showNotification('error', errors)
                template_info.value.see_info = true
            }

        }

        async function getAllGroups() {
            if (await validate()) {
                if (field_status) {
                    loading.value = true
                    await getAllGroupsByUser()
                    loading.value = false
                }
            } else {
                template_info.value.msg_info = ""
            }
        }

        async function validate() {
            form_alert.value = {};

            let schemaForm = Yup.string()
                .nullable()
                .required('Debe introducir un usuario para hacer la búsqueda')
                .matches(/^[a-z]+\.{1}[a-z]+$/i, 'El identificador debe tener el formato "nombre.apellido"');
            try {
                await schemaForm.validate(identifier_user.value, {abortEarly: false});
                return true;
            } catch (error) {
                error.inner.forEach(err => {
                    form_alert.value['identifier_user'] = err.message;
                });
                return false;
            }
        }


        function captureFieldStatus() {
            /*CADA VEZ QUE CAMBIE EL TXT_FIELD SE ACTIVA PARA HACER LA PETICIÓN AL PULSAR ENTER*/
            field_status = true
        }


        function cleanUser() {
            form_alert.value = {}
            identifier_user.value = null
            template_info.value.see_info = false
            template_info.value.msg_info = ""

        }

        return {
            captureFieldStatus,
            getAllGroups,
            cleanUser,

            identifier_user,
            columns_table,
            group_user,
            form_alert,
            template_info,
            loading,
        }
    }
}
</script>

<style lang="scss">
.user-groups-table {
    .vue3-easy-data-table__main {
        border: solid 2px $primary-color-1 !important;
        border-radius: 8px;
    }
}
</style>