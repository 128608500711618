<template>
    <v-container class="all-containers">
        <v-row>
            <v-col cols="12">
                <span class="main-title-page">Gestión de roles por grupo</span>
            </v-col>
        </v-row>
        <template v-if="loading_template.general_container">
            <Loading/>
        </template>
        <template v-else>
            <v-row>
                <v-col cols="10" md="6" lg="4">
                    <v-text-field
                        v-model.trim="filter_group"
                        @keyup.enter="searchGroup"
                        @update:model-value="captureFieldStatus"
                        class="text-field-secondary"
                        label="Buscar nombre del grupo..."
                        append-inner-icon="mdi-magnify"
                        single-line
                        bg-color="white"
                        color="transparent"
                        hide-details
                    ></v-text-field>
                </v-col>
                <v-col cols="2" sm="1" class="align-self-center">
                    <img :src="require('@/assets/icons/delete-left-solid.svg')" alt="Eliminar campo usuario"
                         class="delete-filter" @click="cleanNameGroup">
                </v-col>
            </v-row>

            <v-row class="box-group-rol">
                <v-col cols="12" md="6" lg="8" class="col-list-group">
                    <v-row no-gutters>
                        <v-col>
                            <span class="list-title">Lista de grupos</span>
                        </v-col>
                    </v-row>
                    <div v-if="loading_template.get_groups" class="general-loading">
                        <Loading class_loading="loading-gif-s"/>
                    </div>
                    <v-row v-else>
                        <v-col v-if="show_template.not_group_length">
                            <span>No se ha encontrado ningún grupo</span>
                        </v-col>
                        <v-col v-else>
                            <v-list class="personalize-scroll" mandatory>
                                <v-list-item
                                    v-for="group in group_list"
                                    :key="group.id_group_ldap"
                                    :title="`${group.name_group} (${group.id_group_ldap})`"
                                    append-icon="mdi-chevron-right"
                                    :value="group.name_group"
                                    color="deep-purple-lighten-1"
                                    class="active-list"
                                    @click="groupClickField(group.id_group_ldap,group.name_group)"
                                ></v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12" md="6" lg="4" class="col-edit-rol" v-if="show_template.container_roles">
                    <v-row no-gutters>
                        <v-col cols="12">
                            <span class="list-title" v-if="!show_template.contains_roles">Roles del grupo</span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="col-edit-rol" v-if="show_template.contains_roles">
                            <span style="font-style: italic">{{ msg_error_rol }}</span>
                        </v-col>
                        <v-col v-else>
                            <div v-if="loading_template.get_roles_group" class="general-loading">
                                <Loading class_loading="loading-gif-s"/>
                            </div>
                            <v-row v-else>
                                <v-col v-if="show_template.all_roles">
                                    <v-card class="list-roles"
                                           >
                                        <v-card-title class="roles-card-title">
                                            <span> {{ group_selected.name_group }}</span>
                                        </v-card-title>
                                        <v-card-text class="group-roles personalize-scroll">
                                            <v-row>
                                                <v-col>
                                                    <v-checkbox v-for="rol in rol_list"
                                                                class="checkbox"
                                                                v-model="selected_roles"
                                                                :label="rol.name"
                                                                :value="rol.name"
                                                                color="deep-purple-lighten-2"
                                                                hide-details
                                                    ></v-checkbox>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                        <v-card-actions class="roles-card-actions">
                                            <v-row>
                                                <v-col class="align-self-center text-end">
                                                    <v-btn class="btn button-primary"
                                                           @click="addRolToGroup"
                                                           :loading="show_template.save_roles_group"
                                                    >
                                                        Guardar
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </template>
    </v-container>
</template>

<script>
import {useRoute} from "vue-router";
import {onBeforeMount, ref} from "vue";
import {hasPermission} from "@/utils/auth";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import {getAllRolesGroupLdapWS, getAllGroupsLdapWS, addRolToGroupWS} from "@/utils/admin_functions"
import Loading from "@/components/Generic/Loading.vue";

export default {
    name: "ManagerGroupView",
    components: {Loading},

    setup() {
        let route = useRoute();
        let group_selected = ref({});
        let rol_list = ref([]);
        let group_list = ref([]);
        let selected_roles = ref([]);
        let loading_template = ref({
            general_container: false, get_roles_group: false, get_groups: false,
        })
        let show_template = ref({
            not_group_length: false, contains_roles: true, all_roles: false, save_roles_group: false, container_roles: true
        })
        let filter_group = ref(null);
        let msg_error_rol = ref('Seleccione un grupo para mostrar sus roles')
        let field_status_filter = false
        let click_list_group = true
        let clone_group_selected


        onBeforeMount(async () => {
            loading_template.value.general_container = true;
            await hasPermission(route);
            await getAllGroups();
            loading_template.value.general_container = false;

        })

        async function getAllGroups() {
            let {result, data, errors} = await getAllGroupsLdapWS(filter_group.value);
            if (result) {
                field_status_filter = false
                group_list.value = data;
                let length_list_group = group_list.value.length === 0;

                show_template.value.contains_roles = !length_list_group;
                show_template.value.container_roles = !length_list_group;
                show_template.value.not_group_length = length_list_group;

            } else {
                showNotification('error', errors)
            }
        }

        async function getAllRolesGroup(id_group_ldap, name_group) {
            group_selected.value = {
                'id_group_ldap': id_group_ldap,
                'name_group': name_group,
            }
            let {result, data, errors} = await getAllRolesGroupLdapWS(id_group_ldap);
            if (result) {
                let final_rol_list = [];
                click_list_group = false

                data.forEach((rol) => {
                    if (rol.active === 1) {
                        final_rol_list.push(rol.name);
                    }
                })
                selected_roles.value = final_rol_list;
                rol_list.value = data
                if (rol_list.value.length === 0) {
                    show_template.value.contains_roles = true
                    msg_error_rol.value = "No hay roles creados para el grupo seleccionado"
                }
            } else {
                showNotification('error', errors)
                show_template.value.contains_roles = true
                msg_error_rol.value = ""
            }
        }

        async function groupClickField(id_group_ldap, name_group) {
            /*CONTROLA LA PETICIÓN DEL LISTADO GRUPOS*/
            show_template.value.contains_roles = false
            show_template.value.not_group_length = false
            loading_template.value.get_roles_group = true;

            if (click_list_group && id_group_ldap !== clone_group_selected) {
                await getAllRolesGroup(id_group_ldap, name_group)
                clone_group_selected = id_group_ldap
                click_list_group = true
            }

            show_template.value.all_roles = true;
            loading_template.value.get_roles_group = false;
        }

        async function addRolToGroup() {
            let roles_list = [];
            show_template.value.save_roles_group = true;

            rol_list.value.forEach((rol) => {
                roles_list.push({'id_rol': rol.id_rol, 'active': selected_roles.value.includes(rol.name) ? 1 : 0})
            })

            let {result, data, errors} = await addRolToGroupWS(group_selected.value['id_group_ldap'], {roles_list})
            if (result) {
                showNotification('success', 'Los roles se han guardado correctamente en el grupo');
            } else {
                showNotification('error', errors)
            }
            show_template.value.save_roles_group = false;
        }


        function captureFieldStatus() {
            /*CADA VEZ QUE CAMBIE EL TXT_FIELD SE ACTIVA PARA HACER LA PETICIÓN AL PULSAR ENTER*/
            field_status_filter = true
        }

        async function searchGroup() {
            loading_template.value.get_groups = true
            show_template.value.contains_roles = true
            if (field_status_filter) {
                await getAllGroups()
            }
            loading_template.value.get_groups = false
        }

        async function cleanNameGroup() {
            if (variableIsDefined(filter_group.value)) {
                loading_template.value.get_groups = true

                filter_group.value = null
                show_template.value.contains_roles = true
                msg_error_rol.value = "Seleccione un grupo para mostrar sus roles"
                await getAllGroups()

                loading_template.value.get_groups = false
            }
        }

        return {
            captureFieldStatus,
            groupClickField,
            cleanNameGroup,
            addRolToGroup,
            searchGroup,

            group_list,
            rol_list,
            group_selected,
            selected_roles,
            filter_group,
            loading_template,
            show_template,
            msg_error_rol,
        }
    }
}
</script>

<style lang="scss">
@import "@/styles/views/admin/manager-group-roles.scss";
</style>