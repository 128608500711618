<template>
    <GenericTableFormPageTemplate
            :get-all-elements="getSFTypesWS"
            :headers="headers"
            page_title="Tipos"
            @insert-new-element="openModalInsert"
            :custom_item_slots="['item-btn_actions', 'item-active', 'item-created_at', 'item-updated_at']"
    >
        <!--Template con el botón de las acciones disponibles, en este caso unicamente la de actualizar-->
        <template #item-btn_actions="{item}">
            <v-menu v-if="userHaveAllowedRol('SALES_FORCE_EDIT')">
                <template v-slot:activator="{ props }">
                    <img :src="require('@/assets/icons/more-options.svg')"
                         class="btn button-bg-white icon-actions"
                         alt="Icono puntos"
                         v-bind="props">
                </template>
                <v-list>
                    <v-list-item>
                        <v-list-item-title class="text-center"
                                           @click="openModalUpdate(item)">
                            <span style="cursor: pointer">Editar</span>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>

        <template #item-active="{item}">
            <span>{{item.active === 1 ? 'Activo' : 'Inactivo'}}</span>
        </template>

        <template #item-created_at="{item}">
            <span>{{formatDate(item.created_at)}}</span>
        </template>

        <template #item-updated_at="{item}">
            <span>{{formatDate(item.updated_at)}}</span>
        </template>

        <template #queue-filters="{form_data}">
            <v-col cols="12" md="6">
                <span class="input-title">Nombre del tipo</span>
                <v-text-field
                        v-model.trim="form_data.name"
                        placeholder="Nombre"
                        class="input-error text-field-secondary"
                        bg-color="white"
                        color="transparent"
                        hide-details
                ></v-text-field>
                <div style="display: inline-block; width: auto ">
                    <v-checkbox
                        label="Búsqueda exacta"
                        v-model="form_data.exact_search"
                        :true-value="1"
                        :false-value="0"
                        :color="'#AD7BE9'"
                        hide-details
                    >
                    </v-checkbox>
                </div>
            </v-col>
            <v-col cols="12" md="6">
                <span class="input-title">Estado</span>
                <v-select
                    class="input-error select-secondary"
                    placeholder="Seleccione un estado"
                    item-title="state"
                    item-value="value"
                    v-model="form_data.active"
                    :items="available_states"
                    variant="outlined"
                    hide-details
                    no-data-text="No hay datos disponibles"
                >
                </v-select>
            </v-col>
        </template>

        <template v-slot:modal-element-actions="{reloadData}">
            <v-dialog v-model="show_insert_modal" persistent>
                <ManageSFTypesModal
                        :open_modal="openCloseModal"
                        :reloadData="reloadData"
                        :action="action"
                        :selected_type="selected_type"
                ></ManageSFTypesModal>
            </v-dialog>
        </template>
    </GenericTableFormPageTemplate>
</template>

<script>
import ManageGroupingModal from "@/components/SFGroupings/ManageGroupingModal.vue";
import GenericTableFormPageTemplate from "@/components/Generic/GenericTableFormPageTemplate.vue";
import {ref} from "vue";
import {getSFTypesWS} from "@/utils/sf_types";
import ManageSFTypesModal from "@/components/SFTypes/ManageSFTypesModal.vue";
import {formatDate} from "@/utils/aux_functions";
import {userHaveAllowedRol} from "@/utils/auth";

export default {
    name: "SFTypesView",
    components: {ManageSFTypesModal, GenericTableFormPageTemplate, ManageGroupingModal},
    methods: {userHaveAllowedRol, formatDate, getSFTypesWS},
    setup() {
        let headers = ref([
            {text: 'ID', value: 'type_id', sortable: true},
            {text: 'NOMBRE', value: 'name', sortable: true},
            {text: 'ESTADO', value: 'active', sortable: true},
            {text: 'CREADO', value: 'created_at', sortable: true},
            {text: 'ACTUALIZADO', value: 'updated_at', sortable: true},
            {text: 'CREADO POR', value: 'created_by', sortable: true},
            {text: 'ACTUALIZADO POR', value: 'updated_by', sortable: true},
            {text: 'ACCIONES', value: 'btn_actions'},
        ])

        let available_states = ref([
            {state: 'Activo', value: 1},
            {state: 'Inactivo', value: 0},
            {state: 'Ambos', value: null},
        ])

        let show_insert_modal = ref(false)
        let action = ref("")

        let selected_type = ref({})

        /*Abre o cierra el modal*/
        function openCloseModal() {
            show_insert_modal.value = !show_insert_modal.value
        }

        function openModalInsert() {
            action.value = 'insert'
            openCloseModal()
        }

        function openModalUpdate({type_id, name, active, is_safe_deactivate}) {
            action.value = 'update'

            selected_type.value = {
                'sf_type_id' : type_id,
                'name' : name,
                'active': active,
                'is_safe_deactivate': is_safe_deactivate
            }

            openCloseModal()
        }

        return{
            openCloseModal,
            openModalInsert,
            openModalUpdate,

            action,
            available_states,
            headers,
            selected_type,
            show_insert_modal,
        }
    }

}
</script>

<style scoped>

</style>