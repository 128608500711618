import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//Bootstrap
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap/dist/js/bootstrap.js"
import "@popperjs/core"

// Vuetify
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'
import {createVuetify} from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'


import "@/styles/general.scss";
import "@/styles/modal-general.scss";
import "@/styles/buttons.scss";
import "@/styles/inputs.scss";
import 'vue3-easy-data-table/dist/style.css';

import svSE from 'date-fns/locale/es'
import {es} from "vuetify/locale";
import DateFnsAdapter from '@date-io/date-fns'

const vuetify = createVuetify({
    locale: {
        messages: {es}
    },
    date: {
        adapter: DateFnsAdapter,
        locale: {
            es: svSE,
        }
    },
    components,
    directives,
    icons: {
        defaultSet: 'mdi',
    },
})


const $ = require('jquery')
window.$ = $
/*const bootstrap = require('bootstrap')
window.bootstrap = bootstrap*/

createApp(App).use(store).use(router).use(vuetify).directive(
    'default-alt', {
        mounted(el, binding) {
            const projectName = 'ESCIC';
            const altText = binding.value || projectName;
            el.setAttribute('alt', altText);
        }}
).directive(
    'default-title',{
        mounted(el, binding) {
            const projectName = 'ESCIC';
            const titleText = binding.value || projectName;
            el.setAttribute('title', titleText);
        }}
).mount('#app')

