<template xmlns="http://www.w3.org/1999/html">
    <template v-if="username && token && roles && user_roles">
        <NavBar :key=roles :renderApp="renderApp"/>
        <v-container
                fluid
                class="app-main-container"
        >
            <v-row class=" d-flex flex-nowrap row-sidebar">
                <div id="collapse-sidebar" class="collapse collapse-horizontal" v-if="$route.name !=='inicio'">
                    <v-col id="sidebar">
                        <Sidebar/>
                    </v-col>
                </div>
                <v-col class="main-content personalize-scroll">
                    <a href="#" data-bs-target="#collapse-sidebar" data-bs-toggle="collapse"
                       class="border rounded-end p-1 text-decoration-none menu-btn" id="lateral-btn"
                       aria-expanded="false" v-if="$route.name !=='inicio'">
                        <img src="@/assets/icons/navbar.svg" alt="sidebar" class="menu-collapsed">
                        <img src="@/assets/icons/left-arrow.svg" alt="sidebar" class="menu-expanded">
                    </a>
                    <div class="all-content">
                        <BreadCrumb v-if="render"/>
                        <router-view v-if="render"/>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </template>
    <AuthView v-else></AuthView>
</template>

<script>
import NavBar from "@/components/Navigation/Navbar.vue";
import Sidebar from "@/components/Navigation/Sidebar.vue";
import {useStore} from "vuex";
import {computed, onBeforeMount, provide, ref, watch} from "vue";
import {logout} from "@/utils/auth";
import AuthView from "@/views/Auth/AuthView";
import router from "@/router";
import {initSweetNotification} from "@/utils/sweetalert";
import BreadCrumb from "@/components/Navigation/BreadCrumb.vue";

export default {
    components: {
        BreadCrumb,
        NavBar,
        Sidebar,
        AuthView,
    },
    setup() {
        const store = useStore();
        let username = computed(() => store.getters.getUsername);
        let token = computed(() => store.getters.getToken);
        let roles = computed(() => store.getters.getRoles);
        let user_roles = computed(() => store.getters.getRolesUser);
        let render = ref(true);

        onBeforeMount(() => {
            existsLoggedUser();
            initSweetNotification()
        })

        const renderApp = () => {
            render.value = true;
        }

        const existsLoggedUser = () => {
            let hours = 4
            let saved = localStorage.getItem('saved')
            if (saved && (new Date().getTime() - saved > hours * 60 * 60 * 1000)) {
                logout()
            }
            if (username.value && token.value) {
                store.commit('setUser', username.value)
            }
        }

        return {
            render,
            username,
            token,
            roles,
            user_roles,
            renderApp,
        }
    }
}
</script>


<style lang="scss">
@import "@/styles/main.scss";
@import "@/styles/components/sweetalert/notification-toast.scss";
</style>