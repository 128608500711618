import {userHaveAllowedRol} from "@/utils/auth";

export function systemRoute() {
    return [
        {
            name: "Fuerzas de ventas",
            alt: "icono curso",
            path: "/",
            image: require('@/assets/icons/navbar/generic-icon.svg'),
            access: (userHaveAllowedRol('SALES_FORCE_READ') || userHaveAllowedRol('SALES_FORCE_EDIT')),
            active: true,
            children: [
                {
                    name: "Agrupaciones",
                    alt: "icono agrupaciones de fuerzas de ventas",
                    path: "/agrupaciones-ffvv",
                    image: require('@/assets/icons/navbar/generic-icon.svg'),
                    access: true,
                    children: [],
                    active: true
                },
                {
                    name: "Grupos de objetivos",
                    alt: "icono Grupos de objetivos de fuerzas de ventas",
                    path: "/grupos-objetivos-ffvv",
                    image: require('@/assets/icons/navbar/generic-icon.svg'),
                    access: true,
                    children: [],
                    active: true
                },
                {
                    name: "Tipos",
                    alt: "icono tipos de fuerza de venta",
                    path: "/tipos-fuerzas-ventas",
                    image: require('@/assets/icons/navbar/generic-icon.svg'),
                    access: true,
                    children: [],
                    active: true
                },
                {
                    name: "Agrupaciones internas",
                    alt: "icono agrupaciones internas",
                    path: "/agrupaciones-internas",
                    image: require('@/assets/icons/navbar/generic-icon.svg'),
                    access: true,
                    children: [],
                    active: true
                },
                {
                    name: "Canales de venta",
                    alt: "icono canales venta",
                    path: "/canales-venta",
                    image: require('@/assets/icons/navbar/generic-icon.svg'),
                    access: true,
                    children: [],
                    active: true
                },
                {
                    name: "Grupos de técnicos",
                    alt: "icono curso prioritario",
                    path: "/cursos-prioritarios",
                    image: require('@/assets/icons/navbar/generic-icon.svg'),
                    access: true,
                    children: [],
                    active: false
                },
                {
                    name: "Fuerzas de ventas",
                    alt: "icono fuerza ventas",
                    path: "/fuerzas-ventas",
                    image: require('@/assets/icons/navbar/generic-icon.svg'),
                    access: true,
                    children: [],
                    active: true
                },
                {
                    name: "Zonas",
                    alt: "icono fuerza ventas",
                    path: "/zonas",
                    image: require('@/assets/icons/navbar/generic-icon.svg'),
                    access: true,
                    children: [],
                    active: true
                },
                {
                    name: "Volver",
                    alt: "Atras",
                    path: "/",
                    image: require('@/assets/icons/navbar/arrow-back.svg'),
                    access: true,
                    children: [],
                    active: true
                },
            
            ]
        },
        
        {
            name: "Técnicos",
            alt: "icono curso",
            path: "/",
            image: require('@/assets/icons/navbar/generic-icon.svg'),
            access: true,
            active: true,
            children: [
                {
                    name: "Técnicos por grupo",
                    alt: "icono listado de técnicos",
                    path: "/lista-tecnicos",
                    image: require('@/assets/icons/navbar/generic-icon.svg'),
                    access: true,
                    children: [],
                    active: true
                },
                {
                    name: "Grupos de técnicos",
                    alt: "icono grupos de técnicos",
                    path: "/grupos-tecnicos",
                    image: require('@/assets/icons/navbar/generic-icon.svg'),
                    access: true,
                    children: [],
                    active: true
                },
                {
                    name: "Volver",
                    alt: "Atras",
                    path: "/",
                    image: require('@/assets/icons/navbar/arrow-back.svg'),
                    access: true,
                    children: [],
                    active: true
                },
            ]
        },
    ]
}

