<template>
    <GenericTableFormPageTemplate
        :get-all-elements="getSFGroupingsWS"
        :headers="headers"
        page_title="Agrupaciones"
        @insert-new-element="openModalInsert"
        :custom_item_slots="['item-btn_actions']"
    >
        <!--Template con el botón de las acciones disponibles, en este caso unicamente la de actualizar-->
        <template #item-btn_actions="{item}">
            <v-menu v-if="userHaveAllowedRol('SALES_FORCE_EDIT')">
                <template v-slot:activator="{ props }">
                    <img :src="require('@/assets/icons/more-options.svg')"
                         class="btn button-bg-white icon-actions"
                         alt="Icono puntos"
                         v-bind="props">
                </template>
                <v-list>
                    <v-list-item>
                        <v-list-item-title class="text-center"
                                           @click="openModalUpdate(item)">
                            <span style="cursor: pointer">Editar</span>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>

        <template #queue-filters="{form_data}">
            <v-col cols="12" md="6">
                <v-text-field
                    v-model.trim="form_data.name"
                    placeholder="Nombre"
                    class="input-error text-field-secondary"
                    bg-color="white"
                    color="transparent"
                    hide-details
                ></v-text-field>
                <div style="display: inline-block; width: auto ">
                    <v-checkbox
                        label="Búsqueda exacta"
                        v-model="form_data.exact_search"
                        :true-value="1"
                        :false-value="0"
                        :color="'#AD7BE9'"
                        hide-details
                    >
                    </v-checkbox>
                </div>
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field
                    v-model.trim="form_data.manager"
                    placeholder="Manager"
                    class="input-error text-field-secondary"
                    bg-color="white"
                    color="transparent"
                    hide-details
                ></v-text-field>
            </v-col>
        </template>

        <template v-slot:modal-element-actions="{reloadData}">
            <v-dialog v-model="show_insert_modal" persistent>
                <ManageGroupingModal
                    :open_modal="openCloseModal"
                    :reloadData="reloadData"
                    :action="action"
                    :selected_grouping="selected_grouping"
                ></ManageGroupingModal>
            </v-dialog>
        </template>
    </GenericTableFormPageTemplate>
</template>

<script>
import {ref} from "vue";
import {getSFGroupingsWS} from "@/utils/sf_groupings";
import Vue3EasyDataTable from "vue3-easy-data-table";
import 'vue3-easy-data-table/dist/style.css';
import GenericTableFormPageTemplate from "@/components/Generic/GenericTableFormPageTemplate.vue";
import ManageGroupingModal from "@/components/SFGroupings/ManageGroupingModal.vue";
import ManageTargetGroupsModal from "@/components/TargetGroups/ManageTargetGroupsModal.vue";
import {userHaveAllowedRol} from "@/utils/auth";

export default {
    name: "SFGroupingsView",
    methods: {userHaveAllowedRol, getSFGroupingsWS},
    components: {ManageTargetGroupsModal, ManageGroupingModal, GenericTableFormPageTemplate, Vue3EasyDataTable},
    setup() {
        let headers = ref([
            {text: 'ID', value: 'id_agrupacion', sortable: true},
            {text: 'NOMBRE', value: 'nombre', sortable: true},
            {text: 'MANAGER', value: 'manager', sortable: true},
            {text: 'ACCIONES', value: 'btn_actions'},
        ])
        let show_insert_modal = ref(false)
        let action = ref("")
        let selected_grouping = ref({})


        /*Abre o cierra el modal*/
        function openCloseModal() {
            show_insert_modal.value = !show_insert_modal.value
        }

        function openModalInsert() {
            action.value = 'insert'
            openCloseModal()
        }

        function openModalUpdate({id_agrupacion, nombre, manager}) {
            action.value = 'update'

            selected_grouping.value = {
                'grouping_id' : id_agrupacion,
                'name' : nombre,
                'manager': manager
            }

            openCloseModal()
        }

        return{
            openCloseModal,
            openModalInsert,
            openModalUpdate,

            action,
            headers,
            selected_grouping,
            show_insert_modal,
        }
    }
}
</script>

<style lang="scss">
</style>