import {createRouter, createWebHistory} from 'vue-router';
import HomeView from "@/views/HomeView";
import PageNotFoundView from "@/views/PageNotFoundView.vue";
import AccessDenyView from "@/views/AccessDenyView.vue";
import ManagerAdminView from "@/views/Admin/ManagerAdminView.vue";
import ManagerRolesView from "@/views/Admin/ManagerRolesView.vue";
import ManagerGroupView from "@/views/Admin/ManagerGroupView.vue";
import ManagerUserRolesView from "@/views/Admin/ManagerUserRolesView.vue";
import ManagerGroupsRolView from "@/views/Admin/ManagerGroupsRolView.vue";
import DocumentsView from "@/views/Documents/DocumentsView.vue";
import SFGroupingsView from "@/views/SFGroupings/SFGroupingsView.vue";
import TargetGroupsView from "@/views/TargetGroups/TargetGroupsView.vue";
import SFTypesView from "@/views/SFTypes/SFTypesView.vue";
import InternalGroupingsView from "@/views/SFInternalGroupings/InternalGroupingsView.vue";
import SalesChannelsView from "@/views/SFSalesChannels/SalesChannelsView.vue";
import SalesForcesView from "@/views/SalesForces/SalesForcesView.vue";
import ZonesList from "@/views/Zones/ZonesList.vue";
import SFDetailsView from "@/views/SalesForces/SFDetailsView.vue";
import TechniciansGroupsView from "@/views/TechniciansGroups/TechniciansGroupsView.vue";
import TechniciansListView from "@/views/TechniciansList/TechniciansListView.vue";
import ManagerGroupUsers from "@/views/Admin/ManagerGroupUsers.vue";
import ManagerGroupUserView from "@/views/Admin/ManagerGroupUserView.vue";


const routes = [
    {
        path: '/',
        name: 'inicio',
        component: HomeView,
        meta: {
            beauty_name: 'Inicio',
        },
    },
    {
        name: 'documentacion',
        path: '/documentacion',
        component: DocumentsView,
        meta: {
            beauty_name: 'Manuales de referencia',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'inicio'
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
            
        }
    },
    {
        name: "admin",
        path: "/admin",
        component: ManagerAdminView,
        meta: {
            beauty_name: 'Panel de administración',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'inicio'
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: "roles",
        path: "/admin-roles",
        component: ManagerRolesView,
        meta: {
            beauty_name: 'Gestión de roles',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'inicio'
                    },
                    {
                        title: 'Panel de administración',
                        disabled: false,
                        to: 'admin'
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: "grupos",
        path: "/admin-grupos",
        component: ManagerGroupView,
        meta: {
            beauty_name: 'Gestión de roles por grupo',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'inicio'
                    },
                    {
                        title: 'Panel de administración',
                        disabled: false,
                        to: 'admin'
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: "roles-usuario",
        path: "/admin-roles-usuario",
        component: ManagerUserRolesView,
        meta: {
            beauty_name: 'Visualización de usuarios por rol',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'inicio'
                    },
                    {
                        title: 'Panel de administración',
                        disabled: false,
                        to: 'admin'
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: "grupos-roles",
        path: "/grupos-por-rol",
        component: ManagerGroupsRolView,
        meta: {
            beauty_name: 'Grupos por roles',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'inicio'
                    },
                    {
                        title: 'Panel de administración',
                        disabled: false,
                        to: 'admin'
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    
    {
        name: "agrupaciones-ffvv",
        path: "/agrupaciones-ffvv",
        component: SFGroupingsView,
        meta: {
            beauty_name: 'Agrupaciones',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'inicio'
                    },
                    {
                        title: 'Fuerzas de ventas',
                        disabled: false,
                        to: 'inicio',
                        children: true
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    
    {
        name: "grupos-objetivos-ffvv",
        path: "/grupos-objetivos-ffvv",
        component: TargetGroupsView,
        meta: {
            beauty_name: 'Grupos de objetivos',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'inicio'
                    },
                    {
                        title: 'Fuerzas de ventas',
                        disabled: false,
                        to: 'inicio',
                        children: true
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: "tipos-fuerzas-ventas",
        path: "/tipos-fuerzas-ventas",
        component: SFTypesView,
        meta: {
            beauty_name: 'Tipos',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'inicio'
                    },
                    {
                        title: 'Fuerzas de ventas',
                        disabled: false,
                        to: 'inicio',
                        children: true
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    
    {
        name: "agrupaciones-internas",
        path: "/agrupaciones-internas",
        component: InternalGroupingsView,
        meta: {
            beauty_name: 'Agrupaciones internas',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'inicio'
                    },
                    {
                        title: 'Fuerzas de ventas',
                        disabled: false,
                        to: 'inicio',
                        children: true
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    
    {
        name: "canales-ventas",
        path: "/canales-venta",
        component: SalesChannelsView,
        meta: {
            beauty_name: 'Canales de venta',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'inicio'
                    },
                    {
                        title: 'Fuerzas de ventas',
                        disabled: false,
                        to: 'inicio',
                        children: true
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    
    {
        name: "fuerzas-ventas",
        path: "/fuerzas-ventas",
        component: SalesForcesView,
        meta: {
            beauty_name: 'Fuerzas de ventas',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'inicio'
                    },
                    {
                        title: 'Fuerzas de ventas',
                        disabled: false,
                        to: 'inicio',
                        children: true
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: "zonas",
        path: "/zonas",
        component: ZonesList,
        meta: {
            beauty_name: 'Zonas',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'inicio'
                    },
                    {
                        title: 'Fuerzas de ventas',
                        disabled: false,
                        to: 'inicio',
                        children: true
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: 'detalles-fuerza-venta',
        path: '/detalles-ffvv/:sales_force_id',
        component: SFDetailsView,
        props: (route) => {
            let sales_force_id = parseInt(route.params.sales_force_id)
            let result = true
            
            return result ? {sales_force_id} : {sales_force_id: ''};
        },
        meta: {
            beauty_name: 'Detalles fuerza de ventas',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'inicio'
                    },
                    {
                        title: 'Fuerzas de ventas',
                        disabled: false,
                        to: 'inicio',
                        children: true
                    },
                    {
                        title: 'Fuerzas de ventas',
                        disabled: false,
                        to: 'fuerzas-ventas',
                        children: true
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: "grupos-tecnicos",
        path: "/grupos-tecnicos",
        component: TechniciansGroupsView,
        meta: {
            beauty_name: 'Grupos de técnicos',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'inicio'
                    },
                    {
                        title: 'Técnicos',
                        disabled: false,
                        to: 'inicio',
                        children: true
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    
    {
        name: "lista-tecnicos",
        path: "/lista-tecnicos",
        component: TechniciansListView,
        meta: {
            beauty_name: 'Técnicos por grupo',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'inicio'
                    },
                    {
                        title: 'Técnicos',
                        disabled: false,
                        to: 'inicio',
                        children: true
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: "usuarios-grupo",
        path: "/usuarios-grupo",
        component: ManagerGroupUsers,
        meta: {
            beauty_name: 'Usuarios por grupo',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'inicio'
                    },
                    {
                        title: 'Panel de administración',
                        disabled: false,
                        to: 'admin'
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: "grupos-usuario",
        path: "/grupos-usuario",
        component: ManagerGroupUserView,
        meta: {
            beauty_name: 'Grupos por usuario',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'inicio'
                    },
                    {
                        title: 'Panel de administración',
                        disabled: false,
                        to: 'admin'
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    
    
    /* PÁGINA 404 */
    {
        path: "/:pathMatch(.*)*",
        name: "not-found",
        component: PageNotFoundView,
        meta: {
            beauty_name: 'Página no encontrada',
        }
    },
    {
        path: "/acceso-denegado",
        name: "not-allowed",
        component: AccessDenyView,
        meta: {
            beauty_name: 'Acceso denegado',
        }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

export default router
