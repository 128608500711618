<template>
    <v-sheet class="d-flex flex-wrap bg-surface-variant">
    <v-sheet class="w-50 h-screen edit-sheet">
        <v-col class="box-action">
            <div class="position-login">
                <v-row>
                    <v-col cols="12" sm="6">
                        <img src="@/assets/logos/logo-main-color.svg" v-default-alt class="d-inline-block logo-main"
                             v-default-title>
                    </v-col>
                </v-row>
                <form class="form" @submit.prevent="onLogin">

                    <v-row>
                        <v-col class="pb-0">
                            <label class="titles-login" for="floatingInput label-class">Usuario</label>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="pb-8 pt-2">
                            <input
                                autocomplete="off" name="login" class="edit-form form-control rounded-0 border-0 bg-transparent border-bottom"
                                type="text"  v-model="form_data.username">
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="pb-0 pt-3">
                            <label class="titles-login" for="floatingPassword label-class">Contraseña</label>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="pt-2 input-password">
                            <input
                                class="edit-form form-control rounded-0 border-0 bg-transparent border-bottom"
                                type="password" v-model="form_data.password" id="input-password">
                          <v-icon v-if="show_password"  @click="reveal_password()" class="rounded-0 border-0 bg-transparent ">mdi-eye</v-icon>
                          <v-icon v-else @click="reveal_password()" class="rounded-0 border-0 bg-transparent ">mdi-eye-off</v-icon>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="box-loading">
                            <img v-if="loading"  class="loading-gif-xs" :src="require('@/assets/gifs/loading.gif')"
                                 alt="Loading Gif">
                            <span v-if="error_msg"
                                  class="input-error msg-error">Credenciales inválidas. Inténtelo de nuevo</span>

                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn class="btn button-primary w-100"
                                   type="submit">Aceptar
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="text-right mt-2">
                            <v-icon class="icon-alert">mdi-alert</v-icon>
                            <span class="text-alert">Es necesario tener la <b>VPN activada</b></span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>

                        </v-col>
                    </v-row>
                </form>
            </div>
        </v-col>
    </v-sheet>
    <v-sheet class="w-50 h-screen bg-linear-gradient">
<!--        <div class="box-powerby">
            <v-row>
                <v-col cols="12" class="p-0">
                    <span class="txt-powered-by">Powered by</span>
                </v-col>
                <v-col cols="12" class="p-1">
                    <img src="@/assets/logos/logo-ai-project-white.svg" v-default-alt
                         class="mx-auto logo-project">
                </v-col>
            </v-row>
        </div>-->
    </v-sheet>
    </v-sheet>
</template>

<script>
import * as Yup from "yup"
import {ref} from "vue";
import {authenticate, logout, userHaveAllowedRol} from "@/utils/auth";
import store from "@/store";
import {showNotification} from "@/utils/aux_functions";
import {useRoute} from "vue-router";
import {getAllRolesDataWS, getUserRoles} from "@/utils/admin_functions";


export default {
    name: "Login",

    setup() {
        const route = useRoute();
        let form_data = {}
        let form_error = ref({});
        let error_msg = ref(false);
        let loading = ref(false);

        let show_password =  ref(false);

        const schemaForm = Yup.object().shape({
            username: Yup.string().required(true),
            password: Yup.string().required(true),
        })

        const onLogin = async () => {
            loading.value = true
            error_msg.value = false;
            form_error.value = {};

            try {
                await schemaForm.validate(form_data, {abortEarly: false})
                try {
                    const username = form_data.username;
                    const password = form_data.password;
                    let authenticated_response = await authenticate(username, password)

                    // let groups = [];
                    if (authenticated_response['result'] && authenticated_response['data']['access_token'] && authenticated_response['data']['groups']) {
                        let token = authenticated_response['data']['access_token'];
                        // authenticated_response['data']['groups'].forEach(group => {
                        //     groups.push(group.gidNumber)
                        // })
                        // let groups = authenticated_response.data.groups || [];
                        let groups = authenticated_response.data.groups.map(group => group.gidNumber);


                        localStorage.setItem('saved', new Date().getTime())
                        localStorage.setItem('username', btoa(username))
                        localStorage.setItem('group', btoa(groups.toString()))
                        localStorage.setItem('token', token)

                        store.commit('setAicoreToken', token)
                        store.commit('setGroup', groups.toString())
                        store.commit('setUser', username);

                        let roles = await getAllRolesDataWS();
                        localStorage.setItem('roles', JSON.stringify(roles));
                        store.commit('setRoles', roles);
                        await getUserRoles();

                    } else {
                        error_msg.value = true
                    }


                } catch
                    (error) {
                    showNotification('error', error)
                }
            } catch (error) {
              if(error.inner){
                error.inner.forEach((error) => {
                    form_error.value[error.path] = error.message;
                })
              }
            }
            loading.value = false
        }

        function reveal_password(){
          show_password.value = !show_password.value;
          let input_password = document.getElementById("input-password");
          if(!!show_password.value){
            input_password.type = "text";
          }else{
            input_password.type = "password";
          }
        }

        return {
            form_data,
            form_error,
            error_msg,
            loading,
            onLogin,
            reveal_password,
            show_password
        }
    }
    ,
}

</script>
<style lang="scss">
@import "@/styles/components/login.scss";
</style>
