<template>
    <div id="sidebar-nav" class="list-group rounded-0 personalize-scroll-sidebar" v-if="$route.name !=='inicio'">
        <template v-for="route in route_system">
            <template v-if="route.children.length === 0">
                <router-link v-if="route.active && route.access" :to=route.path class="list-group-item">
                    <img :src="route.image" :alt=route.alt class="icon-sidebar">
                    <span class="nav-link">{{ route.name }}</span>
                </router-link>
            </template>
            <template v-else>
                <div class="link-with-child" v-if="route.active && route.access">
                    <div class="item-parent list-group-item">
                        <img :src="route.image" :alt=route.alt
                             class="icon-sidebar">
                        <span class="nav-link">{{ route.name }} <span class="icon-parent-link"></span></span>
                    </div>
                    <div class="items-children">
                        <template v-for="route_child in route.children">
                            <router-link v-if="route_child.alt !== 'Atras'" :to=route_child.path class="item-child">
                                {{ route_child.name }}
                            </router-link>
                        </template>
                    </div>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import {systemRoute} from "@/router/routes_home";

export default {
    name: "Sidebar",
    setup() {
        let route_system = ref({})

        onBeforeMount(async () => {
            route_system.value = await systemRoute()
        })

        $(document).ready(function () {
            $(".item-parent").on('click', function (e) {
                e.stopImmediatePropagation()
                var $parent = $(this);
                $parent.find('.icon-parent-link').toggleClass('up');
                var $sibling = $parent.next();
                $sibling.toggleClass('show')
            })
        });

        return {
            route_system,
        }
    }
}
</script>

<style lang="scss">
@import "@/styles/components/sidebar.scss";
</style>