<template>
    <GenericTableFormPageTemplate
            :get-all-elements="getSalesForcesWS"
            :headers="headers"
            page_title="Fuerzas de ventas"
            @insert-new-element="openModalInsert"
            :custom_item_slots="['item-btn_actions','item-active','created_at', 'updated_at']"
            :page_loading="loading"
            :use_sales_forces_view="use_sales_forces_view"
            :use_expansion_panel = "use_expansion_panel"
    >
        <!--Template con el botón de las acciones disponibles, en este caso unicamente la de actualizar-->
        <template #item-btn_actions="{item}">
            <v-menu>
                <template v-slot:activator="{ props }">
                    <img :src="require('@/assets/icons/more-options.svg')"
                         class="btn button-bg-white icon-actions"
                         alt="Icono puntos"
                         v-bind="props">
                </template>
                <v-list>
                    <v-list-item v-if="userHaveAllowedRol('SALES_FORCE_EDIT')">
                        <v-list-item-title class="text-center"
                                           @click="openModalUpdate(item)">
                            <span style="cursor: pointer">Editar</span>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-title class="text-center"
                                           @click="openSalesForceDetails(item.id)">
                            <span style="cursor: pointer">Ver en detalle</span>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>

        <template #item-active="{item}">
            <span>{{(item.active === 1) ? 'Activo' : 'Inactivo'}}</span>
        </template>

        <template #item-created_at="{item}">
            <span>{{formatDate(item.created_at)}}</span>
        </template>

        <template #item-updated_at="{item}">
            <span>{{formatDate(item.updated_at)}}</span>
        </template>

        <template #queue-filters="{form_data}">
            <v-col cols="12" md="6">
                <span class="input-title">Nombre de la FFVV</span>
                <v-text-field
                        v-model.trim="form_data.name"
                        placeholder="Nombre"
                        class="input-error text-field-secondary"
                        bg-color="white"
                        color="transparent"
                        hide-details
                ></v-text-field>
                <div style="display: inline-block; width: auto ">
                    <v-checkbox
                        label="Búsqueda exacta"
                        v-model="form_data.exact_search"
                        :true-value="1"
                        :false-value="0"
                        :color="'#AD7BE9'"
                        hide-details
                    >
                    </v-checkbox>
                </div>
            </v-col>
            <v-col cols="12" md="6">
                <span class="input-title">Estado</span>
                <v-select
                        class="input-error select-secondary"
                        placeholder="Seleccione un estado"
                        item-title="state"
                        item-value="value"
                        v-model="form_data.active"
                        :items="available_states"
                        variant="outlined"
                        hide-details
                        no-data-text="No hay datos disponibles"
                >
                </v-select>
            </v-col>
            <v-col cols="12" md="6">
                <span class="input-title">Agrupación</span>
                <v-select
                    class="input-error select-secondary"
                    placeholder="Seleccione un estado"
                    item-title="nombre"
                    item-value="id_agrupacion"
                    v-model="form_data.grouping"
                    :items="sf_selects_data.sf_grouping"
                    variant="outlined"
                    hide-details
                    no-data-text="No hay datos disponibles"
                >
                </v-select>
            </v-col>
            <v-col cols="12" md="6">
                <span class="input-title">Grupo objetivo</span>
                <v-select
                    class="input-error select-secondary"
                    placeholder="Seleccione un grupo objetivo"
                    item-title="nombre"
                    item-value="id_grupo_objetivos"
                    v-model="form_data.target_group"
                    :items="sf_selects_data.sf_target_groups"
                    variant="outlined"
                    hide-details
                    no-data-text="No hay datos disponibles"
                >
                </v-select>
            </v-col>
            <v-col cols="12" md="6">
                <span class="input-title">Tipo</span>
                <v-select
                    class="input-error select-secondary"
                    placeholder="Seleccione un tipo"
                    item-title="name"
                    item-value="type_id"
                    v-model="form_data.type"
                    :items="sf_selects_data.sf_types"
                    variant="outlined"
                    hide-details
                    no-data-text="No hay datos disponibles"
                >
                </v-select>
            </v-col>
            <v-col cols="12" md="6">
                <span class="input-title">Agrupación interna</span>
                <v-select
                    class="input-error select-secondary"
                    placeholder="Seleccione una agrupación interna"
                    item-title="name"
                    item-value="internal_grouping_id"
                    v-model="form_data.internal_grouping"
                    :items="sf_selects_data.sf_internal_groupings"
                    variant="outlined"
                    hide-details
                    no-data-text="No hay datos disponibles"
                >
                </v-select>
            </v-col>
            <v-col cols="12" md="6">
                <span class="input-title">Canal de ventas</span>
                <v-select
                    class="input-error select-secondary"
                    placeholder="Seleccione un canal de ventas"
                    item-title="name"
                    item-value="channel_id"
                    v-model="form_data.sales_channel"
                    :items="sf_selects_data.sf_sales_channel"
                    variant="outlined"
                    hide-details
                    no-data-text="No hay datos disponibles"
                >
                </v-select>
            </v-col>
            <v-col cols="12" md="6">
                <span class="input-title">Zona</span>
                <v-select
                    class="input-error select-secondary"
                    placeholder="Seleccione una zona"
                    item-title="nombre"
                    item-value="id"
                    v-model="form_data.zone"
                    :items="sf_selects_data.sf_zones"
                    variant="outlined"
                    hide-details
                    no-data-text="No hay datos disponibles"
                >
                </v-select>
            </v-col>
        </template>

        <template v-slot:modal-element-actions="{reloadData}">
            <v-dialog v-model="show_insert_modal" persistent>
                <ManageSalesForcesModal
                        :open_modal="openCloseModal"
                        :reloadData="reloadData"
                        :action="action"
                        :selected_sales_force="selected_sales_force"
                        :selects_data="sf_selects_data"
                ></ManageSalesForcesModal>
            </v-dialog>
        </template>
    </GenericTableFormPageTemplate>
</template>

<script>
import ManageSalesChannelsModal from "@/components/SFSalesChannels/ManageSalesChannelsModal.vue";
import GenericTableFormPageTemplate from "@/components/Generic/GenericTableFormPageTemplate.vue";
import {getSalesChannelsWS} from "@/utils/sales_channels";
import {formatDate, manageWSResponses} from "@/utils/aux_functions";
import {onBeforeMount, ref} from "vue";
import {getSalesForcesWS} from "@/utils/sales_forces";
import ManageSalesForcesModal from "@/components/SalesForces/ManageSalesForcesModal.vue";
import {useRouter} from "vue-router";
import {getTargetGroupsWS} from "@/utils/target_groups";
import {getSFTypesWS} from "@/utils/sf_types";
import {getZonesPaginate} from "@/utils/zones";
import {getSFInternalGroupingsWS} from "@/utils/sf_internal_groupings";
import {getSFGroupingsWS} from "@/utils/sf_groupings";
import {userHaveAllowedRol} from "@/utils/auth";

export default {
    name: "SalesForcesView",
    components: {ManageSalesForcesModal, GenericTableFormPageTemplate, ManageSalesChannelsModal},
    methods: {userHaveAllowedRol, getSalesForcesWS, formatDate},
    setup() {
        const router = useRouter()
        let loading = ref(false)

        let use_sales_forces_view = ref(true);

        let use_expansion_panel = ref(true);


        let headers = ref([
            {text: 'ID', value: 'id', sortable: true},
            {text: 'NOMBRE', value: 'name', sortable: true},
            {text: 'ESTADO', value: 'active', sortable: true},
            {text: 'AGRUPACIÓN', value: 'name_agrup', sortable: true},
            {text: 'GRUPO OBJETIVOS', value: 'name_group_objectives', sortable: true},
            {text: 'TIPO', value: 'name_type', sortable: true},
            {text: 'AGRUPACIÓN INTERNA', value: 'name_agrup_internal', sortable: true},
            {text: 'CANAL DE VENTA', value: 'name_sales_channel', sortable: true},
            {text: 'ZONA', value: 'name_zone', sortable: true},
            {text: 'CREADO', value: 'created_at', sortable: true},
            {text: 'ACTUALIZADO', value: 'updated_at', sortable: true},
            {text: 'CREADO POR', value: 'created_by', sortable: true},
            {text: 'ACTUALIZADO POR', value: 'updated_by', sortable: true},
            {text: 'ACCIONES', value: 'btn_actions'},
        ])
        let show_insert_modal = ref(false)

        let action = ref("")
        let selected_sales_force = ref({})
        let available_states = ref([
            {state: 'Activo', value: 1},
            {state: 'Inactivo', value: 0},
            {state: 'Ambos', value: null},
        ])

        let sf_selects_data = ref({})

        onBeforeMount(async () => {
            loading.value = true
            await router.isReady()
            await loadSelectData()
            loading.value = false
        })

        /**
         *  Rellena los siguientes selectores:
         *      Agrupaciones
         *      Grupos de objetivos
         *      Tipo de ffvv
         *      Agrupación interna
         *      Canal de ventas
         *      Grupos de técnicos
         *
         */
        async function loadSelectData() {
            let response = await manageWSResponses(getSFGroupingsWS,[null, null, {use_pagination: false}])
            if (response){
                const default_option = { id_agrupacion: null, nombre: '-- Sin valor --' };
                sf_selects_data.value['sf_grouping'] = [default_option,...response['data']]
            }

            response = await manageWSResponses(getTargetGroupsWS,[null, null, {use_pagination: false}])
            if (response){
                const default_option = { id_grupo_objetivos: null, nombre: '-- Sin valor --' };
                sf_selects_data.value['sf_target_groups'] = [default_option,...response['data']]
            }

            response = await manageWSResponses(getSFTypesWS,[null, null, {use_pagination: false}])
            if (response){
                const default_option = { type_id: null, name: '-- Sin valor --' };
                sf_selects_data.value['sf_types'] = [default_option,...response['data']]
            }

            response = await manageWSResponses(getZonesPaginate,[null, null, {use_pagination: false}])
            if (response){
                const default_option = { id: null, nombre: '-- Sin valor --' };
                sf_selects_data.value['sf_zones'] = [default_option,...response['data']]
            }

            response = await manageWSResponses(getSFInternalGroupingsWS,[null, null, {use_pagination: false}])
            if (response){
                const default_option = { internal_grouping_id: null, name: '-- Sin valor --' };
                sf_selects_data.value['sf_internal_groupings'] = [default_option,...response['data']]
            }

            response = await manageWSResponses(getSalesChannelsWS,[null, null, {use_pagination: false}])
            if (response){
                const default_option = { channel_id: null, name: '-- Sin valor --' };
                sf_selects_data.value['sf_sales_channel'] = [default_option,...response['data']]
            }
        }

        /*Abre o cierra el modal*/
        function openCloseModal() {
            show_insert_modal.value = !show_insert_modal.value
        }

        function openModalInsert() {
            action.value = 'insert'
            openCloseModal()
        }

        function openModalUpdate({id}) {
            action.value = 'update'

            selected_sales_force.value = {
                'sales_force_id': parseInt(id),
            }

            openCloseModal()
        }

        /*
        * Redirecciona a la pantalla de detalles de la fuerza de venta
        * */
        async function openSalesForceDetails(id) {
          await router.push({path: `/detalles-ffvv/${id}`})
        }



        return{
            openCloseModal,
            openModalInsert,
            openModalUpdate,
            openSalesForceDetails,

            action,
            available_states,
            headers,
            use_sales_forces_view,
            use_expansion_panel,
            loading,
            selected_sales_force,
            sf_selects_data,
            show_insert_modal,
        }
    }
}
</script>

<style scoped>

</style>