<template>
    <GenericTableFormPageTemplate
            :get-all-elements="getZonesPaginate"
            :headers="headers"
            page_title="Zonas"
            @insert-new-element="openModalInsert"
            :custom_item_slots="['item-btn_actions']"
    >
        <!--Template con el botón de las acciones disponibles, en este caso unicamente la de actualizar-->
        <template #item-btn_actions="{item}">
            <v-menu v-if="userHaveAllowedRol('SALES_FORCE_EDIT')">
                <template v-slot:activator="{ props }">
                    <img :src="require('@/assets/icons/more-options.svg')"
                         class="btn button-bg-white icon-actions"
                         alt="Icono puntos"
                         v-bind="props">
                </template>
                <v-list>
                    <v-list-item>
                        <v-list-item-title class="text-center"
                                           @click="openModalUpdate(item)">
                            <span style="cursor: pointer">Editar</span>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>

        <!--Template con los filtros disponibles, en este caso unicamente el de nombre, con un check de búsqueda exacta-->
        <template #queue-filters="{form_data}">
            <v-col cols="12" md="6">
                <v-text-field
                        v-model.trim="form_data.name"
                        placeholder="Nombre"
                        class="input-error text-field-secondary"
                        bg-color="white"
                        color="transparent"
                        hide-details
                ></v-text-field>

                <div style="display: inline-block; width: auto ">
                    <v-checkbox
                        label="Búsqueda exacta"
                        v-model="form_data.exact_search"
                        :true-value="1"
                        :false-value="0"
                        :color="'#AD7BE9'"
                        hide-details
                    >
                    </v-checkbox>
                </div>
            </v-col>
        </template>

        <template v-slot:modal-element-actions="{reloadData}">
            <v-dialog v-model="show_insert_modal" persistent>
                <ZonesGroupModal
                    :open_modal="openCloseModal"
                    :reloadData="reloadData"
                    :action="action"
                    :selected_zone="selected_zone"
                ></ZonesGroupModal>
            </v-dialog>

        </template>

    </GenericTableFormPageTemplate>
</template>

<script>
import GenericTableFormPageTemplate from "@/components/Generic/GenericTableFormPageTemplate.vue";
import Vue3EasyDataTable from "vue3-easy-data-table";
import {ref} from "vue";
import {getTargetGroupsWS} from "@/utils/target_groups";
import {getTargetGroupsDetails} from "@/utils/get_target_group_details";
import {getZonesPaginate, getZonesFormated} from "@/utils/zones";
import ManageTargetGroupsModal from "@/components/TargetGroups/ManageTargetGroupsModal.vue";
import DetailsTargetGroupModal from "@/components/Generic/DetailsTargetGroupModal.vue";
import ZonesGroupModal from "@/components/ZonesGroup/ZonesGroupModal.vue";
import {userHaveAllowedRol} from "@/utils/auth";

export default {
    name: "TargetGroupsView",
    methods: {userHaveAllowedRol, getTargetGroupsWS, getTargetGroupsDetails, getZonesPaginate, getZonesFormated},
    components: {DetailsTargetGroupModal, ManageTargetGroupsModal, ZonesGroupModal, GenericTableFormPageTemplate, Vue3EasyDataTable},
    setup() {
        let headers = ref([
            {text: 'ID', value: 'id', sortable: true},
            {text: 'NOMBRE', value: 'nombre', sortable: true},
            {text: 'ACCIONES', value: 'btn_actions'}
        ])
        let show_insert_modal = ref(false)
        let action = ref("")
        let selected_zone = ref({});

        let show_details_modal = ref(false);
        let selected_group_id = ref({});


        function openCloseModal() {
            show_insert_modal.value = !show_insert_modal.value
        }

        function openCloseDetailsModal() {
            show_details_modal.value = !show_details_modal.value
        }

        /*Abre el modal para insertar o actualizar un grupo objetivo*/
        function openModalInsert() {
            action.value = 'insert'
            openCloseModal()
        }
        function openModalUpdate(selected_group_data) {
            action.value = 'update'
            selected_zone.value['id'] = selected_group_data.id
            selected_zone.value['name'] = selected_group_data.nombre
            openCloseModal()
        }

        return{
            openCloseModal,
            openCloseDetailsModal,
            openModalUpdate,
            openModalInsert,
            show_details_modal,
            selected_group_id,

            action,
            headers,
            show_insert_modal,
            selected_zone
        }
    }
}
</script>

<style scoped lang="scss">

//tr td:nth-child(1) {
//    width: 10% !important;
//}
//tr td:nth-child(2) {
//    width: 75% !important;
//}


</style>