<template>
    <GenericTableFormPageTemplate
            :get-all-elements="getTargetGroupsWS"
            :headers="headers"
            page_title="Grupos de objetivos"
            @insert-new-element="openModalInsert"
            :custom_item_slots="['item-btn_actions']"
    >
        <!--Template con el botón de las acciones disponibles, en este caso unicamente la de actualizar-->
        <template #item-btn_actions="{item}">
            <v-menu>
                <template v-slot:activator="{ props }">
                    <img :src="require('@/assets/icons/more-options.svg')"
                         class="btn button-bg-white icon-actions"
                         alt="Icono puntos"
                         v-bind="props">
                </template>
                <v-list>
                    <v-list-item v-if="userHaveAllowedRol('SALES_FORCE_EDIT')">
                        <v-list-item-title class="text-center"
                                           @click="openModalUpdate(item)">
                            <span style="cursor: pointer">Editar</span>
                        </v-list-item-title>
                    </v-list-item>
                  <v-list-item>
                        <v-list-item-title class="text-center"
                                           @click="openDetailsModal(item)">
                            <span style="cursor: pointer">Detalle</span>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>

        <!--Template con los filtros disponibles, en este caso unicamente el de nombre, con un check de búsqueda exacta-->
        <template #queue-filters="{form_data}">
            <v-col cols="12" md="6">
                <v-text-field
                        v-model.trim="form_data.name"
                        placeholder="Nombre"
                        class="input-error text-field-secondary"
                        bg-color="white"
                        color="transparent"
                        hide-details
                ></v-text-field>

                <div style="display: inline-block; width: auto ">
                    <v-checkbox
                        label="Búsqueda exacta"
                        v-model="form_data.exact_search"
                        :true-value="1"
                        :false-value="0"
                        :color="'#AD7BE9'"
                        hide-details
                    >
                    </v-checkbox>
                </div>
            </v-col>
        </template>

        <template v-slot:modal-element-actions="{reloadData}">
            <v-dialog v-model="show_insert_modal" persistent>
                <ManageTargetGroupsModal
                    :open_modal="openCloseModal"
                    :reloadData="reloadData"
                    :action="action"
                    :selected_target_group="selected_target_group"
                ></ManageTargetGroupsModal>
            </v-dialog>


            <v-dialog v-model="show_details_modal" persistent>
                <DetailsTargetGroupModal
                    :open_modal="openCloseDetailsModal"
                    :action="action"
                    :selected_group_id="selected_group_id"
                ></DetailsTargetGroupModal>
            </v-dialog>
        </template>

    </GenericTableFormPageTemplate>
</template>

<script>
import ManageGroupingModal from "@/components/SFGroupings/ManageGroupingModal.vue";
import GenericTableFormPageTemplate from "@/components/Generic/GenericTableFormPageTemplate.vue";
import Vue3EasyDataTable from "vue3-easy-data-table";
import {ref} from "vue";
import {getTargetGroupsWS} from "@/utils/target_groups";
import {getTargetGroupsDetails} from "@/utils/get_target_group_details";
import ManageTargetGroupsModal from "@/components/TargetGroups/ManageTargetGroupsModal.vue";
import DetailsTargetGroupModal from "@/components/Generic/DetailsTargetGroupModal.vue";
import {userHaveAllowedRol} from "@/utils/auth";


export default {
    name: "TargetGroupsView",
    methods: {userHaveAllowedRol, getTargetGroupsWS, getTargetGroupsDetails},
    components: {DetailsTargetGroupModal, ManageTargetGroupsModal, GenericTableFormPageTemplate, Vue3EasyDataTable},
    setup() {
        let headers = ref([
            {text: 'ID', value: 'id_grupo_objetivos', sortable: true},
            {text: 'NOMBRE', value: 'nombre', sortable: true},
            {text: 'ACCIONES', value: 'btn_actions'},
        ])
        let show_insert_modal = ref(false)
        let action = ref("")
        let selected_target_group = ref({});

        let show_details_modal = ref(false);
        let selected_group_id = ref({});


        function openCloseModal() {
            show_insert_modal.value = !show_insert_modal.value
        }

        function openCloseDetailsModal() {
            show_details_modal.value = !show_details_modal.value
        }

        /*Abre el modal para insertar o actualizar un grupo objetivo*/
        function openModalInsert() {
            action.value = 'insert'
            openCloseModal()
        }
        function openModalUpdate(selected_group_data) {
            action.value = 'update'
            selected_target_group.value['target_id'] = selected_group_data.id_grupo_objetivos
            selected_target_group.value['name'] = selected_group_data.nombre
            openCloseModal()
        }

        function openDetailsModal(item){
            action.value = 'detail';
            selected_group_id.value = {
              "id_group": item.id_grupo_objetivos,
              "nombre" : item.nombre
            }
            openCloseDetailsModal();
        }


        return{
            openCloseModal,
            openCloseDetailsModal,
            openModalUpdate,
            openModalInsert,
            openDetailsModal,
            show_details_modal,
            selected_group_id,

            action,
            headers,
            show_insert_modal,
            selected_target_group,
        }
    }
}
</script>

<style lang="scss">
</style>