import {showNotification} from "@/utils/aux_functions";
import store from "@/store";
import {getUserRolesWS} from "@/utils/auth";
import {escicApiBackendPetition} from "@/utils/escic_api_petition";

export async function getAllGroupsLdapWS(filter_name = null) {
    let params_token = {
        'logged_user': store.getters.getUsername,
    }
    let params = {
        'filter_name': filter_name,
    }
    try {
        return await escicApiBackendPetition('POST', "groupsLDAP/getAllGroupsLDAP", params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'groupsLDAP/getAllGroupsLDAP');
    }
}

export async function getAllRolesGroupLdapWS(id_group) {
    let params = {
        'id_group': id_group,
    }

    let params_token = {
        'logged_user': store.getters.getUsername,
    }

    try {
        return await escicApiBackendPetition('POST', 'technical/getAllRolesGroupLdap', params, params_token);
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'technical/getAllRolesGroupLdap');
    }
}

export async function getAllGroupsByRolWS(id_rol) {
    let params = {
        'id_rol': id_rol,
    }
    let params_token = {
        'logged_user': store.getters.getUsername,
    }
    try {
        return await escicApiBackendPetition('POST', 'technical/getAllGroupsLdapRole', params, params_token);
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' technical/getAllGroupsLdapRole');
    }
}

export async function getAllTechnicalsRolWS(rol_id, filter_name=null) {
    let params = {
        'id_rol': rol_id,
        'filter_name': filter_name,
    }

    let params_token = {
        'logged_user': store.getters.getUsername,
    }

    try {
        return await escicApiBackendPetition('POST', 'technical/getAllTechnicalsRol', params, params_token);
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' technical/getAllTechnicalsRol');
    }
}

export async function addRolToGroupWS(id_group_ladp, rol_group_data) {

    let params = {
        'id_group': id_group_ladp,
        'roles_group_data': JSON.stringify(rol_group_data),
    }

    let params_token = {
        'logged_user': store.getters.getUsername,
    }

    try {
        return await escicApiBackendPetition('POST', 'technical/assignRolesGroupLdap', params, params_token)

    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'technical/assignRolesGroupLdap');
    }
}

export async function getAllRolesWS(filter_name) {
    let params_token = {
        'logged_user': store.getters.getUsername,
    }
    let params = {
        'filter_name' : filter_name
    }
    try {
        return await escicApiBackendPetition('POST', 'technical/getAllRoles', params, params_token);
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'technical/getAllRoles');
    }
}


export async function insertRolWS(name, alias) {
    let params = {
        'rol_name': name.toUpperCase(),
        'rol_alias': alias.toLowerCase(),
    }
    let params_token = {
        'logged_user': store.getters.getUsername,
    }

    try {
        return await escicApiBackendPetition('POST', 'technical/insertRol', params, params_token);

    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'technical/insertRol');
    }
}

export async function updateRolWS(rol_data) {
    let params = {
        'rol_data': JSON.stringify(rol_data),
    }

    let params_token = {
        'logged_user': store.getters.getUsername,
    }

    try {
        return await escicApiBackendPetition('POST', 'technical/updateRol', params, params_token);
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'technical/updateRol');
    }
}

export async function deleteRolWS(id_rol) {
    let params = {
        'id_rol': id_rol,
    }

    let params_token = {
        'logged_user': store.getters.getUsername,
    }
    try {
        return await escicApiBackendPetition('POST', 'technical/deleteRol', params, params_token);

    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'technical/deleteRol');
    }

}


/*
*
* OBTIENE TODOS LOS USUARIOS
* QUE PERTENECEN A UN GRUPO
* */
export async function getAllTechnicalByGroupWS(group_name) {
    let params = {
        'group_name': group_name,
    }

    let params_token = {
        'logged_user': store.getters.getUsername,
    }

    try {
        return await escicApiBackendPetition('POST', 'technical/getAllTechnicalByGroup', params, params_token);
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' technical/getAllTechnicalByGroup');
    }
}

export async function getUserRoles() {
    let {result, data, errors} = await getUserRolesWS();
    let id_user_roles = [];

    if (result) {
        data.forEach((rol) => {
            id_user_roles.push(rol.id_rol);
        });
    } else {
        showNotification("error", errors)
    }

    localStorage.setItem('user_roles', JSON.stringify(id_user_roles));
    store.commit('setRolesUser', id_user_roles);

}

export async function getAllRolesDataWS() {
    let roles = [];
    let {result, data, errors} = await getAllRolesWS();
    if (result) {
        data.forEach((rol) => {
            roles.push({'alias': rol.alias.toUpperCase(), 'id': rol.id_rol});
        });
    } else {
        showNotification('error', errors)
    }
    return roles;
}

export async function getIDRolWS(rol_name) {
    let id_rol = null;
    let {result, data, errors} = await getAllRolesWS();

    if (result) {
        data.forEach((rol) => {
            if (rol.name.toLowerCase() === rol_name.toLowerCase()) {
                id_rol = rol.id_rol;
            }
        });
    }else{
        showNotification('error', errors)
    }
    return id_rol
}

export function localGetIDRolByAlias(alias) {
    let roles = store.getters.getRoles;
    let id_rol = 0;

    roles.forEach(rol => {
        if (rol.alias.toUpperCase() === alias.toUpperCase()) {
            id_rol = rol.id
        }
    })
    return id_rol
}

export async function getAllGroupsByTechnicalWS(id_user) {
    let params = {
        'user_name': id_user,
    }

    let params_token = {
        'logged_user': store.getters.getUsername
    }

    try {
        return await escicApiBackendPetition('POST', 'technical/getAllGroupsByTechnical', params, params_token);
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' technical/getAllGroupsByTechnical');
    }
}
