<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <span class="main-title-page">Usuarios por grupo</span>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="10" sm="7" md="6" lg="4">
                <v-text-field
                    v-model.trim="name_group"
                    @keyup.enter="getAllTechnical"
                    @update:model-value="captureFieldStatus"
                    class="input-error text-field-secondary"
                    placeholder="Buscar grupo..."
                    append-inner-icon="mdi-magnify"
                    single-line
                    bg-color="white"
                    color="transparent"
                    :error="group_name_error"
                    hide-details
                ></v-text-field>
            </v-col>
            <v-col cols="2" sm="1" class="align-self-center">
                <img :src="require('@/assets/icons/delete-left-solid.svg')" alt="Eliminar campo usuario"
                     class="delete-filter" @click="cleanGroup()">
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="10" sm="7" md="6" lg="4" class="pe-4">
                <span v-if="group_name_error" class="input-error msg-error">
                        El nombre del grupo no puede estar vacío
                    </span>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <span class="list-title">Usuarios</span>
            </v-col>
        </v-row>
        <Loading v-if="loading"></Loading>
        <v-row v-else>
            <v-col cols="12" md="6" lg="4" class="users-col" v-if="show_users">
                <v-list class="personalize-scroll list-users" v-if="users_of_group.length > 0">
                    <v-list-item
                        class="text-list"
                        v-for="user in users_of_group"
                        :key="user.id"
                        :title="user.nombre_usuario"
                    ></v-list-item>
                </v-list>
                <span v-else>No hay usuarios asignados en este grupo</span>
            </v-col>
            <v-col v-else>
                <span style="font-style: italic">Debe introducir un grupo para mostrar los usuarios</span>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {useRoute} from "vue-router";
import {onBeforeMount, ref} from "vue";
import {hasPermission} from "@/utils/auth";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import Vue3EasyDataTable from "vue3-easy-data-table";
import {getAllTechnicalByGroupWS} from "@/utils/admin_functions";
import Loading from "@/components/Generic/Loading.vue";

export default {
    name: "ManagerGroupUsers",
    components: {Loading, Vue3EasyDataTable},

    setup() {
        let route = useRoute();
        let users_of_group = ref([])
        let loading = ref(false);
        let show_users = ref(false);
        let group_name_error = ref(false);
        let name_group = ref(null)
        let field_status = false

        onBeforeMount(async () => {
            await hasPermission(route);
        })


        async function getAllTechnicalByGroup() {
            let {result, data, errors} = await getAllTechnicalByGroupWS(name_group.value)
            if (result) {
                users_of_group.value = data;
                field_status = false
            } else {
                showNotification('error', errors)
            }
        }

        async function getAllTechnical() {
            loading.value = true;

            if (variableIsDefined(name_group.value)) {
                if (field_status) {
                    group_name_error.value = false
                    await getAllTechnicalByGroup()
                    show_users.value = true;
                }
            } else {
                group_name_error.value = true
                show_users.value = false;
            }

            loading.value = false;
        }

        function captureFieldStatus() {
            /*CADA VEZ QUE CAMBIE EL TXT_FIELD SE ACTIVA PARA HACER LA PETICIÓN AL PULSAR ENTER*/
            field_status = true
        }

        let cleanGroup = () => {
            name_group.value = null
            show_users.value = false
            group_name_error.value = false
        }

        return {
            captureFieldStatus,
            getAllTechnical,
            cleanGroup,

            users_of_group,
            group_name_error,
            name_group,
            show_users,
            loading,
        }
    }
}
</script>

<style scoped lang="scss">
</style>