import store from "@/store";
import {showNotification} from "@/utils/aux_functions";
import {escicApiBackendPetition} from "@/utils/escic_api_petition";

/**
 * Obtiene una página con un array de las agrupaciones de ffvv
 *
 * @param {number} page - Número de página al cual acceder
 * @param {number} rows_per_page - Número de elementos que se mostraran por página
 * @param {Object} options - Opciones para la consulta.
 * @param {string} [options.order_by] - El campo por el cual ordenar.
 * @param {string} [options.order_direction] - La dirección de la ordenación ('asc' o 'desc').
 * @param {string} [options.name] - El nombre para buscar.
 * @param {string} [options.manager] - El nombre del manager para buscar.
 * @param {boolean} [options.exact_search] - Indica si la búsqueda debe ser exacta.
 * @param {boolean} [options.use_pagination=true] - Indica si la búsqueda debe ser paginada.
 *
 * @return {Object} Objeto con posiciones bool 'result', array 'data' y array 'errors'. El objeto 'data' contiene array de las agrupaciones de ffvv
 */
export async function getSFGroupingsWS(page, rows_per_page, {order_by, order_direction, name, manager, exact_search, use_pagination = true}){
    const params_token = {
        logged_user: store.getters.getUsername,
    };

    const params = {
        'page_size': rows_per_page,
        'order_by': order_by,
        'order_direction': order_direction,
        'name': name,
        'manager': manager,
        'exact_search': exact_search,
        'use_pagination': use_pagination,

    };

    try {
        return await escicApiBackendPetition('POST', 'salesforceGrouping/getSalesForceGroupings?page='+page, params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'salesforceGrouping/getSalesForceGroupings');
    }
}

/**
 * Inserta una nueva agrupación de fuerzas de ventas
 *
 * @param {string} name - Nombre de la agrupación a crear
 * @param {string} manager - Nombre del manager de la agrupación
 *
 * @return {Object} Objeto con posiciones bool 'result', array 'data' y array 'errors'. El objeto 'data' contiene los datos de la agrupación creada
 */
export async function insertGroupingWS(name, manager){
    const params_token = {
        logged_user: store.getters.getUsername,
    };

    const params = {
        'name': name,
        'manager': manager
    };

    try {
        return await escicApiBackendPetition('POST', 'salesforceGrouping/insertSalesForceGroupings', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'salesforceGrouping/insertSalesForceGroupings');
    }
}

/**
 * Actualiza un grupo de objetivos de fuerzas de ventas
 *
 * @param {number} grouping_id - ID del grupo a actualizar
 * @param {string} [new_name] - Nuevo nombre que va a tener la agrupación
 * @param {string} [new_manager] - Nuevo nombre que va a tener la agrupación
 *
 * @return {Object} Objeto con posiciones bool 'result', array 'data' y array 'errors'. El objeto 'data' contiene un mensaje de éxito
 */
export async function updateGroupingWS(grouping_id, new_name, new_manager){
    const params_token = {
        logged_user: store.getters.getUsername,
    };

    const params = {
        'grouping_id': grouping_id,
        'new_name': new_name,
        'new_manager': new_manager,
    };

    try {
        return await escicApiBackendPetition('POST', 'salesforceGrouping/updateSalesForceGrouping', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'salesforceGrouping/updateSalesForceGrouping');
    }
}