<template>
    <nav class="navbar navbar-expand-lg bg-light p-0 top-menu bg-linear-gradient">
        <v-container
            fluid
            class="container-menu align-items-center d-flex"
        >
            <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#navbar-nav"
                    aria-controls="navbar-nav" aria-expanded="false" aria-label="Toggle navigation"
                    @click="openSidebar()" v-if="$route.name !=='inicio'">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="d-flex justify-content-start ms-4 align-items-center">
                <router-link class="navbar-brand" to="/" @click="backInicio">
                    <img src="@/assets/logos/logo-main-white.svg" v-default-alt width="32" height="26"
                         class="d-inline-block align-text-top"
                         title="Inicio"
                         id="logo-main-menu">
                </router-link>
                <span v-if="$route.name!=='inicio'" class="nav-link" id="route-name">
                        {{ $route.meta.beauty_name }}
                </span>
            </div>

            <div class="offcanvas offcanvas-start" id="navbar-nav" tabindex="-1">
                <div class="offcanvas-header">
                    <button type="button" class="btn-close ms-2" data-bs-dismiss="offcanvas"
                            aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                    <Sidebar/>
                </div>
            </div>
            <div class="d-flex justify-content-end icon-set me-4 ">
                <router-link to="/documentacion">
                    <img src="@/assets/icons/navbar/doc_icon.svg" class="admin-link" alt="icono documentación">
                </router-link>
                <router-link to="/admin" v-if="admin_access">
                    <img src="@/assets/icons/navbar/settings.svg" class="admin-link" alt="icono administrador">
                </router-link>
                <a class="nav-item dropdown nav-link bg-transparent rounded-circle icons-right"
                   id="btn-user"
                   role="button"
                   data-bs-toggle="dropdown" aria-expanded="false">
                    <div id="logo-user">
                        <span id="icon-user">{{ user_initial }}</span>
                    </div>

                    <ul class="dropdown-menu submenu dropdown-menu-end" id=dropdown-login>
                        <li><span class="dropdown-item-text" id="full-name">{{ username }}</span></li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>
                        <li>
                            <router-link class="dropdown-item" id="log-out" to="" @click="logout">Cerrar
                                sesión
                            </router-link>
                        </li>
                    </ul>

                </a>
            </div>
        </v-container>
    </nav>
</template>


<script>
import AuthView from "@/views/Auth/AuthView.vue";
import Sidebar from "@/components/Navigation/Sidebar.vue";
import {computed, onBeforeMount, ref} from "vue";
import {useStore} from "vuex";
import {logout, userHaveAllowedRol} from "@/utils/auth";
import store from "@/store";


export default {
    name: "NavBar",
    components: {
        AuthView,
        Sidebar
    },
    props: {
        renderApp: Function
    },

    setup() {

        const store = useStore();
        let username = computed(() => store.getters.getUsername).value
        let user_initial = (username.substring(0, 1).charAt(0).toUpperCase());
        let admin_access = ref(false);

        onBeforeMount( () => {
            // admin_access.value = userHaveAllowedRol("ROOT");
            admin_access.value = true;
        })

        const openSidebar = () => {
            var element = document.getElementById("sidebar");
            element.classList.add("show");
        }

        const backInicio = () => {
            store.commit('setChildrenMenu', false);
            localStorage.setItem('children_menu', JSON.stringify(false))
        }

        return {
            username,
            user_initial,
            admin_access,
            logout,
            openSidebar,
            backInicio,
        }
    }

}
</script>
<style lang="scss">
@import "@/styles/components/navbar.scss";
</style>

