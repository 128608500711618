import store from "@/store";
import {showNotification} from "@/utils/aux_functions";
import {escicApiBackendPetition} from "@/utils/escic_api_petition";

/**
 * Obtiene un array de los grupos de técnicos
 *
 * @param {Object} options - Opciones para la consulta.
 * @param {string} [options.order_by] - El campo por el cual ordenar.
 * @param {string} [options.order_direction] - La dirección de la ordenación ('asc' o 'desc').
 *
 * @return {Object} Objeto con posiciones bool 'result', array 'data' y array 'errors'. El objeto 'data' contiene array de los grupos de técnicos
 */
export async function getZonesFormated({order_by, order_direction}) {
    const params_token = {
        logged_user: store.getters.getUsername,
    };
    
    const params = {
        'order_by': order_by,
        'order_direction': order_direction,
    };
    
    try {
        return await escicApiBackendPetition('POST', 'ZonesController/getZones', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'ZonesController/getZones');
    }
}

/**
 * Obtiene una página con un array de Grupos de objetivos de ffvv
 *
 * @param {number} page - Número de página al cual acceder
 * @param {number} rows_per_page - Número de elementos que se mostraran por página
 * @param {Object} options - Opciones para la consulta.
 * @param {string} [options.order_by] - El campo por el cual ordenar.
 * @param {string} [options.order_direction] - La dirección de la ordenación ('asc' o 'desc').
 * @param {string} [options.name] - El nombre para buscar.
 * @param {boolean} [options.exact_search] - Indica si la búsqueda debe ser exacta.
 * @param {boolean} [options.use_pagination=true] - Indica si la búsqueda debe ser paginada.
 *
 * @return {Object} Objeto con posiciones bool 'result', array 'data' y array 'errors'. El objeto 'data' contiene array de los Grupos de objetivos de ffvv
 */
export async function getZonesPaginate(page, rows_per_page, {
    order_by,
    order_direction,
    name,
    exact_search,
    use_pagination = true
}) {
    
    const params_token = {
        logged_user: store.getters.getUsername,
    };
    
    const params = {
        'page_size': rows_per_page,
        'order_by': order_by ?? null,
        'order_direction': order_direction ?? null,
        'name': name ?? null,
        'exact_search': exact_search ?? null,
        'use_pagination': use_pagination ?? null,
    };
    
    try {
        return await escicApiBackendPetition('POST', 'ZonesController/getZonesPaginate?page=' + page, params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'ZonesController/getZonesPaginate');
    }
}

/**
 * Inserta una nueva zona
 *
 * @param {string} name - Nombre de la zona a crear
 *
 * @return {Object} Objeto con posiciones bool 'result', array 'data' y array 'errors'. El objeto 'data' contiene los datos de la nueva zona creada
 */
export async function insertZoneWS(name) {
    const params_token = {
        logged_user: store.getters.getUsername,
    };
    
    const params = {
        'name': name,
    };
    
    try {
        return await escicApiBackendPetition('POST', 'ZonesController/createZoneWS', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'ZonesController/createZoneWS');
    }
}

/**
 * Actualiza una nueva zona
 *
 * @param {number} id_zone - ID de la zona a actualizar
 * @param {string} name - Nuevo nombre que va a tener la zona
 *
 * @return {Object} Objeto con posiciones bool 'result', array 'data' y array 'errors'. El objeto 'data' contiene un mensaje de éxito
 */
export async function updateZoneWS(id_zone, name) {
    const params_token = {
        logged_user: store.getters.getUsername,
    };
    
    const params = {
        'id_zone': id_zone,
        'new_name': name,
    };
    
    try {
        return await escicApiBackendPetition('POST', 'ZonesController/updateZoneWS', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'ZonesController/updateZoneWS');
    }
}