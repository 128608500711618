<template>
    <v-container class="container-manager">
        <v-row class="row-height">
            <v-col cols="12" sm="4" class="col-btn-admin">
                <router-link to='/admin-roles-usuario'>
                    <v-btn class="btn button-secondary btn-admin">
                        <v-row class="admin-btns-content">
                            <v-col >
                                <img src="@/assets/icons/administration/dashboard.svg" alt="Icono" class="icon-roles">
                            </v-col>
                            <v-col>
                                <span class="input-title">Visualización de usuarios por rol</span>
                                <span class="description-title">Pantalla que permite ver todos los usuarios asignados a un rol</span>
                            </v-col>
                        </v-row>
                    </v-btn>
                </router-link>
            </v-col>
            <v-col cols="12" sm="4" class="col-btn-admin">
                <router-link to='/admin-grupos'>
                    <v-btn class="btn button-secondary btn-admin">
                        <v-row class="admin-btns-content">
                            <v-col>
                                <img src="@/assets/icons/administration/groups.svg" alt="Icono" class="icon-roles">
                            </v-col>
                            <v-col>
                                <span class="input-title">Gestión de roles por grupo</span>
                                <span class="description-title">Pantalla que permite ver todos los grupos de LDAP y asignarle o quitarles roles</span>

                            </v-col>
                        </v-row>
                    </v-btn>
                </router-link>
            </v-col>
            <v-col cols="12" sm="4" class="col-btn-admin">
                <router-link to='/admin-roles'>
                    <v-btn class="btn button-secondary btn-admin ">
                        <v-row class="admin-btns-content">
                            <v-col>
                                <img src="../../assets/icons/administration/gestion-rol.svg" alt="Icono" class="icon-roles">
                            </v-col>
                            <v-col>
                                <span class="input-title">Gestión de roles</span>
                                <span class="description-title">Gestión completa de roles</span>

                            </v-col>
                        </v-row>
                    </v-btn>
                </router-link>
            </v-col>
            <v-col cols="12" sm="4" class="col-btn-admin">
                <router-link to='/grupos-por-rol'>
                    <v-btn class="btn button-secondary btn-admin ">
                        <v-row class="admin-btns-content">
                            <v-col>
                                <img src="@/assets/icons/administration/roles.svg" alt="Icono" class="icon-roles">
                            </v-col>
                            <v-col>
                                <span class="input-title">Grupos por roles</span>
                                <span class="description-title">Pantalla para ver los grupos asociados a un rol determinado</span>
                            </v-col>
                        </v-row>
                    </v-btn>
                </router-link>
            </v-col>
            <v-col cols="12" sm="4" class="col-btn-admin">
                <router-link to='/usuarios-grupo'>
                    <v-btn class="btn button-secondary btn-admin ">
                        <v-row class="admin-btns-content">
                            <v-col>
                                <img src="@/assets/icons/administration/user-list.svg" alt="Icono" class="icon-roles">
                            </v-col>
                            <v-col>
                                <span class="input-title">Usuarios por grupo</span>
                                <span class="description-title">Pantalla para ver todos los usuarios que pertenecen a un grupo específico</span>
                            </v-col>
                        </v-row>
                    </v-btn>
                </router-link>
            </v-col>
            <v-col cols="12" sm="4" class="col-btn-admin">
                <router-link to='/grupos-usuario'>
                    <v-btn class="btn button-secondary btn-admin ">
                        <v-row class="admin-btns-content">
                            <v-col>
                                <img src="@/assets/icons/administration/group-user.svg" alt="Icono" class="icon-roles">
                            </v-col>
                            <v-col>
                                <span class="input-title">Grupos asignados a un usuario</span>
                                <span class="description-title">Pantalla para ver los grupos a los que pertenece un usuario</span>
                            </v-col>
                        </v-row>
                    </v-btn>
                </router-link>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import {onBeforeMount} from "vue";
import {hasPermission} from "@/utils/auth";
import {useRoute} from "vue-router";
import {escicApiBackendPetition} from "@/utils/escic_api_petition";

export default {
    name: 'ManagerAdminView',

    setup() {
        const route = useRoute();
        onBeforeMount(async () => {
            await hasPermission(route);
        })
    }
};
</script>

<style lang="scss">
@import "@/styles/views/admin/manager-admin.scss";
</style>