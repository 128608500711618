<template>
    <GenericTableFormPageTemplate
            :get-all-elements="getTechniciansGroupsWS"
            :headers="headers"
            page_title="Grupos de técnicos"
            @insert-new-element="openModalInsert"
            :custom_item_slots="['item-btn_actions', 'item-activo', 'item-asignador_eventos', 'item-usuario_matricula', 'item-grupo_padre', 'item-empresa_grupo']"
            :uses_pagination="false"
            :page_loading="loading"
            :use_expansion_panel = "use_expansion_panel"
    >
        <!--Template con el botón de las acciones disponibles-->
        <template #item-btn_actions="{item}">
            <v-menu>
                <template v-slot:activator="{ props }">
                    <img :src="require('@/assets/icons/more-options.svg')"
                         class="btn button-bg-white icon-actions"
                         alt="Icono puntos"
                         v-bind="props">
                </template>
                <v-list>
                    <v-list-item>
                        <v-list-item-title class="text-center"
                                           @click="openModalUpdate(item)">
                            <span style="cursor: pointer">Editar</span>
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-title class="text-center"
                                           @click="openGroupDetails(item)">
                            <span style="cursor: pointer">Integrantes</span>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>

        <template #item-activo="{item}">
            <span>{{item.activo ? 'Activo' : 'Inactivo'}}</span>
        </template>

        <template #item-asignador_eventos="{item}">
            <span>{{item.asignador_eventos ? 'Si' : 'No'}}</span>
        </template>

        <template #item-usuario_matricula="{item}">
            <span>{{item.usuario_matricula ? 'Si' : 'No'}}</span>
        </template>

        <template #item-grupo_padre="{item}">
            <span>{{item.grupo_padre === 0 ? 'Es grupo padre' : available_groups.filter(element => element.id_grupo === item.grupo_padre)[0].nombre}}</span>
        </template>

        <template #item-empresa_grupo="{item}">
            <span>{{variableIsDefined(item.empresa_grupo) ? available_groups.filter(element => parseInt(element.id_grupo) === parseInt(item.empresa_grupo))[0].nombre : 'Sin definir'}}</span>
        </template>

        <template #queue-filters="{form_data}">
            <v-col cols="12" md="6">
                <span class="input-title">Nombre</span>
                <v-text-field
                        v-model.trim="form_data.name"
                        placeholder="Nombre"
                        class="input-error text-field-secondary"
                        bg-color="white"
                        color="transparent"
                        hide-details
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
                <span class="input-title">Grupo padre</span>
                <v-select
                    class="input-error select-secondary"
                    placeholder="Seleccione un grupo"
                    item-title="nombre"
                    item-value="id_grupo"
                    v-model="form_data.parent_group"
                    :items="[{nombre: 'Grupo padre', id_grupo: 0},...available_groups]"
                    variant="outlined"
                    hide-details
                    no-data-text="No hay datos disponibles"
                >
                </v-select>
            </v-col>
            <v-col cols="12" md="6">
                <span class="input-title">Estado</span>
                <v-select
                    class="input-error select-secondary"
                    placeholder="Seleccione un estado"
                    item-title="state"
                    item-value="value"
                    v-model="form_data.active"
                    :items="available_states"
                    variant="outlined"
                    hide-details
                    no-data-text="No hay datos disponibles"
                >
                </v-select>
            </v-col>
        </template>

        <template v-slot:modal-element-actions="{reloadData}">
            <v-dialog v-model="show_insert_modal" persistent>
                <ManageTechniciansGroupsModal
                        :open_modal="openCloseModal"
                        :reloadData="reloadData"
                        :available_groups = "available_groups"
                        :action="action"
                        :selected_group="selected_group"
                ></ManageTechniciansGroupsModal>
            </v-dialog>

            <v-dialog v-model="show_group_technicians_modal" persistent>
                <GroupTechniciansModal
                    :open_modal="openGroupDetails"
                    :selected_group_id="selected_group"
                ></GroupTechniciansModal>
            </v-dialog>
        </template>
    </GenericTableFormPageTemplate>
</template>

<script>
import GenericTableFormPageTemplate from "@/components/Generic/GenericTableFormPageTemplate.vue";
import {onBeforeMount, ref} from "vue";
import {getTechniciansGroupsWS} from "@/utils/technicians_groups";
import ManageTechniciansGroupsModal from "@/components/TechniciansGroupsModal/ManageTechniciansGroupsModal.vue";
import GroupTechniciansModal from "@/components/TechniciansGroupsModal/GroupTechniciansModal.vue";
import {manageWSResponses, variableIsDefined} from "@/utils/aux_functions";

export default {
    name: "TechniciansGroupsView",
    methods: {variableIsDefined, getTechniciansGroupsWS},
    components: {
        GroupTechniciansModal, ManageTechniciansGroupsModal, GenericTableFormPageTemplate},
    setup() {
        let use_expansion_panel = ref(true);
        let headers = ref([
            {text: 'ID', value: 'id_grupo', sortable: true},
            {text: 'NOMBRE', value: 'nombre', sortable: true},
            {text: 'ASIGNADOR EVENTO', value: 'asignador_eventos', sortable: true},
            {text: 'EMPRESA GRUPO', value: 'empresa_grupo', sortable: true},
            {text: 'GRUPO PADRE', value: 'grupo_padre', sortable: true},
            {text: 'USUARIO MATRÍCULA', value: 'usuario_matricula', sortable: true},
            {text: 'ESTADO', value: 'activo'},
            {text: 'ACCIONES', value: 'btn_actions'},
        ])
        let show_insert_modal = ref(false)
        let show_group_technicians_modal = ref(false)

        let action = ref("")
        let available_states = ref([
            {state: 'Activo', value: 1},
            {state: 'Inactivo', value: 0},
            {state: 'Ambos', value: null},
        ])

        let selected_group = ref({})
        let available_groups = ref([])
        let loading = ref(false)

        onBeforeMount(async () => {
            await loadAvailableGroups()
        })

        /*
        * Carga los distintos grupos de técnicos
        * */
        async function loadAvailableGroups(){
            loading.value = true
            let response = await manageWSResponses(getTechniciansGroupsWS,[{}])
            if (response){
                available_groups.value = [...response['data']]
            }
            loading.value = false
        }

        /*Abre o cierra el modal*/
        function openCloseModal() {
            show_insert_modal.value = !show_insert_modal.value
        }

        function openModalInsert() {
            action.value = 'insert'
            openCloseModal()
        }

        function openModalUpdate({id_grupo, activo, nombre, asignador_eventos, empresa_grupo, grupo_padre, usuario_matricula}) {
            action.value = 'update'

            selected_group.value = {
                'id_group' : id_grupo,
                'name' : nombre,
                'active': activo,
                'event_dispatcher': asignador_eventos,
                'enterprise_group': empresa_grupo,
                'parent_group': grupo_padre,
                'registration_user': usuario_matricula,
            }

            openCloseModal()
        }

        function openGroupTechniciansModal(){
            show_group_technicians_modal.value = !show_group_technicians_modal.value
        }

        function openGroupDetails(item){
            selected_group.value = item.id_grupo

            openGroupTechniciansModal()
        }

        return{
            openCloseModal,
            openModalInsert,
            openModalUpdate,
            openGroupDetails,

            available_groups,
            loading,
            available_states,
            action,
            headers,
            selected_group,
            use_expansion_panel,
            show_insert_modal,
            show_group_technicians_modal,
        }
    }
}
</script>

<style scoped>

</style>