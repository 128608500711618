<template>
    <v-container>
        <template v-if="loading">
            <Loading/>
        </template>
        <template v-else>
            <v-row align="center">
                <v-col cols="12" sm="8" md="8" lg="9">
                    <p class="main-title-page">Detalles fuerza de ventas</p>
                </v-col>
            </v-row>
            <v-row class="info-container">
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12" md="4">
                            <span
                                class="input-title">ID: </span><span>{{ selected_sales_force_data.id_fuerza_venta }}</span>
                        </v-col>
                        <v-col cols="12" md="8">
                            <span class="input-title">Nombre: </span><span>{{ selected_sales_force_data.nombre }}</span>
                        </v-col>
                        <v-col>
                            <span
                                class="input-title">Estado: </span><span>{{ selected_sales_force_data.activo === 1 ? 'Activa' : 'Inactiva' }}</span>
                        </v-col>
                        <v-col>
                            <span class="input-title">Orden: </span><span>{{ selected_sales_force_data.orden }}</span>
                        </v-col>
                        <v-col>
                            <span
                                class="input-title">Creada por: </span><span>{{ selected_sales_force_data.created_by ?? 'Desconocido' }}</span>
                        </v-col>
                        <v-col>
                            <span
                                class="input-title">Zona: </span><span>{{ selected_sales_force_data.zone_name ?? 'Desconocido' }}</span>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="4" v-if="grouping_data && grouping_data.id_agrupacion">
                    <p class="input-title">Agrupación</p>
                    <ul>
                        <li><span>ID: {{ grouping_data.id_agrupacion }}</span></li>
                        <li><span>Nombre: {{ grouping_data.nombre }}</span></li>
                        <li><span>Manager: {{ grouping_data.manager }}</span></li>
                    </ul>
                </v-col>
                <v-col cols="12" md="4"
                       v-if="target_group_data && target_group_data.id_grupo_objetivos && target_group_data.nombre">
                    <p class="input-title">Grupo de objetivos</p>
                    <ul>
                        <li><span>ID: {{ target_group_data.id_grupo_objetivos }}</span></li>
                        <li><span>Nombre: {{ target_group_data.nombre }}</span></li>
                    </ul>
                </v-col>
                <v-col cols="12" md="4" v-if="type_data && type_data.type_id && type_data.name && type_data.active">
                    <p class="input-title">Tipo asignado</p>
                    <ul>
                        <li><span>ID: {{ type_data.type_id }}</span></li>
                        <li><span>Nombre: {{ type_data.name }}</span></li>
                        <li><span>Estado: {{ type_data.active === 1 ? 'Activo' : 'Inactivo' }}</span></li>
                    </ul>
                </v-col>
                <v-col cols="12" md="4"
                       v-if="internal_grouping_data && internal_grouping_data.internal_grouping_id && internal_grouping_data.name && internal_grouping_data.active">
                    <p class="input-title">Agrupación interna</p>
                    <ul>
                        <li><span>ID: {{ internal_grouping_data.internal_grouping_id }}</span></li>
                        <li><span>Nombre: {{ internal_grouping_data.name }}</span></li>
                        <li><span>Estado: {{ internal_grouping_data.active === 1 ? 'Activo' : 'Inactivo' }}</span></li>
                    </ul>
                </v-col>
                <v-col cols="12" md="4">
                    <p class="input-title">Canal de ventas</p>
                    <ul>
                        <li><span>ID: {{ sales_channel_data.channel_id }}</span></li>
                        <li><span>Nombre: {{ sales_channel_data.name }}</span></li>
                        <li><span>Estado: {{ sales_channel_data.active === 1 ? 'Activo' : 'Inactivo' }}</span></li>
                    </ul>
                </v-col>
                <v-col cols="12">
                    <p class="input-title">Grupos de técnicos asociados</p>
                </v-col>
                <v-col cols="12" v-for="group in selected_sales_force_data.technicians_groups">
                    <ul>
                        <li><span>ID: {{ group.id_grupo }}</span></li>
                        <li><span>Nombre: {{ group.nombre }}</span></li>
                        <li><span>Estado: {{ group.activo === 1 ? 'Activo' : 'Inactivo' }}</span></li>

                    </ul>
                    <ul class="technicians-list">
                        <li><span><strong>Técnicos:</strong></span></li>
                        <li v-for="technician in group.technicians"> {{ technician.id_usuario }}</li>
                    </ul>
                    <hr>
                </v-col>
            </v-row>
        </template>
    </v-container>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import {getSalesForceDetailsWS} from "@/utils/sales_forces";
import {manageWSResponses} from "@/utils/aux_functions";
import Loading from "@/components/Generic/Loading.vue";

export default {
    name: "SFDetailsView",
    components: {Loading},
    props: {
        sales_force_id: Number
    },
    setup(props) {
        let loading = ref(false)

        let selected_sales_force_data = ref({})

        let grouping_data = ref({})
        let target_group_data = ref({})
        let type_data = ref({})
        let internal_grouping_data = ref({})
        let sales_channel_data = ref({})
        let sales_zone_name = ref();

        onBeforeMount(async () => {
            await loadSalesForceDetails()
        })

        /*
        * Llama al WS para cargar los detalles de la fuerza de ventas cuyo ID se obtiene por props
        * */
        async function loadSalesForceDetails() {
            loading.value = true
            let response = await manageWSResponses(getSalesForceDetailsWS, [props.sales_force_id]);
            if (response) {

                selected_sales_force_data.value = {...response.data.selected_sales_force}

                grouping_data.value = selected_sales_force_data.value.grouping ? selected_sales_force_data.value['grouping'][0] : null;
                target_group_data.value = selected_sales_force_data.value.target_group ? selected_sales_force_data.value['target_group'][0] : null
                type_data.value = selected_sales_force_data.value.id_tipo ? selected_sales_force_data.value['id_tipo'][0] : null;
                internal_grouping_data.value = selected_sales_force_data.value.id_agrupacion_interna ? selected_sales_force_data.value['id_agrupacion_interna'][0] : null;
                sales_channel_data.value = selected_sales_force_data.value.id_canal_venta ? selected_sales_force_data.value['id_canal_venta'][0] : null;
                sales_zone_name.value = selected_sales_force_data.value.zona ? selected_sales_force_data.value['zona'] : null;
            }
            loading.value = false
        }


        return {
            grouping_data,
            internal_grouping_data,
            loading,
            sales_channel_data,
            selected_sales_force_data,
            target_group_data,
            sales_zone_name,
            type_data,
        }
    }
}
</script>

<style lang="scss">
.info-container {
    background-color: $white;
    border-radius: 16px;
    border: 1px solid $primary-color-1;
    height: 100%;

    li {
        list-style: none;
    }

    .input-title {
        margin-top: 15px;
        font-size: 16px;
        font-weight: bold;
        color: $gray-medium-bold;
        display: unset;
    }

    .technicians-list {
        column-count: 4;
    }
}
</style>